import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from 'react';
import {View, ScrollView, Platform} from 'react-native';
import {Divider} from 'react-native-paper';
import {HeaderBackButton} from '@react-navigation/elements';
import moment from 'moment';
import {TextInput} from 'react-native-paper';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import {
  Text,
  PrimaryButton,
  FormTextInput,
  FormNumberInput,
  Pressable,
  Dialog,
  RadioButton,
  Link,
  ReplacementIcon,
  ReplacementText,
  Checkbox,
  Grouping,
  Calendar,
  TimeSlots,
  ServiceRequestStatusIcon,
} from '~/components/controls';
import {
  Row as GroupingRow,
  EditDialog as GroupingRowEdit,
} from '~/components/controls/Grouping';
import {KvkSearchDialog} from '~/components/shared/Dialogs';
import {
  fleet as fleetApi,
  rob as robApi,
  car as carApi,
  service_request as serviceRequestApi,
} from '~/api/private';
import Format from '~/lib/format';
import UserContext from '~/components/context/UserContext';
import {TIRE_PERMISSION_LABELS, TIRE_PERMISSION_SORT} from '~/types/rob';
import {SERVICE_TYPE_CODES, SERVICE_TYPE_DESCRIPTIONS} from '~/types/services';
import {STATUS_CODES} from '~/types/statuses';
import {REPLACEMENT_TYPE} from '~/types/replacement';
import {showTecrmiDialog, setAppIsLoading, setAppDoneLoading} from '~/actions';
import {useDispatch} from '~/lib/hooks';
import withDimensions from '~/components/hoc/with-dimensions';

import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import WarningIcon from '~/images/md-icons/warning/materialicons/18px.svg';
import ErrorIcon from '~/images/md-icons/error/materialicons/24px.svg';
import PriorityHighIcon from '~/images/md-icons/priority_high/materialicons/24px.svg';
import EventIcon from '~/images/md-icons/event/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import FlagIcon from '~/images/md-icons/flag/materialicons/24px.svg';
import BusinessIcon from '~/images/md-icons/business/materialicons/24px.svg';
import PersonIcon from '~/images/md-icons/person/materialicons/24px.svg';
import CallIcon from '~/images/md-icons/call/materialicons/24px.svg';
import AlternateEmailIcon from '~/images/md-icons/alternate_email/materialicons/24px.svg';
import SpeedIcon from '~/images/md-icons/speed/materialicons/24px.svg';
import CarRepairIcon from '~/images/md-icons/car_repair/materialicons/24px.svg';
import ApprovalIcon from '~/images/md-icons/approval/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import TransportationIcon from '~/images/md-icons/transportation/materialicons/24px.svg';
import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';
import AssignmentIcon from '~/images/md-icons/assignment/materialicons/24px.svg';
import AccountCircleIcon from '~/images/md-icons/account_circle/materialicons/24px.svg';
import IdCardIcon from '~/images/md-icons/id_card/materialicons/24px.svg';
import ErrorOutlineIcon from '~/images/md-icons/error_outline/materialicons/24px.svg';
import PinIcon from '~/images/md-icons/pin/materialicons/24px.svg';

const INSPECTION_EXTENDED_MONTHS = {
  'Bahnkick LM': 8,
  'Ayvens (vh LeasePlan Ned. NV)': 8,
  Alphabet: 6,
  'Athlon Car Lease Nederland': 6,
  'Wagenplan B.V.': 6,
};
const INSPECTION_EXTENDED_LM = [
  'Bahnkick LM',
  'Ayvens (vh LeasePlan Ned. NV)',
  'Alphabet',
  'Athlon Car Lease Nederland',
  'Wagenplan B.V.',
];

/* eslint-disable no-control-regex */
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
const phoneRegex = /^06[0-9]{8}/gi;

const DIALOG_FOOTER_HEIGHT_IN_PX = 50;
const DIALOG_HEADER_HEIGHT_IN_PX = 64;

const DuplicateWarningDialog = ({visible, onDismiss, onOverride}) => {
  const [showOverride, setShowOverride] = useState(false);

  const keyListener = useCallback(async (e) => {
    switch (e.keyCode) {
      case 73: // I
        if (e.ctrlKey && e.shiftKey) {
          setShowOverride(true);
        }
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('keydown', keyListener);
    }
  }, [keyListener]);

  return (
    <Dialog
      visible={visible}
      title="Al aanvraag op kenteken"
      titleIcon={<ErrorIcon fill="#B3261E" />}
      onDismiss={onDismiss}
      buttons={
        showOverride
          ? [
              {
                text: 'Melding negeren',
                onPress: onOverride,
              },
              {
                text: 'OK',
                onPress: onDismiss,
              },
            ]
          : null
      }>
      <View style={[sy['flex-row'], sy['py-4']]}>
        <Text>Er staat al een aanvraag open op dit kenteken</Text>
      </View>
    </Dialog>
  );
};

const DialogChangeDealer = withDimensions(
  ({dimensions, dealer, onChangeDealer, onDismiss}) => {
    const {me} = useContext(UserContext);

    const [selectedValue, setSelectedValue] = useState(dealer);

    return (
      <Dialog
        visible={true}
        onDismiss={onDismiss}
        title="Vestiging"
        buttons={[
          {
            text: 'Annuleren',
            onPress: onDismiss,
          },
          {
            text: 'OK',
            onPress: async () => {
              await onChangeDealer(selectedValue);
              onDismiss();
            },
          },
        ]}>
        <ScrollView
          contentContainerStyle={{
            maxHeight: Math.min(
              dimensions.window.height -
                DIALOG_HEADER_HEIGHT_IN_PX -
                DIALOG_FOOTER_HEIGHT_IN_PX,
              600,
            ),
          }}>
          {me.dealers
            .sort((a, b) => a.city.localeCompare(b.city))
            .map((item) => (
              <Pressable
                key={item.id}
                onPress={() => setSelectedValue(item)}
                style={{
                  flexDirection: 'row',
                  paddingVertical: 16,
                }}>
                <RadioButton checked={selectedValue.id === item.id} />
                <Text style={{marginLeft: 32}}>{item.city}</Text>
              </Pressable>
            ))}
        </ScrollView>
      </Dialog>
    );
  },
);

const SERVICE_TYPE_SUBTITLE = {
  [SERVICE_TYPE_CODES.Maintenance]: (contract) => {
    if (contract?.permissions.repairAndMaintenance === false) {
      return 'Niet in contract';
    }

    if (
      contract?.last_service_request?.maintenance ||
      contract?.vehicle?.maintenance?.lastMaintenanceDate
    ) {
      let date;
      let code;

      const last_bk_maintenance_date = contract?.last_service_request
        ?.maintenance?.date
        ? moment(contract?.last_service_request?.maintenance?.date)
        : null;
      const last_rob_maintenance_date = contract?.vehicle?.maintenance
        ?.lastMaintenanceDate
        ? moment(contract?.vehicle?.maintenance?.lastMaintenanceDate)
        : null;

      if (
        !last_rob_maintenance_date ||
        last_bk_maintenance_date?.isSameOrAfter(
          last_rob_maintenance_date,
          'date',
        )
      ) {
        date = last_bk_maintenance_date;
        code = contract?.last_service_request?.maintenance?.code;
      } else {
        date = last_rob_maintenance_date;
        code = contract.leaseContract
          ? `(${contract.vehicle.maintenance.lastMaintenanceCode})`
          : '';
      }

      return `Laatst bekend ${moment(date).format('DD-MM-YYYY')} ${
        code ? `(${code})` : ''
      }`;
    }

    return '';
  },

  [SERVICE_TYPE_CODES.Inspection]: (contract) => {
    if (contract?.permissions.repairAndMaintenance === false) {
      return 'Niet in contract';
    }

    return contract?.vehicle?.maintenance?.nextPeriodicCheck
      ? `Vervalt ${moment(
          contract?.vehicle?.maintenance?.nextPeriodicCheck,
        ).format('DD-MM-YYYY')}`
      : null;
  },

  [SERVICE_TYPE_CODES.Tires]: (contract) => {
    const allowed = [];
    const denied = [];

    Object.keys(contract?.permissions.tires ?? {})
      .filter((item) => TIRE_PERMISSION_LABELS[item])
      .sort(
        (a, b) =>
          TIRE_PERMISSION_SORT.indexOf(a) - TIRE_PERMISSION_SORT.indexOf(b),
      )
      .forEach((item) => {
        if (contract.permissions.tires[item]) {
          allowed.push(TIRE_PERMISSION_LABELS[item]);
        } else {
          denied.push(TIRE_PERMISSION_LABELS[item]);
        }
      });

    let text;
    if (!denied.length) {
      text = allowed.join(', ');
    } else if (!allowed.length) {
      text = `${denied.join(', ')} niet in contract`;
    } else {
      text = `${allowed.join(', ')} (${denied.join(', ')} niet in contract)`;
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
  },
};

const Index = ({navigation}) => {
  const [license, setLicense] = useState('');
  const [car, setCar] = useState({});
  const [fleetContract, setFleetContract] = useState(null);
  const [contract, setContract] = useState(null);
  const [mileage, setMileage] = useState(null);
  const [initials, setInitials] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [serviceTypes, setServiceTypes] = useState([]);
  const [kvk_number, setKvkNumber] = useState('');
  const [kvk_name, setKvkName] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [changeDealer, setChangeDealer] = useState(false);
  const [dealer, setDealer] = useState(null);
  const [datetime, setDatetime] = useState(
    moment
      .utc(moment().format('YYYY-MM-DDTHH:mm:ss'))
      .add(-1 * (moment().minute() % 30), 'minutes'),
  );
  const [editDateTime, setEditDateTime] = useState(null);
  const [vin_part, setVINPart] = useState(null);
  const [vin_part_incorrect, setVINPartIncorrect] = useState(null);
  const [vin, setVIN] = useState(null);
  const [replacementType, setReplacementType] = useState(REPLACEMENT_TYPE.none);
  const [status, setStatus] = useState(STATUS_CODES.Reserved);
  const [hasOpenStatus, setHasOpenStatus] = useState(false);
  const [overrideOpenStatus, setOverrideOpenStatus] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [showInspectionInfoDialog, setShowInspectionInfoDialog] =
    useState(false);
  const [showKilometrageWarning, setShowKilometrageWarning] = useState(false);
  const [hasRetail, setHasRetail] = useState(false);
  const [isBusiness, setIsBusiness] = useState(null);
  const [showKvkDialog, setShowKvkDialog] = useState(false);
  const [enableTecRMI, setEnableTecRMI] = useState(false);
  const [isLicenseValid, setIsLicenseValid] = useState(false);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <CloseIcon />}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
              return;
            }

            navigation.replace('Tabs', {screen: 'Calendar'});
          }}
        />
      ),
      headerStatusBarHeight: 0,
      headerStyle: {
        height: 56,
      },
      headerTitleAlign: 'center',
      headerTitle: () => (
        <Text style={[sy.mediumPlus, {fontSize: 14}]}>Concept</Text>
      ),
      headerRight: () => (
        <View style={[sy['pr-4']]}>
          <ServiceRequestStatusIcon status={STATUS_CODES.Draft} />
        </View>
      ),
    });
  }, [navigation]);

  const {me} = useContext(UserContext);

  useEffect(() => {
    if (me && !dealer) {
      const dealers = me.dealers.sort((a, b) => a.city.localeCompare(b.city));

      let dealer = dealers.find((item) => item.is_default);

      if (!dealer) {
        dealer = dealers.find((item) =>
          me?.dealer_selection?.includes(item.id),
        );
      }

      if (!dealer) {
        dealer = dealers[0];
      }

      setDealer(dealer);
    }
  }, [me, dealer]);

  useEffect(() => {
    setHasRetail(dealer?.package_access?.bahnkick?.has_retail);
    setLicense('');
    setVINPart(null);
    setMileage(null);
    // setInitials('');
    // setFirstName('');
    // setLastName('');
    // setEmail('');
    // setPhoneNumber('');
    setServiceTypes([]);
    reset();
  }, [dealer]);

  const reset = () => {
    setFleetContract(null);
    setContract(null);
    setCar({});
    setVIN(null);
    setHasOpenStatus(false);
    setOverrideOpenStatus(false);
    setIsBusiness(null);
    setKvkName(null);
    setKvkNumber(null);
  };

  const licenseCheck = useCallback(async () => {
    if (!license || license.length < 6) {
      reset();
      return false;
    }

    try {
      const {result: status} = await serviceRequestApi.status_by_license(
        license,
      );
      const hasOpenStatus =
        status &&
        !overrideOpenStatus &&
        ![STATUS_CODES.Archived, STATUS_CODES.Cancelled].includes(status);
      setHasOpenStatus(hasOpenStatus);

      if (hasOpenStatus) {
        return false;
      }

      const {result: fleet_contract} = await fleetApi.contract_by_license(
        license,
        dealer.id,
      );
      setFleetContract(fleet_contract);

      let contract;
      if (fleet_contract) {
        contract = fleet_contract.contract;
      } else {
        ({result: contract} = await robApi.contract_by_dealer(
          license,
          dealer.id,
        ));
      }

      setContract(contract);

      const car = await carApi.fetch(license);
      setCar(car);

      return contract || (car && car.name);
    } catch {
      reset();
    }

    return false;
  }, [license, dealer, overrideOpenStatus]);

  useEffect(() => {
    licenseCheck().then((valid) => setIsLicenseValid(valid));
  }, [license, overrideOpenStatus]);

  useEffect(() => {
    if (!contract || !contract.vehicle?.maintenance?.nextPeriodicCheck) {
      return;
    }

    if (
      moment(datetime)
        .add(2, 'months')
        .isAfter(moment(contract?.vehicle?.maintenance?.nextPeriodicCheck))
    ) {
      setServiceTypes((prevState) => [
        ...new Set([...(prevState ?? []), SERVICE_TYPE_CODES.Inspection]),
      ]);
    } else {
      setServiceTypes((prevState) =>
        prevState.filter((item) => item !== SERVICE_TYPE_CODES.Inspection),
      );
    }
  }, [datetime, contract]);

  useEffect(() => {
    if (license?.length > 5 && vin_part?.length === 4) {
      const fetch = async () => {
        const {vin_number} = await carApi.vinLookup(license, vin_part);

        if (vin_number?.endsWith(vin_part)) {
          setVIN(vin_number);
          setVINPartIncorrect(false);
        } else {
          setVIN(null);
          setVINPartIncorrect(true);
        }
      };

      fetch();
    } else {
      setVIN(null);
      setVINPartIncorrect(null);
    }
  }, [license, vin_part]);

  useEffect(() => {
    setStatus(
      datetime.isAfter(
        moment.utc(moment.utc(moment().format('YYYY-MM-DD'))),
        'days',
      )
        ? STATUS_CODES.Reserved
        : STATUS_CODES.CheckIn,
    );
  }, [datetime, vin]);

  useEffect(() => {
    if (_.isNumber(car?.last_kilometrage?.value) && _.isNumber(mileage)) {
      setShowKilometrageWarning(mileage <= car.last_kilometrage.value);
    }
  }, [mileage, car]);

  const isValid =
    (!hasOpenStatus || overrideOpenStatus) &&
    license.length > 5 &&
    (!vin_part?.length ||
      (vin_part.length === 4 && vin_part_incorrect === false)) &&
    ((contract && contract.isActive) ||
      (hasRetail
        ? (isBusiness === false && !!email && !!phone_number) ||
          (!!kvk_number && !!kvk_name && !!email && !!phone_number)
        : false)) &&
    (!email || !!email.match(emailRegex)) &&
    (!phone_number || !!phone_number?.match(phoneRegex)) &&
    !!dealer &&
    !!datetime;

  const isRetail = !fleetContract && !contract && hasRetail;

  const onRequest = async () => {
    if (!isValid) {
      return;
    }

    const check = await licenseCheck();
    if (!check) {
      return;
    }

    dispatch(setAppIsLoading());

    try {
      const {success, request_id} = await serviceRequestApi.post({
        status,
        license,
        mileage,
        initials,
        first_name,
        last_name,
        email,
        phone_number,
        dealer_id: dealer.id,
        datetime,
        lessor: fleetContract
          ? fleetContract.company_name
          : contract
          ? contract.lessor.name
          : isBusiness
          ? 'Zakelijk'
          : 'Particulier',
        types: serviceTypes,
        vin_part,
        vin_number: vin,
        replacement_type:
          replacementType !== REPLACEMENT_TYPE.none ? replacementType : null,
        is_fleet_car: !!fleetContract,
        is_business: isBusiness,
        kvk_number: isBusiness === true ? kvk_number : null,
        kvk_name: isBusiness === true ? kvk_name : null,
        enable_tecrmi: enableTecRMI,
      });

      if (success) {
        navigation.replace('Request', {request_id});
      }
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const contract_ends =
    contract &&
    contract.leaseContract &&
    moment(contract.leaseContract.endDate).isBefore(moment().add(3, 'months'));

  return (
    <>
      <View style={sy.mainView}>
        <ScrollView style={[sy['bg-gray95']]}>
          <Grouping
            icon={<EventIcon fill="#4A4A49" />}
            header={
              <>
                <Text style={[sy.mediumPlus, sy.capitalize]}>
                  {moment(datetime, 'YYYY-MM-DD').format('dddd')}
                </Text>
                &nbsp;{moment(datetime, 'YYYY-MM-DD').format('D MMMM')}
                &nbsp;&middot;&nbsp;
                {moment.utc(datetime).format('H:mm')}
                &nbsp;uur
              </>
            }
            options={{topMost: true}}>
            <GroupingRow title="Vestiging">
              <Link onPress={() => setChangeDealer(true)}>{dealer?.city}</Link>

              {changeDealer && (
                <DialogChangeDealer
                  dealer={dealer}
                  onChangeDealer={(dealer) => {
                    setDealer(dealer);
                  }}
                  onDismiss={() => setChangeDealer(false)}
                />
              )}
            </GroupingRow>
            <GroupingRow title="Datum">
              <Text>
                {moment(datetime, 'YYYY-MM-DD').format('dddd D MMMM YYYY')}
              </Text>
              <GroupingRowEdit
                title="Datum wijzigen"
                dismissable
                cancelButton
                disableOKButton={!editDateTime}
                onClose={() => setEditDateTime(null)}
                onOK={() => {
                  setDatetime((prevState) => {
                    editDateTime.hours(moment(prevState).utc().hours());
                    editDateTime.minutes(moment(prevState).minutes());

                    return editDateTime;
                  });
                }}>
                <Calendar
                  months={[
                    /* Three months in the past and six in the future */
                    -3, -2, -1, 0, 1, 2, 3, 4, 5, 6,
                  ]}
                  initialDate={moment()}
                  date={editDateTime ?? datetime}
                  onPress={setEditDateTime}
                />
              </GroupingRowEdit>
            </GroupingRow>
            <GroupingRow title="Tijd">
              <Text>{moment.utc(datetime).format('H:mm')}</Text>
              <GroupingRowEdit
                title="Tijd wijzigen"
                dismissable
                cancelButton
                disableOKButton={!editDateTime}
                onClose={() => setEditDateTime(null)}
                onOK={() => setDatetime(editDateTime)}>
                <ScrollView style={[sy['pl-4'], {maxHeight: 400}]}>
                  <TimeSlots
                    begin_checkin={moment().startOf('day').hours(6)}
                    end_checkin={moment().startOf('day').hours(19)}
                    datetime={editDateTime ?? datetime}
                    onPress={setEditDateTime}
                  />
                </ScrollView>
              </GroupingRowEdit>
            </GroupingRow>
          </Grouping>
          <Grouping
            icon={<DirectionsCarIcon fill="#4A4A49" />}
            header={car?.name ?? 'Voertuig'}
            initial={{expand: true}}>
            <View style={[sy['p-4'], sy['gap-4']]}>
              <View style={[sy['flex-row'], sy['gap-8']]}>
                <View style={[sy['py-4']]}>
                  <PinIcon fill="#4a4a49" />
                </View>
                <View style={[sy['flex-1'], sy['gap-2']]}>
                  <FormTextInput
                    value={license}
                    onChangeText={(text) =>
                      setLicense(text?.replace(/[^0-9a-z]/gi, '').toUpperCase())
                    }
                    label="Kenteken"
                    required={true}
                    errorMessage={'Kenteken is verplicht'}
                    inputProps={{
                      maxLength: 8,
                      autoCapitalize: 'characters',
                    }}
                  />
                  {contract && !contract.isActive && (
                    <Text style={{lineHeight: 20, color: 'red'}}>
                      Lease contract is niet gevonden voor dit kenteken
                    </Text>
                  )}
                  {car?.name && (
                    <Text style={{lineHeight: 20}}>{car.name}</Text>
                  )}
                </View>
              </View>
              <View style={[sy['flex-row'], sy['gap-8']]}>
                <View style={[sy['py-4']]}>
                  <FlagIcon fill="#4a4a49" />
                </View>
                <View style={[sy['flex-1'], sy['gap-2']]}>
                  <FormTextInput
                    label="Meldcode"
                    value={vin_part}
                    errorMessage="Meldcode is onjuist"
                    hasError={vin_part_incorrect}
                    onChangeText={setVINPart}
                    inputProps={{
                      maxLength: 4,
                      keyboardType: 'numeric',
                    }}
                  />
                  {vin && <Text style={{lineHeight: 20}}>{vin}</Text>}
                </View>
              </View>
              <View style={[sy['flex-row'], sy['gap-8']]}>
                <View style={[sy['py-4']]}>
                  <SpeedIcon
                    fill={showKilometrageWarning ? '#FF6C00' : '#4a4a49'}
                  />
                </View>
                <View style={[sy['flex-1']]}>
                  <FormNumberInput
                    label="Kilometerstand"
                    value={mileage}
                    digits={0}
                    onChangeNumber={setMileage}
                    inputProps={{
                      activeUnderlineColor: showKilometrageWarning
                        ? '#FF6C00'
                        : null,
                    }}
                  />
                  {showKilometrageWarning && (
                    <Text style={{color: '#FF6C00', lineHeight: 20}}>
                      Laatste stand&nbsp;
                      {Format.number(car.last_kilometrage.value, 0, true)} km (
                      {moment(car.last_kilometrage.date).format('D-M-YYYY')})
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </Grouping>
          {isLicenseValid && isRetail && (
            <Grouping
              icon={
                isBusiness === true ? (
                  <BusinessIcon fill="#4a4a49" />
                ) : (
                  <IdCardIcon fill="#4a4a49" />
                )
              }
              header={kvk_name ?? 'Eigendom'}
              initial={{expand: true}}>
              <Pressable
                style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}
                onPress={() => {
                  setIsBusiness(false);
                  setKvkName(null);
                  setKvkNumber(null);
                }}>
                <RadioButton checked={isBusiness === false} />
                <Text>Particulier</Text>
              </Pressable>
              <Divider style={[sy['ml-14'], sy['bg-gray95']]} />
              <Pressable
                style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}
                onPress={() => {
                  setIsBusiness(true);
                  setKvkName(null);
                  setKvkNumber(null);
                }}>
                <RadioButton checked={isBusiness === true} />
                <View>
                  <Text>Zakelijk</Text>
                  {isBusiness === true && (
                    <>
                      {kvk_number ? (
                        <Link
                          textStyle={[sy.small]}
                          onPress={() => setShowKvkDialog(true)}>
                          {kvk_name}
                        </Link>
                      ) : (
                        <Link
                          textStyle={[sy.small, {color: '#FF6C00'}]}
                          onPress={() => setShowKvkDialog(true)}>
                          Bedrijf zoeken (KVK)
                        </Link>
                      )}
                    </>
                  )}
                </View>
              </Pressable>
            </Grouping>
          )}
          {!hasRetail && car?.name && !contract?.isActive ? (
            <>
              <Divider />
              <View
                style={[
                  sy['p-4'],
                  sy['gap-8'],
                  sy['flex-row'],
                  sy['bg-white'],
                ]}>
                <BusinessIcon fill="#FF6C00" />
                <Text style={[sy['mediumPlus']]}>
                  Lease/wagenpark niet gevonden
                </Text>
              </View>
            </>
          ) : (
            isLicenseValid &&
            (!isRetail || isBusiness === false || !!kvk_number) && (
              <>
                {!isRetail ? (
                  <Grouping
                    icon={
                      <BusinessIcon
                        fill={
                          contract_ends || contract?.isActive === false
                            ? '#972727'
                            : car?.name &&
                              !contract &&
                              hasRetail &&
                              isBusiness === null
                            ? '#FF6C00'
                            : '#4A4A49'
                        }
                      />
                    }
                    header={
                      fleetContract ? (
                        <>{fleetContract.company_name}</>
                      ) : contract ? (
                        contract.lessor?.name || 'Geen lease auto'
                      ) : (
                        'Voer kenteken in'
                      )
                    }>
                    {contract ? (
                      <>
                        {contract.leaseContract && (
                          <GroupingRow
                            title={
                              <View
                                style={[sy['flex-row'], sy['items-center']]}>
                                <Text>Einde contract</Text>
                                {contract_ends && (
                                  <>
                                    &nbsp;
                                    <WarningIcon fill="#972727" />
                                  </>
                                )}
                              </View>
                            }>
                            <Text>
                              {moment(contract?.leaseContract.endDate).format(
                                'D-M-YYYY',
                              )}
                              &nbsp;(
                              {Format.number(
                                contract?.leaseContract.maximumMileage,
                                0,
                                true,
                              )}{' '}
                              km)
                            </Text>
                          </GroupingRow>
                        )}
                        <GroupingRow title="Reparatie & onderhoud">
                          <Text>
                            {contract?.permissions.repairAndMaintenance ? (
                              <>Toegestaan</>
                            ) : (
                              <>Niet toegestaan</>
                            )}
                          </Text>
                        </GroupingRow>
                        <GroupingRow title="Bandvervanging">
                          <Text>
                            {contract?.permissions.tires.replace ? (
                              <>Toegestaan</>
                            ) : (
                              <>Niet toegestaan</>
                            )}
                          </Text>
                        </GroupingRow>
                        <GroupingRow title="Seizoenswissel">
                          <Text>
                            {contract?.permissions.tires.swap ? (
                              <>Toegestaan</>
                            ) : (
                              <>Niet toegestaan</>
                            )}
                          </Text>
                        </GroupingRow>
                        <GroupingRow title="Vervangend vervoer">
                          <Text>
                            {contract?.permissions.replacement_vehicle
                              ?.allowed ? (
                              <>Toegestaan</>
                            ) : (
                              <>Niet toegestaan</>
                            )}
                          </Text>
                        </GroupingRow>
                      </>
                    ) : null}
                  </Grouping>
                ) : (
                  <></>
                )}
                <Grouping
                  icon={<PersonIcon fill="#4a4a49" />}
                  header={
                    <View style={[sy['flex-row']]}>
                      <Text style={sy.mediumPlus}>
                        {initials || first_name || last_name
                          ? `${first_name || initials || ''} ${
                              last_name || ''
                            }`.trim()
                          : 'Berijder'}
                      </Text>
                      {isRetail && !isValid && (
                        <>
                          <Text style={sy.mediumPlus}>
                            &nbsp;&middot;&nbsp;
                          </Text>
                          <Text style={[sy.mediumPlus, {color: '#FF6C00'}]}>
                            Gegevens invoeren
                          </Text>
                        </>
                      )}
                    </View>
                  }
                  initial={{expand: isRetail}}>
                  <View style={[sy['p-4'], sy['gap-4']]}>
                    <View style={[sy['flex-row'], sy['gap-8']]}>
                      <View style={[sy['py-4']]}>
                        <AccountCircleIcon fill="#4a4a49" />
                      </View>
                      <View style={[sy['flex-1']]}>
                        <div data-private>
                          <FormTextInput
                            label="Voorletter(s)"
                            value={initials}
                            inputProps={{
                              right: isRetail && !initials?.length && (
                                <TextInput.Icon
                                  style={{backgroundColor: 'transparent'}}
                                  icon={({size}) => (
                                    <ErrorOutlineIcon
                                      fill="#FF6C00"
                                      width={size}
                                      height={size}
                                    />
                                  )}
                                />
                              ),
                            }}
                            onChangeText={setInitials}
                          />
                        </div>
                      </View>
                    </View>
                    <View style={[sy['flex-row'], sy['gap-8']]}>
                      <View style={[sy['py-4']]}>
                        <View style={{width: 24, height: 24}}>
                          <Text>&nbsp;</Text>
                        </View>
                      </View>
                      <View style={[sy['flex-1']]}>
                        <div data-private>
                          <FormTextInput
                            label="Voornaam"
                            value={first_name}
                            inputProps={{
                              right: isRetail && !first_name?.length && (
                                <TextInput.Icon
                                  style={{backgroundColor: 'transparent'}}
                                  icon={({size}) => (
                                    <ErrorOutlineIcon
                                      fill="#FF6C00"
                                      width={size}
                                      height={size}
                                    />
                                  )}
                                />
                              ),
                            }}
                            onChangeText={setFirstName}
                          />
                        </div>
                      </View>
                    </View>
                    <View style={[sy['flex-row'], sy['gap-8']]}>
                      <View style={[sy['py-4']]}>
                        <View style={{width: 24, height: 24}}>
                          <Text>&nbsp;</Text>
                        </View>
                      </View>
                      <View style={[sy['flex-1']]}>
                        <div data-private>
                          <FormTextInput
                            label="Achternaam"
                            value={last_name}
                            inputProps={{
                              right: isRetail && !last_name?.length && (
                                <TextInput.Icon
                                  style={{backgroundColor: 'transparent'}}
                                  icon={({size}) => (
                                    <ErrorOutlineIcon
                                      fill="#FF6C00"
                                      width={size}
                                      height={size}
                                    />
                                  )}
                                />
                              ),
                            }}
                            onChangeText={setLastName}
                          />
                        </div>
                      </View>
                    </View>
                    <View style={[sy['flex-row'], sy['gap-8']]}>
                      <View style={[sy['py-4']]}>
                        <CallIcon fill="#4a4a49" />
                      </View>
                      <View style={[sy['flex-1']]}>
                        <div data-private>
                          <FormTextInput
                            value={phone_number}
                            onChangeText={setPhoneNumber}
                            onEndEditing={() => {
                              const valid =
                                !phone_number ||
                                !!phone_number.match(phoneRegex);
                              setPhoneError(!valid);
                            }}
                            label="Mobiele telefoonnummer"
                            hasError={phoneError}
                            errorMessage={
                              !phone_number?.length
                                ? 'Mobiele telefoonnummer is verplicht'
                                : !phone_number?.match(phoneRegex)
                                ? 'Ongeldige waarde mobiele telefoonnummer'
                                : null
                            }
                            digits={0}
                            inputProps={{
                              right: isRetail && !phone_number?.length && (
                                <TextInput.Icon
                                  style={{backgroundColor: 'transparent'}}
                                  icon={({size}) => (
                                    <ErrorOutlineIcon
                                      fill="#FF6C00"
                                      width={size}
                                      height={size}
                                    />
                                  )}
                                />
                              ),
                              maxLength: 10,
                              keyboardType: 'numeric',
                            }}
                          />
                        </div>
                      </View>
                    </View>
                    <View style={[sy['flex-row'], sy['gap-8']]}>
                      <View style={[sy['py-4']]}>
                        <AlternateEmailIcon fill="#4a4a49" />
                      </View>
                      <View style={[sy['flex-1']]}>
                        <div data-private>
                          <FormTextInput
                            label="E-mail"
                            value={email}
                            onChangeText={setEmail}
                            onEndEditing={() => {
                              const valid = !email || !!email.match(emailRegex);
                              setEmailError(!valid);
                            }}
                            hasError={emailError}
                            errorMessage="E-mail heeft een ongeldige waarde"
                            inputProps={{
                              right: isRetail && !email?.length && (
                                <TextInput.Icon
                                  style={{backgroundColor: 'transparent'}}
                                  icon={({size}) => (
                                    <ErrorOutlineIcon
                                      fill="#FF6C00"
                                      width={size}
                                      height={size}
                                    />
                                  )}
                                />
                              ),
                              keyboardType: 'email-address',
                            }}
                          />
                        </div>
                      </View>
                    </View>
                  </View>
                </Grouping>
                <Grouping
                  icon={<AssignmentIcon fill="#4A4A49" />}
                  header="Werkopdracht"
                  initial={{expand: true}}>
                  <View style={[sy['p-4']]}>
                    <View style={[sy['gap-4']]}>
                      {Object.keys(SERVICE_TYPE_CODES)
                        .filter((item) => item !== SERVICE_TYPE_CODES.Repairs)
                        .map((type) => {
                          const permitted =
                            isBusiness !== null ||
                            contract?.permissions.repairAndMaintenance;
                          const tecrmi_exemption =
                            isBusiness !== null || contract?.permissions.tecrmi;

                          return (
                            <Pressable
                              disabled={!permitted}
                              key={type}
                              onPress={async () => {
                                if (
                                  type === SERVICE_TYPE_CODES.Maintenance &&
                                  !serviceTypes.includes(type)
                                ) {
                                  if (
                                    !serviceTypes.includes(
                                      SERVICE_TYPE_CODES.Inspection,
                                    ) &&
                                    INSPECTION_EXTENDED_LM.includes(
                                      contract?.lessor?.name,
                                    ) &&
                                    moment().isAfter(
                                      moment(
                                        contract.vehicle?.maintenance
                                          ?.nextPeriodicCheck,
                                      ).add(
                                        -(
                                          INSPECTION_EXTENDED_MONTHS[
                                            contract?.lessor?.name
                                          ] ?? 8
                                        ),
                                        'months',
                                      ),
                                    )
                                  ) {
                                    serviceTypes.push(
                                      SERVICE_TYPE_CODES.Inspection,
                                    );
                                  }

                                  if (tecrmi_exemption) {
                                    // Do nothing. TecRMI will be enabled when the service request is created.
                                  } else {
                                    try {
                                      await new Promise((resolve, reject) => {
                                        dispatch(
                                          showTecrmiDialog({
                                            resolve,
                                            reject,
                                          }),
                                        );
                                      });

                                      setEnableTecRMI(true);
                                    } catch {
                                      return;
                                    }
                                  }
                                }

                                if (serviceTypes.includes(type)) {
                                  setServiceTypes(
                                    serviceTypes.filter(
                                      (item) => item !== type,
                                    ),
                                  );
                                } else {
                                  setServiceTypes([...serviceTypes, type]);
                                }
                              }}
                              style={[sy['flex-row'], sy['gap-8']]}>
                              <Checkbox
                                disabled={!permitted}
                                checked={serviceTypes.includes(type)}
                              />
                              <View style={[sy['flex-1']]}>
                                <Text>{SERVICE_TYPE_DESCRIPTIONS[type]}</Text>
                                <View style={[sy['flex-row'], sy['gap-2']]}>
                                  <Text
                                    style={[sy.small, sy['text-lightgray']]}>
                                    {SERVICE_TYPE_SUBTITLE[type]?.(contract)}
                                  </Text>
                                  {type === SERVICE_TYPE_CODES.Inspection &&
                                  contract?.vehicle?.maintenance
                                    ?.nextPeriodicCheck &&
                                  moment(datetime)
                                    .add(2, 'months')
                                    .isAfter(
                                      moment(
                                        contract?.vehicle?.maintenance
                                          ?.nextPeriodicCheck,
                                      ),
                                    ) ? (
                                    <PriorityHighIcon
                                      fill="#972727"
                                      width={18}
                                      height={18}
                                    />
                                  ) : type === SERVICE_TYPE_CODES.Inspection &&
                                    contract?.vehicle?.maintenance
                                      ?.nextPeriodicCheck &&
                                    INSPECTION_EXTENDED_LM.includes(
                                      contract?.lessor?.name,
                                    ) &&
                                    moment().isAfter(
                                      moment(
                                        contract?.vehicle?.maintenance
                                          ?.nextPeriodicCheck,
                                      ).add(
                                        -(
                                          INSPECTION_EXTENDED_MONTHS[
                                            contract?.lessor?.name
                                          ] ?? 8
                                        ),
                                        'months',
                                      ),
                                    ) ? (
                                    <Pressable
                                      onPress={() =>
                                        setShowInspectionInfoDialog(true)
                                      }>
                                      <InfoIcon
                                        fill="#FF6C00"
                                        width={18}
                                        height={18}
                                      />
                                    </Pressable>
                                  ) : null}
                                </View>
                              </View>
                              {type === SERVICE_TYPE_CODES.Maintenance && (
                                <CarRepairIcon fill="#4a4a49" />
                              )}
                              {type === SERVICE_TYPE_CODES.Inspection && (
                                <ApprovalIcon fill="#4a4a49" />
                              )}
                              {type === SERVICE_TYPE_CODES.Tires && (
                                <TireRepairIcon fill="#4a4a49" />
                              )}
                            </Pressable>
                          );
                        })}
                    </View>
                  </View>
                </Grouping>
                <Grouping
                  icon={<TransportationIcon fill="#4A4A49" />}
                  header="Vervangend vervoer"
                  initial={{expand: true}}>
                  <View>
                    {Object.values(REPLACEMENT_TYPE).map((type, index, arr) => {
                      let allowed = true;
                      if (type === REPLACEMENT_TYPE.car) {
                        allowed =
                          contract?.permissions.replacement_vehicle?.allowed;
                      }

                      return (
                        <Pressable
                          key={type}
                          disabled={!allowed}
                          style={[
                            sy['flex-row'],
                            sy['flex-1'],
                            sy['gap-8'],
                            sy['px-4'],
                          ]}
                          onPress={() =>
                            setReplacementType(
                              replacementType !== type ? type : null,
                            )
                          }>
                          <View style={[sy['py-4']]}>
                            <RadioButton
                              disabled={!allowed}
                              checked={replacementType === type}
                            />
                          </View>
                          <View style={[sy['flex-1']]}>
                            <View
                              style={[
                                sy['flex-row'],
                                sy['flex-1'],
                                sy['items-center'],
                                sy['justify-between'],
                                sy['py-4'],
                              ]}>
                              <View>
                                <ReplacementText type={type} />
                                {!allowed && (
                                  <Text
                                    style={[
                                      sy.smallRegular,
                                      sy['text-lightgray'],
                                    ]}>
                                    Niet in contract
                                  </Text>
                                )}
                              </View>
                              <ReplacementIcon type={type} fill="#4A4A49" />
                            </View>
                            {index < arr.length - 1 && (
                              <Divider style={sy['bg-gray95']} />
                            )}
                          </View>
                        </Pressable>
                      );
                    })}
                  </View>
                </Grouping>
              </>
            )
          )}
        </ScrollView>
        <View style={[sy.footerView, sy['justify-end'], sy['items-center']]}>
          {hasOpenStatus && (
            <Pressable
              style={[sy['mr-4']]}
              onPress={() => setShowDuplicateDialog(true)}>
              <ErrorIcon fill="#B3261E" />
            </Pressable>
          )}
          <PrimaryButton
            icon={() => (
              <ServiceRequestStatusIcon
                status={status}
                fill="#FFFFFF"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}
            disabled={!isValid}
            onPress={onRequest}>
            {status === STATUS_CODES.Reserved ? 'Reserveren' : 'Inchecken'}
          </PrimaryButton>
        </View>
        <DuplicateWarningDialog
          visible={showDuplicateDialog}
          onDismiss={() => setShowDuplicateDialog(false)}
          onOverride={() => setOverrideOpenStatus(true)}
        />
        <Dialog
          visible={showInspectionInfoDialog}
          title="APK bij onderhoud"
          titleIcon={<InfoIcon fill="#FF6C00" />}
          onDismiss={() => setShowInspectionInfoDialog(false)}>
          <View style={[sy['flex-row'], sy['py-4']]}>
            <Text>
              Verzoek LM: APK combineren met onderhoud bij vervaldatum binnen{' '}
              {INSPECTION_EXTENDED_MONTHS[contract?.lessor?.name] ?? 8} maanden.
            </Text>
          </View>
        </Dialog>
        <KvkSearchDialog
          visible={showKvkDialog}
          onDismiss={() => setShowKvkDialog(false)}
          onOK={(value) => {
            setKvkNumber(value.number);
            setKvkName(value.name);
            setShowKvkDialog(false);
          }}
        />
      </View>
    </>
  );
};

export default withProtectedScreen(Index);
