import moment from 'moment';
import qs from 'qs';

import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

const dealer = {
  details: async (dealer_id) => {
    const result = await fetch(`${endpoints.dealer}/${dealer_id}`);

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  availability: async (dates) => {
    const result = await fetch(`${endpoints.dealer}/availability`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  saveAvailability: async (dealer_id, date, blocked, capacity) => {
    const result = await fetch(`${endpoints.dealer}/availability`, {
      method: 'POST',
      body: JSON.stringify({
        dealer_id,
        date: moment(date).format('YYYY-MM-DD'),
        blocked,
        capacity,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  addEditRentalCar: async (dealer_id, car) => {
    const result = await fetch(`${endpoints.dealer}/rental_car`, {
      method: 'POST',
      body: JSON.stringify({
        dealer_id,
        car,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  deleteRentalCar: async (car_id) => {
    const result = await fetch(`${endpoints.dealer}/rental_car/${car_id}`, {
      method: 'DELETE',
    });

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  rental_cars: async (options) => {
    const result = await fetch(
      `${endpoints.dealer}/rental_car?${qs.stringify(options)}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  parts: async (dealer_id) => {
    const result = await fetch(
      `${endpoints.dealer}/parts?${qs.stringify({
        dealer_id,
      })}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  add_part: async (part) => {
    const result = await fetch(`${endpoints.dealer}/part`, {
      method: 'POST',
      body: JSON.stringify({part}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_part: async (part) => {
    const result = await fetch(`${endpoints.dealer}/part/${part.id}`, {
      method: 'PATCH',
      body: JSON.stringify({part}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  delete_part: async (part) => {
    const result = await fetch(`${endpoints.dealer}/part/${part.id}`, {
      method: 'DELETE',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  request: async (id, phone_number) => {
    const result = await fetch(`${endpoints.dealer}/request/${id}`, {
      method: 'POST',
      body: JSON.stringify({phone_number}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  reminder: async ({request_id, phone_number, email}) => {
    const result = await fetch(`${endpoints.dealer}/reminder/${request_id}`, {
      method: 'POST',
      body: JSON.stringify({phone_number, email}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  invoice: async (id) => {
    const result = await fetch(`${endpoints.dealer}/request/${id}/invoice`, {
      method: 'POST',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  users: async (id) => {
    const result = await fetch(`${endpoints.dealer}/${id}/users`);

    if (!result) {
      return {};
    }

    const json = await result.json();
    return json;
  },
  messages: async () => {
    const result = await fetch(`${endpoints.dealer}/messages`, {
      method: 'POST',
    });

    if (result?.status !== 200) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  company: async (id) => {
    const result = await fetch(`${endpoints.dealer}/${id}/company`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};

export default dealer;
