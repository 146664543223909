export default {
  'p-1': {
    padding: 4,
  },
  'p-2': {
    padding: 8,
  },
  'p-4': {
    padding: 16,
  },
  'px-1': {
    paddingHorizontal: 4,
  },
  'px-2': {
    paddingHorizontal: 8,
  },
  'px-3': {
    paddingHorizontal: 12,
  },
  'px-4': {
    paddingHorizontal: 16,
  },
  'px-6': {
    paddingHorizontal: 24,
  },
  'px-8': {
    paddingHorizontal: 32,
  },
  'px-10': {
    paddingHorizontal: 40,
  },
  'py-1': {
    paddingVertical: 4,
  },
  'py-2': {
    paddingVertical: 8,
  },
  'py-3': {
    paddingVertical: 12,
  },
  'py-4': {
    paddingVertical: 16,
  },
  'py-14': {
    paddingVertical: 56,
  },
  'pt-0': {
    paddingTop: 0,
  },
  'pt-2': {
    paddingTop: 8,
  },
  'pt-3': {
    paddingTop: 12,
  },
  'pt-4': {
    paddingTop: 16,
  },
  'pt-5': {
    paddingTop: 20,
  },
  'pt-6': {
    paddingTop: 24,
  },
  'pr-0': {
    paddingRight: 0,
  },
  'pr-1': {
    paddingRight: 4,
  },
  'pr-2': {
    paddingRight: 8,
  },
  'pr-4': {
    paddingRight: 16,
  },
  'pr-6': {
    paddingRight: 24,
  },
  'pr-8': {
    paddingRight: 32,
  },
  'pb-0': {
    paddingBottom: 0,
  },
  'pb-1': {
    paddingBottom: 4,
  },
  'pb-2': {
    paddingBottom: 8,
  },
  'pb-3': {
    paddingBottom: 12,
  },
  'pb-4': {
    paddingBottom: 16,
  },
  'pl-0': {
    paddingLeft: 0,
  },
  'pl-1': {
    paddingLeft: 4,
  },
  'pl-2': {
    paddingLeft: 8,
  },
  'pl-3': {
    paddingLeft: 12,
  },
  'pl-4': {
    paddingLeft: 16,
  },
  'pl-6': {
    paddingLeft: 24,
  },
  'pl-8': {
    paddingLeft: 32,
  },
  'pl-10': {
    paddingLeft: 40,
  },
  'pl-12': {
    paddingLeft: 48,
  },
  'pl-14': {
    paddingLeft: 56,
  },
  'pl-18': {
    paddingLeft: 72,
  },
  'pl-21': {
    paddingLeft: 84,
  },
  'm-2': {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  'm-4': {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  'mx-4': {
    marginLeft: 16,
    marginRight: 16,
  },
  'mx-6': {
    marginLeft: 24,
    marginRight: 24,
  },
  'my-3': {
    marginTop: 12,
    marginBottom: 12,
  },
  'my-4': {
    marginTop: 16,
    marginBottom: 16,
  },
  'my-5': {
    marginTop: 20,
    marginBottom: 20,
  },
  'mt-2': {
    marginTop: 8,
  },
  'mt-4': {
    marginTop: 16,
  },
  'mb-3': {
    marginBottom: 12,
  },
  'mb-4': {
    marginBottom: 16,
  },
  'mb-8': {
    marginBottom: 32,
  },
  'ml-4': {
    marginLeft: 16,
  },
  'ml-6': {
    marginLeft: 24,
  },
  'ml-8': {
    marginLeft: 32,
  },
  'ml-14': {
    marginLeft: 56,
  },
  'ml-16': {
    marginLeft: 64,
  },
  'ml-18': {
    marginLeft: 72,
  },
  'ml-20': {
    marginLeft: 80,
  },
  'mr-4': {
    marginRight: 16,
  },
  'mr-8': {
    marginRight: 32,
  },
  'mr-14': {
    marginRight: 56,
  },
};
