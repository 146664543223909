import React, {useEffect, useRef, useMemo, useState, useContext} from 'react';
import {View} from 'react-native';
import moment from 'moment';
import {useNavigation, useIsFocused} from '@react-navigation/native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';
import {RecyclerListView, DataProvider, LayoutProvider} from 'recyclerlistview';

import sy from '~/styles';
import {useSelector, useDispatch} from '~/lib/hooks';
import {fetchMessages} from '~/actions';
import {user as userApi} from '~/api/private';
import Format from '~/lib/format';
import {
  Pressable,
  Text,
  Persona,
  ServiceRequestStatusIcon,
  UserLabel,
} from '~/components/controls';
import {PersonaSize} from '~/components/controls/Persona';
import {DealerHeader} from '~/components/shared';
import {STATUS_DESCRIPTIONS} from '~/types/statuses';
import withDimensions from '~/components/hoc/with-dimensions';
import UserContext from '~/components/context/UserContext';

import ChatIcon from '~/images/md-icons/chat/materialicons/24px.svg';
import PhotoCameraIcon from '~/images/md-icons/photo_camera/materialicons/24px.svg';
import TextsmsIcon from '~/images/md-icons/textsms/materialicons/24px.svg';
import EmailIcon from '~/images/md-icons/email/materialicons/24px.svg';

const ROW_HEIGHT = 89;

const Message = ({
  style,
  service_request_id,
  user_id,
  dealer_name,
  lessor,
  license,
  assigned_user_id,
  activity_attributes,
  message,
  is_photo,
  message_timestamp,
  message_status,
  unread,
}) => {
  const navigation = useNavigation();

  const {me} = useContext(UserContext);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const user = await userApi.find(user_id);
      setUser(user);
    };

    if (user_id && me && me?.id !== user_id) {
      fetch();
    }
  }, [me, user_id]);

  const timestamp = moment(message_timestamp);
  let duration = timestamp.format('DD-MM-YYYY');
  if (timestamp.isSame(moment(), 'day')) {
    duration = timestamp.format('H:mm');
  }
  const onPress = () =>
    navigation.navigate('Chat', {request_id: service_request_id});

  let content;

  if (activity_attributes?.type === 'SMS') {
    content = (
      <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
        <TextsmsIcon fill="#4a4a49" width={18} height={18} />
        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
          {message_status === 'SentFailed'
            ? 'SMS verzenden mislukt'
            : 'SMS verzonden'}
        </Text>
      </View>
    );
  } else if (activity_attributes?.type === 'Email') {
    content = (
      <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
        <EmailIcon fill="#4a4a49" width={18} height={18} />
        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
          E-mail verzonden
        </Text>
      </View>
    );
  } else if (message_status) {
    content = (
      <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
        <ServiceRequestStatusIcon
          status={message_status}
          iconProps={{width: 18, height: 18}}
        />
        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
          {STATUS_DESCRIPTIONS[message_status]}
        </Text>
      </View>
    );
  } else if (is_photo)
    content = (
      <>
        {dealer_name && (
          <Text style={[sy.smallRegular, sy['text-lightgray']]}>
            {user?.first_name ?? 'Jij'}:&nbsp;
          </Text>
        )}
        <PhotoCameraIcon width={18} height={18} fill="#4a4a49" />
        <Text style={sy.smallRegular}>&nbsp;Foto</Text>
      </>
    );
  else if (message) {
    content = (
      <Text
        style={[
          sy.smallRegular,
          sy['text-lightgray'],
          {
            width: 302 - (unread > 0 ? 24 : 0),
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          },
        ]}>
        {dealer_name ? <>{user?.first_name ?? 'Jij'}:&nbsp;</> : <></>}
        {message}
      </Text>
    );
  }

  return (
    <Pressable style={[style]} onPress={onPress}>
      <View style={[sy['flex-1'], sy['flex-row'], sy['px-4'], sy['gap-4']]}>
        <Persona
          key={lessor}
          is_dealer={false}
          imageInitials={lessor.substring(0, 2)}
          lessor={lessor}
          size={PersonaSize.size40}
        />
        <View style={[sy['flex-1']]}>
          <View style={[sy['flex-row'], sy['items-between'], sy['gap-2']]}>
            <View style={[sy['flex-1']]}>
              <Text style={[sy.medium, sy.truncate, {lineHeight: 24}]}>
                {Format.license(license)}&nbsp;&middot;&nbsp;
                {lessor}
              </Text>
            </View>
            <Text
              style={[
                sy.smallRegular,
                {lineHeight: 24},
                unread > 0 && {
                  color: '#231fda',
                },
              ]}>
              {duration}
            </Text>
          </View>
          <View style={[sy['flex-row'], sy['gap-2']]}>
            <View style={[sy['flex-1'], sy['flex-row']]}>{content}</View>
            {unread > 0 && (
              <View
                style={[
                  sy['items-center'],
                  sy['justify-center'],
                  {
                    backgroundColor: '#231fda',
                    borderRadius: 90,
                    width: 16,
                    height: 16,
                  },
                ]}>
                <Text
                  style={[
                    sy.mediumBold,
                    {lineHeight: 10, fontSize: 10, color: '#ffffff'},
                  ]}>
                  {unread}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <UserLabel
        key={service_request_id}
        style={{bottom: -12}}
        user_id={assigned_user_id}
      />
    </Pressable>
  );
};

const Overview = ({dimensions}) => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const {window} = dimensions;
  const width = Math.min(window.width, 412);

  const listViewRef = useRef(null);
  const dataProvider = useRef(
    new DataProvider(
      (r1, r2) => r1?.service_request_id !== r2?.service_request_id,
    ),
  );

  const [counter, setCounter] = useState(0);
  const messages = useSelector((state) => state.messages);

  useEffect(() => {
    if (isFocused) {
      dispatch(fetchMessages());
    }
  }, [isFocused]);

  useEffect(() => {
    dataProvider.current = dataProvider.current.cloneWithRows(messages);
    listViewRef.current?.forceRerender();
    setCounter(counter + 1);
  }, [messages]);

  const layoutProvider = useMemo(() => {
    const provider = new LayoutProvider(
      (index) => index,
      (index, dim) => {
        dim.width = width;
        dim.height = ROW_HEIGHT;
      },
    );

    provider.shouldRefreshWithAnchoring = false;
    return provider;
  }, [width]);

  const onRowRenderer = (type, data) => {
    return (
      <>
        <View style={[sy['py-3']]}>
          <Message {...data} style={[{minHeight: 64}]} />
        </View>
        <Divider style={sy['bg-gray95']} />
      </>
    );
  };

  return (
    <View style={[sy.mainView, sy['bg-gray95']]}>
      <View style={[sy['px-4'], sy['pt-4'], sy['pb-3'], sy['bg-white']]}>
        <DealerHeader
          titleStyle={{textTransform: 'capitalize'}}
          title="Berichten"
          icon={<ChatIcon fill="#ffffff" height={18} width={18} />}
        />
      </View>
      <Divider style={sy['bg-gray95']} />
      <RecyclerListView
        ref={listViewRef}
        scrollViewProps={{contentContainerStyle: sy['bg-white']}}
        dataProvider={dataProvider.current}
        layoutProvider={layoutProvider}
        rowRenderer={onRowRenderer}
        initialRenderIndex={0}
      />
    </View>
  );
};

export default withDimensions(Overview);
