import React, {useEffect, useState, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';
import moment from 'moment';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Checkbox,
  Link,
  Pressable,
  Dialog,
  FormNumberInput,
  RobTaskIcon,
  MoreMenu,
  WarrantyIcon,
  Quantity,
  PriceCheck,
  ArticleText,
  SlaArticleText,
  ActionButton,
  Grouping,
  Menu,
} from '~/components/controls';
import {Row as GroupingRow} from '~/components/controls/Grouping';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {
  AddArticleActionsheet,
  AddPartActionsheet,
  OperationActionsheet,
  LocationActionsheet,
  LeniencyActionsheet,
  RobCodeActionsheet,
} from '~/components/shared/Actionsheets';
import {
  DiscountDialog,
  MarkupDialog,
  DamageNotAllowedDialog,
  DurationDialog,
} from '~/components/shared/Dialogs';
import {HOUR_TYPES as DURATION_HOUR_TYPES} from '~/components/shared/Dialogs/duration';
import {
  service_request as serviceRequestApi,
  rob as robApi,
} from '~/api/private';
import Format from '~/lib/format';
import {isRobLeniencyTask, LOCATION_TEXT} from '~/types/rob';
import {showTecrmiDialog} from '~/actions';
import {useDispatch} from '~/lib/hooks';
import {SOURCES} from '~/types/sources';
import withDimensions from '~/components/hoc/with-dimensions';
import {SearchWorkModal} from '~/components/shared/Modals';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import CheckIcon from '~/images/md-icons/check/materialicons/24px.svg';
import HistoryIcon from '~/images/md-icons/history/materialicons/24px.svg';
import HistoryToggleOffIcon from '~/images/md-icons/history_toggle_off/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import RestartIcon from '~/images/md-icons/restart/materialicons/24px.svg';
import LocalOfferIcon from '~/images/md-icons/local_offer/materialicons/24px.svg';
import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';
import FactoryIcon from '~/images/md-icons/factory/materialicons/24px.svg';
import CreateIcon from '~/images/md-icons/create/materialicons/24px.svg';

const SCREENS = {
  TASK: 'TASK',
  WORK: 'WORK',
  ROB_CODE: 'ROB_CODE',
};

const Activity = ({disabled, rob_code, description, onPress}) => (
  <GroupingRow
    style={[sy['gap-2']]}
    contentStyle={[sy['flex-1']]}
    title={<Text style={sy.mediumPlus}>Activiteit</Text>}>
    <Link
      disabled={disabled}
      textStyle={[sy.truncate, sy['text-right']]}
      onPress={onPress}>
      {rob_code ? (
        <>
          {rob_code}&nbsp;&middot;&nbsp;
          {description}
        </>
      ) : (
        <>Keuze maken</>
      )}
    </Link>
  </GroupingRow>
);

const Operation = ({
  rob_component,
  operation_code,
  reason_code,
  onShowActionsheet,
}) => {
  if (!rob_component) {
    return <></>;
  }

  const operation = rob_component.operations.find(
    (item) => item.operation.code === operation_code,
  );

  const reason = operation?.reasons.find(
    (item) => item.code === reason_code,
  )?.description;

  return (
    <GroupingRow
      style={[sy['gap-2']]}
      contentStyle={[sy['flex-1']]}
      title={<Text style={sy.mediumPlus}>Bewerking</Text>}>
      <Link
        textStyle={[sy.truncate, sy['text-right']]}
        onPress={onShowActionsheet}>
        {operation ? (
          <>
            {operation.operation.description}
            {reason ? <>&nbsp;({reason})</> : <></>}
          </>
        ) : (
          <>Keuze maken</>
        )}
      </Link>
    </GroupingRow>
  );
};

const Location = ({
  rob_component,
  operation_code,
  location,
  onShowActionsheet,
}) => {
  const operation = rob_component?.operations.find(
    (item) => item.operation.code === operation_code,
  );
  if (!operation || !operation.locations.length) {
    return <></>;
  }

  return (
    <GroupingRow
      style={[sy['gap-2']]}
      contentStyle={[sy['flex-1']]}
      title={<Text style={sy.mediumPlus}>Locatie</Text>}>
      <Link
        textStyle={[sy.truncate, sy['text-right']]}
        onPress={onShowActionsheet}>
        {location
          ?.split('')
          .map((item) => LOCATION_TEXT[item])
          .filter(Boolean)
          .join(' • ') ?? <>Keuze maken</>}
      </Link>
    </GroupingRow>
  );
};

const Part = ({
  request_id,
  lessor,
  task_parts,
  sla_parts,
  onEditArticle,
  hasDiscount,
  partsDiscounts,
  hasMarkup,
  partsMarkups,
  onChangeQuantity,
  onChangeDiscount,
  onChangeMarkup,
}) => {
  const parts = task_parts?.reduce((acc, item) => {
    acc[item.attributes.itemMpId] = {
      itemMpId: item.attributes.itemMpId,
      ..._.pickBy(item),
    };

    return acc;
  }, {});

  return (
    <>
      {Object.values(parts ?? {}).map((item, index) => {
        const gen_art_nrs = item.gen_art_nr
          ? [item.gen_art_nr]
          : item.gen_arts ?? [];

        const sla_part = sla_parts?.find((x) =>
          gen_art_nrs.includes(x.gen_art_nr),
        );
        const is_option_part = !!item.attributes?.option_id;

        const quantity = item.quantity ?? 0;
        const description = item.description;
        const price =
          sla_part && !is_option_part
            ? (_.isNumber(item.price) ? item.price : sla_part.price) * quantity
            : _.isNumber(item.price) && _.isNumber(item.quantity)
            ? item.price * item.quantity
            : null;
        const is_sla_task_part = item.reference?.startsWith('SLA_TASK|');
        let discount_percentage;
        if (is_sla_task_part) {
          discount_percentage = Math.round(
            (item.discount / (item.quantity ?? 1) / item.price) * 100,
          );
        }

        return (
          <React.Fragment key={item.itemMpId}>
            <View style={[sy['flex-row']]}>
              <View style={[sy['p-4'], sy['pr-4']]}>
                <Quantity
                  quantity={quantity}
                  onChange={() =>
                    onChangeQuantity(
                      item,
                      item.itemMpId,
                      item.amounts ?? quantity,
                    )
                  }
                />
              </View>
              <View
                style={[
                  sy['flex-row'],
                  sy['flex-1'],
                  sy['justify-between'],
                  sy['py-4'],
                  sy['pr-4'],
                ]}>
                <Pressable
                  style={[sy['flex-1']]}
                  onPress={() =>
                    onEditArticle({
                      itemMpId: item.itemMpId,
                      description,
                      gen_art_nrs,
                      quantity,
                      amounts: item.amounts ?? 1,
                      task_part: item,
                      sla_part: is_option_part
                        ? {
                            id: item.id,
                            description: item.description,
                            price: item.price,
                            lessor,
                          }
                        : sla_part,
                    })
                  }>
                  <Text>{description}</Text>
                  <Text style={[sy.small, sy['text-lightgray'], sy.underline]}>
                    {sla_part ? (
                      <SlaArticleText
                        lessor={lessor}
                        description={sla_part.description}
                        attributes={item.attributes}
                        specifications={item.specifications}
                        request_id={request_id}
                        itemMpId={item.attributes?.itemMpId}
                      />
                    ) : _.isNumber(price) ? (
                      <ArticleText source={item.source} number={item.number} />
                    ) : (
                      <>Keuze maken</>
                    )}
                  </Text>
                </Pressable>
                <View style={[sy['self-start'], sy['items-end']]}>
                  <Pressable
                    disabled={!hasDiscount && !hasMarkup}
                    onPress={() => {
                      if (hasDiscount) {
                        onChangeDiscount(
                          item.itemMpId,
                          price,
                          partsDiscounts[item.itemMpId] ?? 0,
                        );
                      }

                      if (hasMarkup) {
                        onChangeMarkup(
                          item.itemMpId,
                          price,
                          partsMarkups[item.itemMpId] ?? 0,
                        );
                      }
                    }}>
                    <Text
                      style={[
                        sy.smallRegular,
                        (hasDiscount || hasMarkup) && sy.underline,
                      ]}>
                      {_.isNumber(price) ? (
                        Format.price(
                          Math.max(
                            price +
                              (partsMarkups[item.itemMpId] ?? 0) -
                              (partsDiscounts[item.itemMpId] ?? 0),
                            0,
                          ),
                        )
                      ) : (
                        <></>
                      )}
                    </Text>
                  </Pressable>
                  {partsDiscounts[item.itemMpId] > 0 &&
                    (is_sla_task_part && discount_percentage > 0 ? (
                      <Menu
                        anchor={
                          <DiscountIcon fill="#4A4A49" width={18} height={18} />
                        }
                        style={{marginTop: 0}}
                        contentStyle={{backgroundColor: '#6D6D6D'}}>
                        <View style={[sy['px-2'], sy['flex-row'], sy['gap-2']]}>
                          <DiscountIcon width={18} height={18} fill="#FFFFFF" />
                          <Text
                            style={[
                              sy.smallMedium,
                              {color: '#FFFFFF', whiteSpace: 'nowrap'},
                            ]}>
                            {lessor}&nbsp;{Format.number(discount_percentage)}
                            %&nbsp;korting
                          </Text>
                          <Text
                            style={[
                              sy.smallMedium,
                              sy['line-through'],
                              {color: '#FFFFFF', whiteSpace: 'nowrap'},
                            ]}>
                            {Format.price(item.price)}
                          </Text>
                        </View>
                      </Menu>
                    ) : (
                      <DiscountIcon fill="#4A4A49" width={18} height={18} />
                    ))}
                  {partsMarkups[item.itemMpId] > 0 && (
                    <LocalOfferIcon fill="#4A4A49" width={18} height={18} />
                  )}
                </View>
              </View>
            </View>
            {index < parts.length - 1 && (
              <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const History = ({mileage, work_parts, task_parts, history}) => {
  const item_mp_ids = [
    ...new Set([
      ...(work_parts?.map((item) => item.itemMpId) ?? []),
      ...(task_parts?.map((item) => item.attributes.itemMpId) ?? []),
    ]),
  ];

  const parts = item_mp_ids
    .map((item_mp_id) => history?.[item_mp_id])
    .filter(Boolean)
    // Strip location information
    .flatMap((item) => Object.values(item));

  if (parts.length === 0) {
    return (
      <View style={[sy['p-4'], sy['flex-row']]}>
        <Text>Geen historie bekend</Text>
      </View>
    );
  }

  return parts.map((part, index) => (
    <React.Fragment key={index}>
      <View style={[sy['p-4'], sy['flex-row']]}>
        <Quantity quantity={part.quantity} />
        <View style={[sy['flex-1'], sy['flex-row'], sy['justify-between']]}>
          <View style={[sy['px-4'], sy['flex-1']]}>
            <Text style={[sy.truncate]}>{part.description}</Text>
            <Text style={[sy.small, sy['text-lightgray']]}>{part.number}</Text>
          </View>
          {_.isNumber(part.mileage) && _.isNumber(mileage) ? (
            <View style={sy['items-end']}>
              <Text>
                {Format.number(mileage - part.mileage, 0, true)}&nbsp; km
              </Text>
              <Text style={[sy.small, sy['text-lightgray']]}>
                {Format.number(
                  moment().diff(moment(part.date), 'months') / 12,
                  1,
                  true,
                  {
                    minimumFractionDigits: 0,
                  },
                )}
                &nbsp;jaar
              </Text>
            </View>
          ) : (
            <Text>
              {Format.number(
                moment().diff(moment(part.date), 'months') / 12,
                1,
                true,
                {
                  minimumFractionDigits: 0,
                },
              )}
              &nbsp;jaar
            </Text>
          )}
        </View>
      </View>
      {index < parts.length - 1 && <Divider style={sy['bg-gray95']} />}
    </React.Fragment>
  ));
};

const calculateTaskAmount = (
  selected,
  tasks,
  works,
  hourly_rate,
  discount,
  partsDiscounts,
  markup,
  partsMarkups,
) => {
  let task_amount;
  if (selected?.warranty === 'Warranty') {
    task_amount = 0;
  } else if (isRobLeniencyTask(selected?.warranty)) {
    task_amount = selected?.leniency_amount;
  } else {
    const parts_amount = Object.values(selected?.parts ?? {})
      .filter((item) => _.isNumber(item.price) && _.isNumber(item.quantity))
      .reduce(
        (acc, item) =>
          acc +
          Math.max(
            item.price * item.quantity +
              (partsMarkups[item.attributes.itemMpId] ?? 0) -
              (partsDiscounts[item.attributes.itemMpId] ?? 0),
            0,
          ),
        0,
      );

    if (
      !tasks.some((task) => task.type === 'SLA_TASK_DURATION') &&
      !_.isNumber(selected.original_duration) &&
      selected?.works?.length > 0
    ) {
      task_amount = (works ?? [])
        .filter((item) => selected?.works.includes(item.work_id))
        .reduce((acc, item) => {
          const task = tasks.find(
            (task) => parseInt(task.reference) === item.work_id,
          );

          const duration = task?.duration ?? item.work_time;
          return acc + duration * (hourly_rate ?? 0);
        }, 0);
    } else {
      task_amount = selected.duration * (hourly_rate ?? 0);
    }

    task_amount += (parts_amount ?? 0) + (markup ?? 0) - (discount ?? 0);
  }

  return task_amount;
};

const TaskAmount = ({
  selected,
  tasks,
  works,
  hourly_rate,
  discount,
  partsDiscounts,
  markup,
  partsMarkups,
  style,
}) => {
  const task_amount = calculateTaskAmount(
    selected,
    tasks,
    works,
    hourly_rate,
    discount,
    partsDiscounts,
    markup,
    partsMarkups,
  );

  return <Text style={style}>{Format.price(task_amount)}</Text>;
};

const HEADER_HEIGHT_IN_PX = 72;
const FOOTER_HEIGHT_IN_PX = 52;

const Task = ({
  dimensions,
  onDismiss,
  license,
  is_fleet_car,
  is_retail_car,
  vin_number,
  mileage,
  tecrmi_enabled,
  request_id,
  dealer_id,
  lessor,
  main_task,
  tasks,
  parts,
  component,
  rob,
  onChange,
  onDelete,
  onShowDenialReasons,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(SCREENS.TASK);
  const [works, setWorks] = useState(null);
  const [work_parts, setWorkParts] = useState(null);
  const [codes, setCodes] = useState([main_task.rob_code].filter(Boolean));
  const [selected, setSelected] = useState({
    operation: main_task.operation,
    reason: main_task.reason,
    location: main_task.location,
    rob_code: main_task.rob_code,
    duration: tasks.reduce((acc, item) => acc + item.duration, 0),
    parts:
      parts
        ?.filter((item) => item.attributes?.itemMpId)
        .reduce((acc, item) => {
          acc[item.attributes.itemMpId] = item;
          return acc;
        }, {}) ?? {},
    warranty: main_task.warranty,
    leniency_amount: main_task.task_amount,
    is_damage: main_task.is_damage,
    works: tasks
      .map((item) =>
        main_task?.type === 'SLA_TASK_DURATION' ||
        main_task?.source === 'Fabrikant'
          ? item.reference
          : parseInt(item.reference),
      )
      .filter(Boolean),
    original_duration: main_task.original_duration,
  });
  const [taskDiscount, setTaskDiscount] = useState(main_task.discount);
  const [partsDiscounts, setPartsDiscounts] = useState(
    parts
      ?.filter((item) => item.attributes?.itemMpId)
      .reduce((acc, item) => {
        acc[item.attributes.itemMpId] = item.discount;
        return acc;
      }, {}) ?? {},
  );
  const [taskMarkup, setTaskMarkup] = useState(main_task.markup);
  const [partsMarkups, setPartsMarkups] = useState(
    parts
      ?.filter((item) => item.attributes?.itemMpId)
      .reduce((acc, item) => {
        acc[item.attributes.itemMpId] = item.markup;
        return acc;
      }, {}) ?? {},
  );
  const [history, setHistory] = useState(null);
  const [hasHistory, setHasHistory] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [slaParts, setSlaParts] = useState(null);
  const [slaCodeParts, setSlaCodeParts] = useState(null);
  const [slaOperations, setSlaOperations] = useState(null);
  const [rob_components, setRobComponents] = useState(null);
  const [selectedWorks, setSelectedWorks] = useState(null);
  const [priceCheck, setPriceCheck] = useState(null);
  const [hasDiscount, _setHasDiscount] = useState(
    main_task.discount > 0 || parts?.some((item) => item.discount > 0),
  );
  const [hasMarkup, _setHasMarkup] = useState(
    main_task.markup > 0 || parts?.some((item) => item.markup > 0),
  );
  const [componentsWithDamageAllowed, setComponentsWithDamageAllowed] =
    useState(true);
  const [taskOptions, setTaskOptions] = useState(null);

  const toggleHasDiscount = () => {
    _setHasDiscount((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setTaskDiscount(0);
        setPartsDiscounts({});
      } else {
        setTaskMarkup(0);
        setPartsMarkups({});
      }

      return newState;
    });
  };

  const toggleHasMarkup = () => {
    _setHasMarkup((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setTaskMarkup(0);
        setPartsMarkups({});
      } else {
        setTaskDiscount(0);
        setPartsDiscounts({});
      }

      return newState;
    });
  };

  const [showEditNumber, setShowEditNumber] = useState(null);
  const [editArticle, setEditArticle] = useState(null);

  const [showPartsHistory, setShowPartsHistory] = useState(false);
  const [showOperationActionsheet, setShowOperationActionsheet] =
    useState(false);
  const [showLocationActionsheet, setShowLocationActionsheet] = useState(false);
  const [showLeniencyActionsheet, setShowLeniencyActionsheet] = useState(false);
  const [showWarrantyInfoDialog, setShowWarrantyInfoDialog] = useState(false);
  const [showDamageInfoDialog, setShowDamageInfoDialog] = useState(false);
  const [showAddPartActionsheet, setShowAddPartActionsheet] = useState(false);
  const [showDiscountDialog, setShowDiscountDialog] = useState(null);
  const [showMarkupDialog, setShowMarkupDialog] = useState(null);
  const [showDamageNotAllowedDialog, setShowDamageNotAllowedDialog] =
    useState(false);
  const [showDurationDialog, setShowDurationDialog] = useState(false);

  const [searchWork, setSearchWork] = useState(false);

  const transform_work_parts = useCallback(
    (work_parts) => {
      const parts = Object.fromEntries(
        Object.entries(selected.parts).filter(
          ([k, v]) =>
            v.source === SOURCES.Fource ||
            v.source === SOURCES.PartsPoint ||
            slaCodeParts[main_task.rob_code]?.includes(`${v.gen_art_nr}`),
        ),
      );

      for (const part of work_parts ?? []) {
        if (!parts[part.itemMpId]) {
          parts[part.itemMpId] = {
            ...part,
          };
        }
      }

      return parts;
    },
    [selected, slaCodeParts, main_task],
  );

  const onPrefixChangeWork = useCallback(async (value) => {
    const {result, success} = await serviceRequestApi.search_works(
      request_id,
      value,
      {
        include_parts: true,
      },
    );

    return {result, success};
  }, []);

  const onAddWorks = useCallback(
    (changes) => {
      setWorks([...works, ...changes]);

      setSelectedWorks([
        ...selectedWorks,
        ...changes.map((item) => item.work_id),
      ]);

      for (const work of changes ?? []) {
        if (work.parts) {
          setWorkParts({
            ...work_parts,
            [work.work_id]: work.parts,
          });
        }
      }

      setSearchWork(false);
    },
    [works, work_parts, selectedWorks],
  );

  useEffect(() => {
    const fetch = async () => {
      if (main_task.id) {
        const {result} = await serviceRequestApi.task(request_id, main_task.id);

        const {task, works, parts: work_parts, codes} = result;

        setWorks(works);
        setWorkParts(work_parts);
        setCodes([...new Set([...codes, main_task.rob_code].filter(Boolean))]);

        if (task.options) {
          setTaskOptions(task.options);

          if (!task.options.discount) {
            _setHasDiscount(false);
          }

          if (!task.options.markup) {
            _setHasMarkup(false);
          }
        }
      }

      if (!is_fleet_car && !is_retail_car) {
        const {result: rob_components} = await robApi.components(
          license,
          request_id,
        );
        setRobComponents(rob_components);
        const {result: rob_contract} = await robApi.contract(
          license,
          request_id,
        );
        setComponentsWithDamageAllowed(
          rob_contract?.supplierContract.repairAndMaintenance
            .componentsWithDamageAllowed,
        );
      }

      const response = await serviceRequestApi.car_history(request_id);
      const {history} = response.result;
      setHistory(history);

      const {result: sla_result} = await serviceRequestApi.sla(request_id);
      setSlaParts(sla_result?.parts);
      setSlaCodeParts(
        sla_result?.codes
          .filter((item) => item.parts)
          ?.reduce((acc, item) => ({...acc, [item.rob_code]: item.parts}), {}),
      );
      setSlaOperations(
        sla_result?.codes
          .filter((item) => item.operations)
          ?.reduce(
            (acc, item) => ({...acc, [item.rob_code]: item.operations}),
            {},
          ),
      );

      setLoading(false);
    };

    fetch();
  }, [request_id, license, main_task, rob, vin_number, lessor]);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.sla_hourly_rate(
        request_id,
        selected.rob_code,
      );
      setHourlyRate(result.hourly_rate);
    };

    fetch();
  }, [request_id, selected?.rob_code]);

  useEffect(() => {
    const fetch = async () => {
      const {result: price_check} = await robApi.task_price_check(
        lessor,
        selected.rob_code,
        vin_number,
        hourlyRate,
      );

      setPriceCheck(price_check);
    };

    if (selected?.rob_code && hourlyRate) {
      fetch();
    }
  }, [selected?.rob_code, hourlyRate, vin_number, lessor]);

  useEffect(() => {
    if (!selected || !history) {
      return;
    }

    const itemMpIds = [
      ...new Set(
        Object.values(selected.parts).map((item) => item.attributes.itemMpId) ??
          [],
      ),
    ];

    setHasHistory(
      Object.keys(history).some((itemMpId) =>
        itemMpIds.includes(parseInt(itemMpId)),
      ),
    );
  }, [selected, history]);

  useEffect(() => {
    setSelected((prevState) => {
      if (!slaParts) {
        return prevState;
      }

      let changed = false;

      const parts = {...prevState.parts};

      // SLA part options
      const sla_part_options = slaParts
        .filter((p) => p.options?.length)
        .filter((p) =>
          Object.values(parts ?? {})
            .map((part) => part.gen_art_nr)
            .includes(p.gen_art_nr),
        )
        .flatMap((p) => p.options);

      for (const option of sla_part_options) {
        if (
          option.condition?.rob_operation_code?.includes(prevState.operation)
        ) {
          if (!parts[option.id]) {
            changed = true;
            parts[option.id] = {
              gen_art_nr: option.gen_art_nr,
              description: option.item,
              price: option.price,
              quantity: 1,
              attributes: {
                itemMpId: option.id,
                option_id: option.id,
              },
            };
          }
        } else {
          if (parts[option.id]) {
            changed = true;
            delete parts[option.id];
          }
        }
      }

      if (changed) {
        return {
          ...prevState,
          parts,
        };
      }

      return prevState;
    });
  }, [selected, slaParts]);

  const onChangeOperation = (operation_code, reason_code) => {
    const sla_operation = slaOperations?.[selected?.rob_code]?.find(
      (x) => x.operation === operation_code,
    );

    if (sla_operation) {
      setSelected({
        ...selected,
        operation: operation_code,
        reason: reason_code,
        duration: sla_operation.amount / hourlyRate,
        parts: [],
        works: [],
      });
      setTaskDiscount(0);

      return;
    }

    if (selected.operation !== operation_code) {
      setSelected({
        ...selected,
        operation: operation_code,
        reason: reason_code,
        duration: 0,
        original_duration: null,
        works: [],
      });

      setTaskDiscount(0);
      setTaskMarkup(0);
    } else {
      setSelected({
        ...selected,
        operation: operation_code,
        reason: reason_code,
      });
    }
  };

  const onAddParts = (parts) => {
    const changes =
      parts?.reduce((acc, {id, ...item}) => {
        acc[id] = {
          ...item,
        };
        return acc;
      }, {}) ?? {};

    setSelected({
      ...selected,
      parts: {...(selected.parts ?? {}), ...changes},
    });

    setShowAddPartActionsheet(false);
  };

  const retrieveChanges = () => {
    let changed_tasks;
    if (main_task?.type !== 'SLA_TASK_DURATION' && selected.works?.length > 0) {
      // Make sure the main task is the first in the array.
      const existing_tasks = [
        main_task,
        ...tasks.filter((item) => item.id !== main_task.id),
      ];

      changed_tasks = selected.works
        .sort()
        .map((work_id, idx) => {
          const existing_task = existing_tasks[idx] ?? {};
          const work = works.find((item) => item.work_id === work_id);

          if (!work) {
            return null;
          }

          return {
            ...existing_task,
            component_id: main_task.component_id,
            rob_code: selected.rob_code,
            description: work.text ?? work.work_text,
            work_id,
            reference: work_id,
            work_time: _.isNumber(selected.original_duration)
              ? existing_task.id === main_task.id
                ? selected.duration
                : 0
              : work.work_time,
            work_text: work.text ?? work.work_text,
            work_item_mp_id: work.item_mp_id ?? work.work_item_mp_id,
            number: work.work_number ?? work.number,
            source: work.source,
          };
        })
        .filter(Boolean);
    } else {
      changed_tasks = [
        {
          ...main_task,
          work_id: null,
          reference:
            main_task?.type === 'SLA_TASK_DURATION'
              ? main_task?.reference
              : null,
          work_time: null,
          work_text: main_task?.work_text,
          work_item_mp_id: null,
          source: null,
          number: null,
          original_duration: null,
        },
      ];
    }

    return {
      task_id: main_task?.id,
      rob_code: selected.rob_code,
      duration: selected.duration,
      original_duration: selected.original_duration,
      operation: selected.operation,
      reason: selected.reason,
      location: selected.location,
      parts: Object.values(selected.parts).map((part) => ({
        ...part,
        discount: Math.min(
          partsDiscounts[part.attributes.itemMpId] ?? 0,
          part.price * part.quantity,
        ),
        markup: partsMarkups[part.attributes.itemMpId] ?? 0,
      })),
      warranty: selected.warranty,
      leniency_amount: isRobLeniencyTask(selected.warranty)
        ? selected.leniency_amount
        : null,
      is_damage: selected.is_damage,
      discount: taskDiscount,
      markup: taskMarkup,
      tasks: changed_tasks,
    };
  };

  const onOK = () => {
    const changes = retrieveChanges();

    onChange({
      ...changes,
      task_id: main_task.id,
    });
  };

  const onResetParts = () => {
    let parts = {};

    for (const work_id of selected?.works ?? []) {
      parts = {
        ...parts,
        ...transform_work_parts(work_parts?.[work_id]),
      };
    }

    setSelected({
      ...selected,
      parts: {...parts},
    });
  };

  if (showOperationActionsheet) {
    return (
      <OperationActionsheet
        onDismiss={() => setShowOperationActionsheet(false)}
        license={license}
        request_id={request_id}
        rob_code={selected.rob_code}
        operation_code={selected.operation}
        reason_code={selected.reason}
        onChange={({operation, reason}) => {
          onChangeOperation(operation, reason);

          setShowOperationActionsheet(false);
        }}
      />
    );
  }

  if (showLocationActionsheet) {
    return (
      <LocationActionsheet
        onDismiss={() => setShowLocationActionsheet(false)}
        license={license}
        request_id={request_id}
        rob_code={selected.rob_code}
        operation_code={selected.operation}
        location={selected.location}
        onChange={({location}) => {
          setSelected({
            ...selected,
            location,
          });

          setShowLocationActionsheet(false);
        }}
      />
    );
  }

  if (showAddPartActionsheet) {
    return (
      <AddPartActionsheet
        request_id={request_id}
        dealer_id={dealer_id}
        license={license}
        parts={Object.values(selected.parts).map((item) => ({
          ...item,
          id: item.itemMpId ?? item.attributes.itemMpId,
        }))}
        lessor={lessor}
        rob_code={selected.rob_code}
        onChange={onAddParts}
        onDismiss={() => setShowAddPartActionsheet(false)}
      />
    );
  }

  if (editArticle) {
    return (
      <AddArticleActionsheet
        dismissable={false}
        onDismiss={() => setEditArticle(null)}
        request_id={request_id}
        license={license}
        dealer_id={dealer_id}
        description={editArticle.description}
        gen_arts={editArticle.gen_art_nrs}
        item_mp_id={editArticle.itemMpId}
        number={editArticle.task_part?.number}
        reference={editArticle.task_part?.reference}
        sla_part={editArticle.sla_part}
        task_part={editArticle.task_part}
        lessor={lessor}
        rob_code={selected.rob_code}
        onChange={({
          reference,
          number,
          source,
          description,
          price,
          quantity,
          gen_art_nr,
          specifications,
          lead_surcharge,
        }) => {
          if (lead_surcharge > 0) {
            selected.parts['LEAD_SURCHARGE'] = {
              reference: 'LEAD_SURCHARGE',
              source,
              description: 'Loodtoeslag',
              price: lead_surcharge,
              quantity: 1,
              attributes: {
                itemMpId: 'LEAD_SURCHARGE',
              },
            };
          }

          if (selected.parts[editArticle.itemMpId]) {
            selected.parts[editArticle.itemMpId] = {
              ...selected.parts[editArticle.itemMpId],
              reference,
              number,
              source: source ?? editArticle.task_part?.source,
              description,
              price,
              gen_art_nr,
              quantity:
                _.isNumber(editArticle.task_part?.quantity) &&
                editArticle.task_part?.quantity !== 1
                  ? editArticle.task_part?.quantity
                  : Math.max(
                      editArticle.task_part?.quantity ?? 0,
                      editArticle.amounts ?? 0,
                      quantity ?? 0,
                      1,
                    ),
              attributes: {
                ...(selected.parts[editArticle.itemMpId].attributes ?? {}),
                itemMpId: editArticle.itemMpId,
              },
              specifications,
            };

            setSelected({
              ...selected,
              parts: {...selected.parts},
            });
          } else {
            setSelected({
              ...selected,
              parts: {
                ...selected.parts,
                [editArticle.itemMpId]: {
                  reference,
                  number,
                  source,
                  description,
                  price,
                  gen_art_nr,
                  quantity: Math.max(
                    editArticle.quantity ?? 0,
                    editArticle.amounts ?? 0,
                    quantity ?? 0,
                    1,
                  ),
                  attributes: {
                    itemMpId: editArticle.itemMpId,
                  },
                  specifications,
                },
              },
            });
          }

          setEditArticle(null);
        }}
        onRemove={() => {
          selected.parts[editArticle.itemMpId] = null;

          setSelected({
            ...selected,
            parts: {..._.pickBy(selected.parts)},
          });

          setEditArticle(null);
        }}
      />
    );
  }

  if (showLeniencyActionsheet) {
    return (
      <LeniencyActionsheet
        onDismiss={() => setShowLeniencyActionsheet(false)}
        value={selected.warranty}
        task_amount={main_task.task_amount}
        onChange={(leniency, leniency_amount) => {
          setSelected({
            ...selected,
            warranty: leniency,
            leniency_amount,
            is_damage: false,
          });
          setShowLeniencyActionsheet(false);
        }}
      />
    );
  }

  if (screen === SCREENS.ROB_CODE) {
    return (
      <RobCodeActionsheet
        request_id={request_id}
        license={license}
        codes={codes}
        rob_code={selected?.rob_code}
        onDismiss={() => setScreen(SCREENS.TASK)}
        onChange={(rob_code) => {
          setSelected({
            ...selected,
            rob_code,
            operation: rob_components[rob_code]?.operations[0].operation.code,
            reason: rob_components[rob_code]?.operations[0].reasons[0].code,
          });

          setScreen(SCREENS.TASK);
        }}
      />
    );
  }

  const task_works =
    selected.works?.length > 0
      ? works?.filter((w) => selected.works?.includes(w.work_id))
      : null;

  const duration = selected.duration;

  const has_overlap =
    selected.works?.length > 0 &&
    tasks.some((t) => _.isNumber(t.calculated_work_time));

  const has_original_duration =
    task_works?.length > 0 &&
    _.isNumber(selected.original_duration) &&
    selected.original_duration !== duration;

  const sla_operation = slaOperations?.[selected.rob_code]?.find(
    (o) => o.operation === selected.operation,
  );

  return (
    <>
      <Actionsheet visible={true} onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            {screen === SCREENS.TASK && (
              <>
                <Title>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['justify-between'],
                      sy['items-center'],
                      sy['flex-1'],
                      {
                        width: '100%',
                      },
                    ]}>
                    <Text style={[sy.largePlus, sy.truncate, {lineHeight: 30}]}>
                      {selected.rob_code
                        ? rob_components?.[selected.rob_code].description ??
                          component?.name
                        : component?.name
                            .replace(main_task.rob_code, '')
                            .trim()}
                    </Text>
                    <View style={[sy['flex-row']]}>
                      {onDelete && (
                        <Pressable onPress={() => onDelete(main_task.id)}>
                          <DeleteIcon fill="#4a4a49" />
                        </Pressable>
                      )}
                      {!rob && !!priceCheck && (
                        <PriceCheck
                          rob_code={selected.rob_code}
                          amount={calculateTaskAmount(
                            selected,
                            tasks,
                            works,
                            hourlyRate,
                            taskDiscount,
                            partsDiscounts,
                            taskMarkup,
                            partsMarkups,
                          )}
                          range={priceCheck?.range}
                          duration={selected.duration}
                          suggested_duration={priceCheck?.suggested_duration}
                          onChangeDuration={(suggested, original) =>
                            setSelected({
                              ...selected,
                              duration: suggested,
                              original_duration:
                                task_works?.length > 0 ? original : null,
                            })
                          }
                        />
                      )}
                      {rob?.status.code && (
                        <Pressable
                          style={[sy['flex-row']]}
                          onPress={() => onShowDenialReasons()}>
                          <RobTaskIcon
                            statuses={[rob?.status.code]}
                            height="24"
                            width="24"
                          />
                        </Pressable>
                      )}
                    </View>
                  </View>
                </Title>
                <Divider />

                <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
                  <ScrollView
                    contentContainerStyle={{
                      maxHeight:
                        dimensions.window.height -
                        HEADER_HEIGHT_IN_PX -
                        FOOTER_HEIGHT_IN_PX,
                    }}>
                    <View style={[sy['p-4']]}>
                      <View style={[sy['flex-row']]}>
                        <View style={[sy['pr-8']]}>
                          {selected.works?.length > 0 ? (
                            <FactoryIcon />
                          ) : (
                            <CreateIcon />
                          )}
                        </View>
                        <View style={[sy['flex-1']]}>
                          <View style={[sy['flex-row'], sy['justify-between']]}>
                            <Text>Arbeidstijd</Text>
                            <View
                              style={[
                                sy['self-start'],
                                sy['items-end'],
                                sy['flex-row'],
                                sy['gap-2'],
                              ]}>
                              {taskMarkup > 0 ? (
                                <LocalOfferIcon
                                  fill="#4A4A49"
                                  width={18}
                                  height={18}
                                />
                              ) : taskDiscount > 0 ? (
                                <DiscountIcon
                                  fill="#4A4A49"
                                  width={18}
                                  height={18}
                                />
                              ) : (
                                <></>
                              )}
                              <Pressable
                                disabled={!hasDiscount && !hasMarkup}
                                onPress={() => {
                                  if (hasDiscount) {
                                    setShowDiscountDialog({
                                      discount: taskDiscount,
                                      amount: duration * hourlyRate,
                                      onChange: setTaskDiscount,
                                    });
                                  }

                                  if (hasMarkup) {
                                    setShowMarkupDialog({
                                      markup: taskMarkup,
                                      amount: duration * hourlyRate,
                                      onChange: setTaskMarkup,
                                    });
                                  }
                                }}>
                                <Text
                                  style={[
                                    sy.smallRegular,
                                    (hasDiscount || hasMarkup) && sy.underline,
                                  ]}>
                                  {Format.price(
                                    duration * hourlyRate +
                                      taskMarkup -
                                      taskDiscount,
                                  )}
                                </Text>
                              </Pressable>
                            </View>
                          </View>
                          <View>
                            <View
                              style={[
                                sy['flex-row'],
                                sy['justify-between'],
                                sy['gap-2'],
                              ]}>
                              <View style={[sy['flex-1']]}>
                                {main_task?.source === 'Fabrikant' ? (
                                  <Text
                                    style={[sy.small, sy['text-lightgray']]}>
                                    Fabrikant&nbsp;&middot;&nbsp;
                                    {main_task.work_text}
                                  </Text>
                                ) : main_task?.type === 'SLA_TASK_DURATION' ? (
                                  <Text
                                    style={[sy.small, sy['text-lightgray']]}>
                                    Bahnkick&nbsp;&middot;&nbsp;
                                    {main_task.work_text}
                                  </Text>
                                ) : sla_operation ? (
                                  <Text
                                    style={[sy.small, sy['text-lightgray']]}>
                                    SLA&nbsp;&middot;&nbsp;
                                    {sla_operation.description}
                                  </Text>
                                ) : (
                                  <Link
                                    textStyle={[
                                      sy.small,
                                      sy['text-lightgray'],
                                      sy.truncate,
                                    ]}
                                    onPress={() => {
                                      setSelectedWorks(
                                        task_works ? selected.works : null,
                                      );
                                      setScreen(SCREENS.WORK);
                                    }}>
                                    {(selected.works?.length > 0 &&
                                      main_task?.work_text) ||
                                    task_works?.length > 0 ? (
                                      <>
                                        <>TecRMI&nbsp;&middot;&nbsp;</>
                                        {task_works?.length > 0 ? (
                                          task_works.map((w, index, array) => (
                                            <React.Fragment key={w.work_id}>
                                              <>{w.text ?? w.work_text}</>
                                              {index < array.length - 1 ? (
                                                <>,&nbsp;</>
                                              ) : (
                                                <></>
                                              )}
                                            </React.Fragment>
                                          ))
                                        ) : (
                                          <>{main_task?.work_text}</>
                                        )}
                                      </>
                                    ) : _.isNumber(duration) ? (
                                      <>Invoer</>
                                    ) : (
                                      <>Keuze maken</>
                                    )}
                                  </Link>
                                )}
                              </View>
                              <View style={[sy['flex-row'], sy['gap-2']]}>
                                {has_original_duration ? (
                                  <Text
                                    style={[
                                      sy.smallRegular,
                                      sy['text-lightgray'],
                                      sy['line-through'],
                                    ]}>
                                    {Format.number(
                                      selected.original_duration,
                                      2,
                                    )}
                                    &nbsp;u
                                  </Text>
                                ) : has_overlap ? (
                                  <Text
                                    style={[
                                      sy.smallRegular,
                                      sy['text-lightgray'],
                                      sy['line-through'],
                                    ]}>
                                    {Format.number(
                                      tasks.reduce(
                                        (acc, t) =>
                                          acc +
                                          (t.original_work_time ?? t.duration),
                                        0,
                                      ),
                                      2,
                                    )}
                                    &nbsp;u
                                  </Text>
                                ) : null}
                                {sla_operation ? (
                                  <></>
                                ) : (
                                  <Pressable
                                    onPress={() => setShowDurationDialog(true)}>
                                    <Text
                                      style={[
                                        sy.smallRegular,
                                        sy['text-lightgray'],
                                        sy.underline,
                                      ]}>
                                      {Format.number(duration, 2)}
                                      &nbsp;u
                                    </Text>
                                  </Pressable>
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <Divider style={sy['bg-gray95']} />
                    <View
                      style={[
                        sy['p-4'],
                        sy['flex-row'],
                        sy['justify-between'],
                      ]}>
                      <Text style={sy.mediumPlus}>Artikel(en)</Text>
                      <Pressable
                        onPress={() => setShowPartsHistory(!showPartsHistory)}>
                        {hasHistory && (
                          <>
                            {showPartsHistory ? (
                              <HistoryToggleOffIcon />
                            ) : (
                              <HistoryIcon />
                            )}
                          </>
                        )}
                      </Pressable>
                    </View>
                    <Divider style={sy['bg-gray95']} />
                    <View>
                      {hasHistory && showPartsHistory ? (
                        <History
                          mileage={mileage}
                          work_parts={work_parts?.[selected.work_id]}
                          task_parts={Object.values(selected.parts)}
                          history={history}
                        />
                      ) : (
                        <Part
                          request_id={request_id}
                          lessor={lessor}
                          task_parts={Object.values(selected.parts)}
                          sla_parts={slaParts}
                          onEditArticle={setEditArticle}
                          hasDiscount={hasDiscount}
                          partsDiscounts={partsDiscounts}
                          hasMarkup={hasMarkup}
                          partsMarkups={partsMarkups}
                          onChangeQuantity={(task_part, itemMpId, amounts) => {
                            setShowEditNumber({
                              title: 'Hoeveelheid',
                              label: 'Aantal',
                              value: task_part?.quantity ?? amounts,
                              onChange: (value) => {
                                if (selected.parts[itemMpId]) {
                                  selected.parts[itemMpId] = {
                                    ...selected.parts[itemMpId],
                                    quantity: value,
                                  };

                                  setSelected({
                                    ...selected,
                                    parts: {...selected.parts},
                                  });
                                } else {
                                  setSelected({
                                    ...selected,
                                    parts: {
                                      ...selected.parts,
                                      [itemMpId]: {
                                        quantity: value,
                                        attributes: {
                                          itemMpId: itemMpId,
                                        },
                                      },
                                    },
                                  });
                                }
                              },
                            });
                          }}
                          onChangeDiscount={(itemMpId, amount, discount) => {
                            setShowDiscountDialog({
                              discount,
                              amount,
                              onChange: (value) => {
                                setPartsDiscounts((prevState) => ({
                                  ...prevState,
                                  [itemMpId]: value,
                                }));
                              },
                            });
                          }}
                          onChangeMarkup={(itemMpId, amount, markup) => {
                            setShowMarkupDialog({
                              markup,
                              amount,
                              onChange: (value) => {
                                setPartsMarkups((prevState) => ({
                                  ...prevState,
                                  [itemMpId]: value,
                                }));
                              },
                            });
                          }}
                        />
                      )}
                    </View>
                    <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
                    <View style={[sy['flex-row']]}>
                      <Pressable
                        style={[sy['flex-row'], sy['flex-1']]}
                        onPress={() => setShowAddPartActionsheet(true)}>
                        <View style={[sy['p-4']]}>
                          <AddIcon fill="#231fda" />
                        </View>
                        <View style={[sy['flex-1']]}>
                          <View style={[sy['p-4']]}>
                            <Text>Artikel toevoegen</Text>
                          </View>
                        </View>
                      </Pressable>
                      {work_parts?.[selected?.work_id]?.some(
                        (part) => !selected.parts[part.itemMpId],
                      ) && (
                        <Pressable style={[sy['p-4']]} onPress={onResetParts}>
                          <RestartIcon width={24} height={24} />
                        </Pressable>
                      )}
                    </View>
                    {rob_components && (
                      <Grouping header="ROB-Net" initial={{expand: true}}>
                        <Activity
                          disabled={rob?.id}
                          rob_code={selected.rob_code}
                          description={
                            rob_components[selected?.rob_code]?.description
                          }
                          onPress={() => {
                            setScreen(SCREENS.ROB_CODE);
                          }}
                        />

                        <Operation
                          rob_component={rob_components[selected.rob_code]}
                          operation_code={selected.operation}
                          reason_code={selected.reason}
                          onShowActionsheet={() =>
                            setShowOperationActionsheet(true)
                          }
                        />

                        <Location
                          rob_component={rob_components[selected.rob_code]}
                          operation_code={selected.operation}
                          location={selected.location}
                          onShowActionsheet={() =>
                            setShowLocationActionsheet(true)
                          }
                        />
                      </Grouping>
                    )}
                  </ScrollView>
                </Content>
                <Divider />
                <Actions style={[sy['justify-between']]}>
                  <View style={[sy['flex-row'], sy['items-center']]}>
                    <TaskAmount
                      selected={selected}
                      tasks={tasks}
                      works={works}
                      hourly_rate={hourlyRate}
                      discount={taskDiscount}
                      partsDiscounts={partsDiscounts}
                      markup={taskMarkup}
                      partsMarkups={partsMarkups}
                      style={[sy.mediumBold, sy['pl-2'], sy['pr-4']]}
                    />
                    <MoreMenu
                      icon={
                        <View style={[sy['flex-row'], sy['items-center']]}>
                          {hasDiscount && <DiscountIcon fill="#4A4A49" />}
                          {hasMarkup && <LocalOfferIcon fill="#4A4A49" />}
                          {((!hasDiscount && !hasMarkup) ||
                            selected.warranty ||
                            selected.is_damage) && (
                            <WarrantyIcon
                              value={{
                                warranty: selected.warranty,
                                is_damage: selected.is_damage,
                              }}
                            />
                          )}
                        </View>
                      }
                      items={[
                        {
                          title: 'Korting',
                          disabled: taskOptions?.discount === false,
                          onPress: () => {
                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            toggleHasDiscount();

                            setSelected((prevState) => ({
                              ...prevState,
                              warranty: null,
                              leniency_amount: null,
                              is_damage: false,
                            }));
                          },
                          leadingIcon: hasDiscount ? CheckIcon : null,
                        },
                        {
                          title: 'Toeslag',
                          disabled: taskOptions?.markup === false,
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            toggleHasMarkup();

                            setSelected((prevState) => ({
                              ...prevState,
                              warranty: null,
                              leniency_amount: null,
                              is_damage: false,
                            }));
                          },
                          leadingIcon: hasMarkup ? CheckIcon : null,
                        },
                        {
                          title: 'Garantie',
                          disabled: taskOptions?.warranty === false,
                          leadingIcon:
                            selected.warranty === 'Warranty' ? CheckIcon : null,
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            if (selected.warranty !== 'Warranty') {
                              setSelected({
                                ...selected,
                                warranty: 'Warranty',
                                leniency_amount: null,
                                is_damage: false,
                              });
                              setShowWarrantyInfoDialog(true);
                            } else {
                              setSelected({
                                ...selected,
                                warranty: null,
                                leniency_amount: null,
                                is_damage: false,
                              });
                            }
                          },
                        },
                        {
                          title: 'Coulance',
                          disabled: taskOptions?.leniency === false,
                          leadingIcon: isRobLeniencyTask(selected.warranty)
                            ? CheckIcon
                            : null,
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            if (!isRobLeniencyTask(selected.warranty)) {
                              setTimeout(
                                () => setShowLeniencyActionsheet(true),
                                100,
                              );
                            } else {
                              setSelected({
                                ...selected,
                                warranty: null,
                                leniency_amount: null,
                                is_damage: false,
                              });
                            }
                          },
                        },
                        {
                          title: (
                            <>
                              {componentsWithDamageAllowed ? (
                                'Schade'
                              ) : (
                                <View style={[sy['flex-row'], sy['gap-4']]}>
                                  <Text>Schade</Text>
                                  <InfoIcon
                                    fill="#4A4A49"
                                    height={24}
                                    width={24}
                                  />
                                </View>
                              )}
                            </>
                          ),
                          disabled: taskOptions?.damage === false,
                          leadingIcon: selected.is_damage ? CheckIcon : null,
                          onPress: () => {
                            if (componentsWithDamageAllowed) {
                              if (hasDiscount) {
                                toggleHasDiscount();
                              }

                              if (hasMarkup) {
                                toggleHasMarkup();
                              }

                              if (selected.is_damage) {
                                setSelected({
                                  ...selected,
                                  warranty: null,
                                  leniency_amount: null,
                                  is_damage: false,
                                });
                              } else {
                                setSelected({
                                  ...selected,
                                  warranty: null,
                                  leniency_amount: null,
                                  is_damage: true,
                                });
                                setShowDamageInfoDialog(true);
                              }
                            } else {
                              setShowDamageNotAllowedDialog(true);
                            }
                          },
                        },
                      ]}
                    />
                  </View>
                  <View style={[sy['flex-row'], sy['gap-2']]}>
                    <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
                    <ActionButton onPress={onOK}>OK</ActionButton>
                  </View>
                </Actions>
              </>
            )}
            {screen === SCREENS.WORK && (
              <>
                <Title>
                  {is_fleet_car || is_retail_car
                    ? 'Activiteit'
                    : rob_components?.[selected.rob_code]?.operations.find(
                        (item) => item.operation.code === selected.operation,
                      )?.operation.description ?? main_task.description}
                </Title>
                <Divider />
                <Content
                  style={{
                    paddingBottom: 0,
                    paddingHorizontal: 0,
                  }}>
                  <ScrollView
                    contentContainerStyle={{
                      maxHeight:
                        dimensions.window.height -
                        HEADER_HEIGHT_IN_PX -
                        FOOTER_HEIGHT_IN_PX,
                    }}>
                    <View style={[sy['flex-row'], sy['p-4']]}>
                      <View style={[sy['pr-8']]}>
                        <RadioButton
                          checked={!!selectedWorks}
                          onPress={() => setSelectedWorks([])}
                        />
                      </View>
                      <View
                        style={[
                          sy['flex-row'],
                          sy['flex-1'],
                          sy['justify-between'],
                        ]}>
                        <Text>TecRMI</Text>
                      </View>
                    </View>
                    <View>
                      {!!selectedWorks && (
                        <>
                          {works.map((item) => (
                            <View
                              key={item.work_id}
                              style={[sy['flex-row'], sy['p-4'], sy['pl-8']]}>
                              <View style={[sy['pr-8']]}>
                                <Checkbox
                                  disabledTimeout={0}
                                  checked={selectedWorks.includes(item.work_id)}
                                  onPress={() => {
                                    if (selectedWorks?.includes(item.work_id)) {
                                      setSelectedWorks(
                                        selectedWorks.filter(
                                          (w) => w !== item.work_id,
                                        ),
                                      );
                                    } else {
                                      setSelectedWorks([
                                        ...(selectedWorks ?? []),
                                        item.work_id,
                                      ]);
                                    }
                                  }}
                                />
                              </View>
                              <View
                                style={[
                                  sy['flex-row'],
                                  sy['flex-1'],
                                  sy['justify-between'],
                                ]}>
                                <Text>
                                  {item.text ??
                                    item.description ??
                                    item.work_text}
                                </Text>
                                <Text>
                                  {Format.number(item.work_time, 2)}&nbsp;u
                                </Text>
                              </View>
                            </View>
                          ))}
                          <Pressable
                            style={[sy['flex-row']]}
                            onPress={async () => {
                              if (!tecrmi_enabled) {
                                try {
                                  await new Promise((resolve, reject) => {
                                    dispatch(
                                      showTecrmiDialog({
                                        resolve,
                                        reject,
                                      }),
                                    );
                                  });

                                  await serviceRequestApi.enable_tecrmi(
                                    request_id,
                                  );
                                } catch {
                                  return;
                                }
                              }

                              setSearchWork(true);
                            }}>
                            <View style={[sy['pl-8'], sy['p-4']]}>
                              <AddIcon fill="#231fda" />
                            </View>
                            <View style={[sy['flex-1']]}>
                              <View style={[sy['p-4']]}>
                                <Text>Taak toevoegen</Text>
                              </View>
                              <Divider style={sy['bg-gray95']} />
                            </View>
                          </Pressable>
                        </>
                      )}
                    </View>
                    <View>
                      <View style={[sy['flex-row'], sy['p-4']]}>
                        <View style={[sy['pr-8']]}>
                          <RadioButton
                            checked={!selectedWorks}
                            onPress={() => setSelectedWorks(null)}
                          />
                        </View>
                        <View
                          style={[
                            sy['flex-row'],
                            sy['flex-1'],
                            sy['justify-between'],
                          ]}>
                          <Text>Invoer</Text>
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </Content>
                <Divider />
                <Actions>
                  <View style={[sy['flex-row'], sy['gap-2']]}>
                    <ActionButton onPress={() => setScreen(SCREENS.TASK)}>
                      Annuleren
                    </ActionButton>
                    <ActionButton
                      disabled={
                        !!selectedWorks &&
                        !works.some((item) =>
                          selectedWorks.includes(item.work_id),
                        )
                      }
                      onPress={() => {
                        let parts = {
                          ...selected.parts,
                        };

                        let duration = selected.duration;
                        if (selectedWorks?.length) {
                          for (const work_id of selectedWorks) {
                            if (
                              !selected.works?.includes(work_id) &&
                              work_parts?.[work_id]
                            ) {
                              parts = {
                                ...parts,
                                ...transform_work_parts(work_parts?.[work_id]),
                              };
                            }
                          }

                          duration = works
                            .filter((w) => selectedWorks.includes(w.work_id))
                            .reduce((acc, item) => acc + item.work_time, 0);
                        }

                        setSelected({
                          ...selected,
                          original_duration: null,
                          duration,
                          works: selectedWorks,
                          parts,
                        });

                        setWorks(
                          works?.filter((w) =>
                            selectedWorks?.includes(w.work_id),
                          ),
                        );

                        setScreen(SCREENS.TASK);
                      }}>
                      OK
                    </ActionButton>
                  </View>
                </Actions>
              </>
            )}
          </>
        )}
      </Actionsheet>
      {showEditNumber && (
        <Dialog
          visible={true}
          title={showEditNumber.title}
          initialValue={showEditNumber.value}
          onDismiss={({initialValue}) => {
            showEditNumber.onChange(initialValue);
            setShowEditNumber(null);
          }}
          buttons={[
            {
              text: 'Annuleren',
              onPress: ({initialValue}) => {
                showEditNumber.onChange(initialValue);
                setShowEditNumber(null);
              },
            },
            {
              text: 'OK',
              onPress: () => setShowEditNumber(null),
            },
          ]}>
          <View style={[sy['py-4']]}>
            <FormNumberInput
              label={showEditNumber.label}
              value={showEditNumber.value}
              digits={2}
              onChangeNumber={showEditNumber.onChange}
            />
          </View>
        </Dialog>
      )}
      {showDurationDialog && (
        <DurationDialog
          priceCheck={priceCheck}
          work_source={main_task?.source}
          work_time={
            task_works?.length > 0
              ? task_works.reduce((acc, t) => {
                  const task = tasks?.find(
                    (x) => x.reference === `${t.work_id}`,
                  );

                  return acc + (task?.calculated_work_time ?? t.work_time);
                }, 0)
              : null
          }
          duration={selected.duration}
          onChange={(duration, type) => {
            const original_duration = task_works?.reduce((acc, t) => {
              const task = tasks?.find((x) => x.reference === `${t.work_id}`);

              return acc + (task?.calculated_work_time ?? t.work_time);
            }, 0);

            switch (type) {
              case DURATION_HOUR_TYPES.suggested: {
                const suggested_duration = priceCheck.suggested_duration;
                if (task_works?.length > 0) {
                  setSelected({
                    ...selected,
                    duration: suggested_duration,
                    original_duration:
                      suggested_duration !== original_duration
                        ? original_duration
                        : null,
                  });
                } else if (suggested_duration !== selected.duration) {
                  setSelected({
                    ...selected,
                    duration: suggested_duration,
                    original_duration: selected.duration,
                  });
                }

                break;
              }

              case DURATION_HOUR_TYPES.work: {
                setSelected({
                  ...selected,
                  duration: original_duration,
                  original_duration: null,
                });
                break;
              }

              case DURATION_HOUR_TYPES.custom: {
                setSelected({
                  ...selected,
                  duration,
                  original_duration:
                    task_works?.length > 0 ? original_duration : null,
                });
                break;
              }

              default: {
                break;
              }
            }
          }}
          onDismiss={() => setShowDurationDialog(false)}
        />
      )}
      {searchWork && (
        <SearchWorkModal
          visible={true}
          works={works}
          onPrefixChange={onPrefixChangeWork}
          onAdd={onAddWorks}
          onDismiss={() => setSearchWork(false)}
        />
      )}
      {showWarrantyInfoDialog && (
        <Dialog
          visible={true}
          title={main_task.description}
          onDismiss={() => setShowWarrantyInfoDialog(false)}
          buttons={[
            {
              text: 'OK',
              onPress: () => setShowWarrantyInfoDialog(false),
            },
          ]}
          options={{noPaddingContent: true}}>
          <View style={[sy['flex-row']]}>
            <View style={[sy['p-4'], {flexShrink: 0}]}>
              <WarrantyIcon
                value={{warranty: 'Warranty'}}
                width="24"
                height="24"
              />
            </View>
            <Text style={[sy['py-4']]}>
              Garantie toegepast. Bedrag voor activiteit aangepast naar 0,00
              euro.
            </Text>
          </View>
        </Dialog>
      )}
      {showDamageInfoDialog && (
        <Dialog
          visible={true}
          title="Schade"
          onDismiss={() => setShowDamageInfoDialog(false)}
          buttons={[
            {
              text: 'OK',
              onPress: () => setShowDamageInfoDialog(false),
            },
          ]}
          options={{noPaddingContent: true}}>
          <View style={[sy['flex-row']]}>
            <View style={[sy['p-4'], {flexShrink: 0}]}>
              <WarrantyIcon value={{is_damage: true}} width="24" height="24" />
            </View>
            <Text style={[sy['py-4']]}>
              Schadekenmerk toegepast voor activiteit.
            </Text>
          </View>
        </Dialog>
      )}
      {showDiscountDialog && (
        <DiscountDialog
          value={showDiscountDialog.discount}
          amount={showDiscountDialog.amount}
          onDismiss={() => setShowDiscountDialog(null)}
          onChange={showDiscountDialog.onChange}
        />
      )}
      {showMarkupDialog && (
        <MarkupDialog
          value={showMarkupDialog.markup}
          amount={showMarkupDialog.amount}
          onDismiss={() => setShowMarkupDialog(null)}
          onChange={showMarkupDialog.onChange}
        />
      )}
      {showDamageNotAllowedDialog && (
        <DamageNotAllowedDialog
          onDismiss={() => setShowDamageNotAllowedDialog(false)}
        />
      )}
    </>
  );
};

export default withDimensions(Task);
