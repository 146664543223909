import React, {useEffect, useState} from 'react';
import {ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {dealer as dealerApi} from '~/api/private';
import {
  Text,
  Pressable,
  RadioButton,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import withDimensions from '~/components/hoc/with-dimensions';

const HEADER_HEIGHT_IN_PX = 70;
const FOOTER_HEIGHT_IN_PX = 50;
const DIVIDER_HEIGHT_IN_PX = 1;

const DealerUserActionsheet = ({
  dimensions,
  visible,
  dealer_id,
  assigned_user_id,
  onDismiss,
  onOK,
}) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(assigned_user_id ?? null);

  useEffect(() => {
    const fetch = async () => {
      const result = await dealerApi.users(dealer_id);
      setUsers(result);
      setLoading(false);
    };

    fetch();
  }, [dealer_id]);

  if (loading) {
    return <></>;
  }

  return (
    <Actionsheet visible={visible} onDismiss={onDismiss}>
      <Title>Gebruiker opdracht</Title>
      <Divider />
      <Content style={[sy['pb-0']]}>
        <ScrollView
          contentContainerStyle={{
            maxHeight:
              dimensions.window.height -
              HEADER_HEIGHT_IN_PX -
              DIVIDER_HEIGHT_IN_PX -
              FOOTER_HEIGHT_IN_PX -
              DIVIDER_HEIGHT_IN_PX,
          }}>
          {!loading && (
            <>
              {[{id: null, name: 'Niet toegewezen'}, ...users].map(
                (user, index, arr) => (
                  <React.Fragment key={user.id}>
                    <Pressable
                      style={[
                        sy['py-4'],
                        sy['gap-4'],
                        sy['flex-row'],
                        sy['items-center'],
                      ]}
                      onPress={() => setSelected(user.id)}>
                      <RadioButton checked={selected === user.id} />
                      <Text>{user.name}</Text>
                    </Pressable>
                    {index < arr.length - 1 && <Divider />}
                  </React.Fragment>
                ),
              )}
            </>
          )}
        </ScrollView>
      </Content>
      <Divider />
      <Actions>
        <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
        <ActionButton onPress={() => onOK(selected)}>OK</ActionButton>
      </Actions>
    </Actionsheet>
  );
};

export default withDimensions(DealerUserActionsheet);
