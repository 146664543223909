import React, {useState, useEffect} from 'react';
import {View} from 'react-native';

import sy from '~/styles';
import {user as userApi} from '~/api/private';

import {Text} from '~/components/controls';

export default ({style, user_id}) => {
  const [label, setLabel] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const {first_name, short_form} = (await userApi.find(user_id)) ?? {};

      setLabel(short_form?.partner ?? first_name);
    };

    if (user_id) {
      fetch();
    }
  }, [user_id]);

  if (!label) {
    return null;
  }

  return (
    <View
      style={[
        sy['py-1'],
        sy['px-2'],
        {
          backgroundColor: '#B29A74',
          position: 'absolute',
          right: 0,
          bottom: 0,
          borderTopLeftRadius: 8,
        },
        style,
      ]}>
      <Text style={[sy.smallRegular, sy['text-white']]}>{label}</Text>
    </View>
  );
};
