import React, {useState, useEffect, useLayoutEffect, useCallback} from 'react';
import {View, ActivityIndicator, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';
import {useIsFocused} from '@react-navigation/native';
import {HeaderBackButton} from '@react-navigation/elements';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import {
  Text,
  Pressable,
  Dialog,
  ReplacementText,
  ServiceRequestStatusIcon,
  Checkbox,
} from '~/components/controls';
import {ReplacementActionsheet} from '~/components/shared/Actionsheets';
import {
  service_request as serviceRequestApi,
  rob as robApi,
  fleet as fleetApi,
} from '~/api/private';
import {
  REPLACEMENT_TYPE,
  ROB_REPLACEMENT_CODE,
  REPLACEMENT_TITLE,
} from '~/types/replacement';
import {
  isLessorStatus,
  isInvoiced,
  isArchived,
  isRobTaskCancelled,
} from '~/types/rob';
import {STATUS_DESCRIPTIONS, STATUS_CODES} from '~/types/statuses';
import {useDispatch} from '~/lib/hooks';
import Format from '~/lib/format';
import {REQUEST_TYPES} from '~/types/request';

import Car from './car';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import ReportIcon from '~/images/md-icons/report/materialicons/24px.svg';
import MoreVertIcon from '~/images/md-icons/more_vert/materialicons/24px.svg';

const HeaderRight = ({
  replacement,
  rob,
  fleetRequest,
  onEditType,
  onShowRobDenials,
}) => {
  if (fleetRequest || rob?.rob_id) {
    const {status} = fleetRequest ?? replacement ?? {};
    return (
      <Pressable
        disabled={
          isLessorStatus(status) ||
          [STATUS_CODES.Done, STATUS_CODES.DoneNoInvoice].includes(status) ||
          isInvoiced(status) ||
          isArchived(status)
        }
        style={{marginHorizontal: 11, marginVertical: 3}}
        onPress={onShowRobDenials}>
        <ServiceRequestStatusIcon status={status} />
      </Pressable>
    );
  }

  return (
    <>
      <Pressable
        style={{marginHorizontal: 11, marginVertical: 3}}
        onPress={onEditType}>
        <MoreVertIcon />
      </Pressable>
    </>
  );
};

const RobDenialsDialog = ({
  visible,
  request_id,
  replacement,
  rob,
  fleet_request,
  onRefresh,
  onDismiss,
}) => {
  const [cancelRequest, setCancelRequest] = useState(
    isRobTaskCancelled(rob?.task_status),
  );

  const [pending, setPending] = useState(false);

  return (
    <Dialog
      visible={visible}
      title={
        <Text style={[sy.largePlus]}>
          {fleet_request ? (
            <>{REPLACEMENT_TITLE}</>
          ) : (
            <>
              {ROB_REPLACEMENT_CODE}&nbsp;&middot;&nbsp;{REPLACEMENT_TITLE}
            </>
          )}
        </Text>
      }
      buttons={[
        {
          text: 'OK',
          textStyle: {color: '#972727'},
          disabled: pending,
          onPress: async () => {
            setPending(true);
            try {
              if (fleet_request) {
                if (cancelRequest) {
                  await fleetApi.cancel_replacement_vehicle(request_id);
                }
              } else {
                if (cancelRequest) {
                  if (!isRobTaskCancelled(rob?.task_status)) {
                    await robApi.cancel_replacement_vehicle(request_id);
                  }
                } else if (isRobTaskCancelled(rob?.task_status)) {
                  await robApi.activate_task(
                    replacement.request_id,
                    rob.rob_id,
                    replacement.request_id,
                  );
                }
              }
              await onRefresh();
            } finally {
              setPending(false);
              onDismiss();
            }
          },
        },
      ]}
      onDismiss={pending ? null : onDismiss}
      options={{noPaddingContent: true}}>
      <ScrollView
        style={[sy['p-4']]}
        contentContainerStyle={[{maxHeight: 400}, sy['gap-4']]}>
        <View style={[sy['flex-row'], sy['gap-4']]}>
          <ServiceRequestStatusIcon
            status={replacement?.status}
            height="24"
            width="24"
          />
          <Text>{STATUS_DESCRIPTIONS[replacement?.status]}</Text>
        </View>
        {rob?.denialReasons?.map((reason) => (
          <View key={reason.code} style={[sy['flex-row'], sy['gap-4']]}>
            <View>
              <ReportIcon height={24} width={24} />
            </View>
            <Text>{reason.lessorRemark ?? reason.code}</Text>
          </View>
        ))}
      </ScrollView>
      <Divider />
      <Pressable
        disabled={pending}
        style={[sy['p-4'], sy['gap-4'], sy['flex-row'], sy['items-center']]}
        onPress={() => setCancelRequest(!cancelRequest)}>
        <Checkbox disabled={pending} checked={cancelRequest} />
        <Text>Activiteit naar vervallen</Text>
      </Pressable>
    </Dialog>
  );
};

const Index = ({navigation, route}) => {
  const request_id = route.params?.request_id;

  const isFocused = useIsFocused();

  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState({});
  const [replacement, setReplacement] = useState(null);
  const [rob, setRob] = useState(null);
  const [fleetRequest, setFleetRequest] = useState(null);

  const [showEditType, setShowEditType] = useState(false);

  const [showDenialReasonsDialog, setShowDenialReasonsDialog] = useState(false);

  const dispatch = useDispatch();

  const refresh = useCallback(async () => {
    const {result} = await serviceRequestApi.get(request_id, dispatch);
    setRequest(result[0]);

    if (result[0].request_type === REQUEST_TYPES.FLEET_REQUEST) {
      const {result: replacement_vehicle} = await fleetApi.replacement_vehicle(
        request_id,
      );

      setReplacement(replacement_vehicle.replacement);
      setFleetRequest(replacement_vehicle.fleet_request);
    } else if (result[0].request_type === REQUEST_TYPES.ROB_REQUEST) {
      const {result: replacement_vehicle} = await robApi.replacement_vehicle(
        request_id,
      );

      setReplacement(replacement_vehicle.replacement);
      setRob(replacement_vehicle.rob);
    }
  }, [request_id, dispatch]);

  const navigateBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('Request', {
        request_id,
      });
    }
  }, [navigation, request_id]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={navigateBack}
        />
      ),
      headerStatusBarHeight: 0,
      headerTitleAlign: 'center',
      headerTitle: () =>
        request.replacement?.type ? (
          <View style={[sy['flex-row']]}>
            <ReplacementText
              style={sy.largePlus}
              type={request.replacement?.type}
            />
            {request.request_type !== REQUEST_TYPES.SERVICE_REQUEST &&
              request.replacement?.type === REPLACEMENT_TYPE.car && (
                <Text style={sy.largePlus}>
                  &nbsp;&middot;&nbsp;{Format.license(request.license)}
                </Text>
              )}
          </View>
        ) : (
          <Text style={sy.largePlus}>Geen keuze</Text>
        ),
      headerRight: () => (
        <HeaderRight
          replacement={replacement}
          rob={rob}
          fleetRequest={fleetRequest}
          onEditType={() => setShowEditType(true)}
          onShowRobDenials={() => setShowDenialReasonsDialog(true)}
        />
      ),
    });
  }, [navigation, navigateBack, request, replacement, rob]);

  useEffect(() => {
    const fetch = async () => {
      await refresh();
      setLoading(false);
    };

    if (isFocused) {
      fetch();
    }
  }, [isFocused, request_id, refresh]);

  return (
    <View style={sy.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          {[REQUEST_TYPES.ROB_REQUEST, REQUEST_TYPES.FLEET_REQUEST].includes(
            request.request_type,
          ) &&
            request.replacement?.type === REPLACEMENT_TYPE.car && (
              <Car
                navigation={navigation}
                service_request={request}
                replacement={replacement}
                rob={rob}
                fleet_request={fleetRequest}
                onRefresh={refresh}
              />
            )}
          <ReplacementActionsheet
            visible={
              request.request_type === REQUEST_TYPES.SERVICE_REQUEST ||
              request.replacement?.type !== REPLACEMENT_TYPE.car ||
              showEditType
            }
            request_id={request_id}
            license={request.license}
            fleet_contract_id={request.fleet_contract_id}
            parent_request_type={request.request_type}
            type={request.replacement?.type}
            onDismiss={() => {
              if (
                request.request_type !== REQUEST_TYPES.SERVICE_REQUEST &&
                request.replacement?.type === REPLACEMENT_TYPE.car
              ) {
                setShowEditType(false);
                refresh();
              } else {
                navigateBack();
              }
            }}
            onRefresh={() => {
              setShowEditType(false);
              refresh();
            }}
            onTypeChanged={(type) => {
              if (
                request.request_type !== REQUEST_TYPES.SERVICE_REQUEST &&
                type === REPLACEMENT_TYPE.car
              ) {
                setShowEditType(false);
                refresh();
              } else {
                navigateBack();
              }
            }}
          />
          <RobDenialsDialog
            visible={showDenialReasonsDialog}
            request_id={request_id}
            replacement={replacement}
            rob={rob}
            fleet_request={fleetRequest}
            onRefresh={refresh}
            onDismiss={() => setShowDenialReasonsDialog(false)}
          />
        </>
      )}
    </View>
  );
};

export default withProtectedScreen(Index);
