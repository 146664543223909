import React, {useState} from 'react';
import {Menu as PaperMenu} from 'react-native-paper';

import {Menu} from '~/components/controls';
import MoreVertIcon from '~/images/md-icons/more_vert/materialicons/24px.svg';

const MoreMenu = ({icon, items}) => {
  const [visible, setVisible] = useState(false);
  const has_leading_icon = items.some((item) => item.leadingIcon);

  return (
    <Menu
      visible={visible}
      anchor={icon ?? <MoreVertIcon />}
      onShow={() => setVisible(true)}
      onDismiss={() => setVisible(false)}>
      {items.map(({title, leadingIcon, disabled, onPress}, index) => (
        <PaperMenu.Item
          key={index}
          title={title}
          leadingIcon={leadingIcon}
          disabled={disabled}
          titleStyle={
            !leadingIcon && has_leading_icon ? {paddingLeft: 56} : null
          }
          onPress={async () => {
            if (onPress) {
              await onPress();
            }

            setVisible(false);
          }}
        />
      ))}
    </Menu>
  );
};

export default MoreMenu;
