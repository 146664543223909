import React, {useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import sy from '~/styles';
import {Text, Dialog, FormTextInput} from '~/components/controls';

const RobNetCredentialsDialog = ({visible, error, onDismiss, onOK}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Dialog
      visible={visible}
      title="Inloggen ROB-Net"
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'Opslaan',
          disabled: !username && !password,
          textStyle: {color: '#972727'},
          onPress: () => onOK(username, password),
        },
      ]}
      options={{
        hideDividers: true,
        noPaddingContent: true,
      }}
      onDismiss={onDismiss}>
      <View style={[sy['px-6'], sy['pb-4'], sy['gap-6']]}>
        <Text>Voor het sturen van foto's is inloggen bij ROB-Net vereist.</Text>
        <View style={[sy['gap-4']]}>
          <div data-private>
            <FormTextInput
              label="E-mailadres"
              value={username}
              onChangeText={setUsername}
            />
          </div>
          <div data-private>
            <FormTextInput
              label="Wachtwoord"
              value={password}
              onChangeText={setPassword}
            />
          </div>
          {error && <Text style={[sy['text-error']]}>{error}</Text>}
        </View>
      </View>
    </Dialog>
  );
};

export default RobNetCredentialsDialog;
