import React, {useState, useEffect} from 'react';
import {ImageBackground, View} from 'react-native';

import {apiRoot as publicApiRoot} from '~/api/public/endpoints';
import {dealer as dealerApi} from '~/api/private';

export const DealerPersonaSize = {
  size32: 32,
  size36: 36,
  size40: 40,
  size48: 48,
  size56: 56,
};

const DealerPersona = ({dealer_id, size = DealerPersonaSize.size32}) => {
  const height = size;
  const width = size;

  const [image, setImage] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const retrieve = async () => {
      if (dealer_id) {
        const {result: company} = (await dealerApi.company(dealer_id)) ?? {};
        if (company) {
          const image_url = `https://bahnkick.blob.core.windows.net/company-logos/${company.name}.svg`;
          setImage(image_url);
          setCompany(company);
          setLoading(true);
        } else {
          setCompany(null);
          setImage(null);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    retrieve();
  }, [dealer_id]);

  return (
    <View
      style={[
        {
          borderRadius: 50,
          overflow: 'hidden',
          width,
          height,
          visiblity: loading ? 'hidden' : 'visible',
        },
      ]}>
      {image ? (
        <img
          src={image}
          style={{width, height}}
          alt={company?.name}
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setImage(null);
            setLoading(false);
          }}
        />
      ) : loading ? (
        <></>
      ) : (
        <ImageBackground
          style={{width, height}}
          source={{
            uri: `${publicApiRoot}/dealer/photo/${dealer_id}`,
          }}
        />
      )}
    </View>
  );
};

export default DealerPersona;
