import React, {useState, useEffect} from 'react';

import {Pressable} from '~/components/controls';

const IMG_DEFAULT_WIDTH = 800;

const Thumbnail = ({data}) => {
  const [size, setSize] = useState({
    width: IMG_DEFAULT_WIDTH,
    height: IMG_DEFAULT_WIDTH,
  });

  useEffect(() => {
    if (data) {
      const img = new Image();
      img.src = data;
      img.onload = () => {
        const ratio = img.height / img.width;
        setSize({width: IMG_DEFAULT_WIDTH, height: IMG_DEFAULT_WIDTH * ratio});
      };
    }
  }, [data]);

  return (
    <Pressable
      onPress={() => {
        const w = window.open(
          null,
          'bahnkick_image',
          `popup,width=${size.width},height=${size.height}`,
        );
        w.document.write(
          `<html onclick="window.close()" style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
            <body style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
              <img src="${data}" style="display: block; margin: 0 auto;" height="100%" />
            </body>
          </html>`,
        );
        w.document.close();
      }}>
      <img
        draggable="false"
        style={{
          maxWidth: '50%',
          borderRadius: 10,
        }}
        src={data}
      />
    </Pressable>
  );
};

export default Thumbnail;
