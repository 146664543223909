import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import styles from './styles';
import sy from '~/styles';
import {Text} from '~/components/controls';

export const PersonaSize = {
  size32: 32,
  size36: 36,
  size40: 40,
  size44: 44,
  size48: 48,
  size56: 56,
};

const Persona = ({
  imageInitials,
  discStyle = null,
  size = PersonaSize.size40,
  selected = false,
  is_dealer = false,
  lessor = null,
}) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const retrieve = async () => {
      if (!is_dealer && lessor) {
        const image_url = `https://bahnkick.blob.core.windows.net/company-logos/${lessor}.svg`;
        setImage(image_url);
        setLoading(true);
      } else {
        setLoading(false);
      }
    };

    retrieve();
  }, [is_dealer, lessor]);

  const height = size;
  const width = size;

  return (
    <View style={styles.root}>
      <View
        style={[
          styles.disc,
          {width, height},
          selected ? styles.selected : null,
          is_dealer ? styles.dealer : null,
          discStyle,
          {
            visibility: loading ? 'hidden' : 'visible',
          },
        ]}>
        {image ? (
          <img
            src={image}
            style={{width, height}}
            alt={lessor}
            onLoad={() => {
              setLoading(false);
            }}
            onError={() => {
              setImage(null);
              setLoading(false);
            }}
          />
        ) : (
          <Text
            style={[
              styles.text,
              sy.uppercase,
              {
                fontSize: Math.round(size / 2.5),
                lineHeight: Math.round(size / 2.5),
              },
            ]}>
            {imageInitials}
          </Text>
        )}
      </View>
    </View>
  );
};

export default Persona;
