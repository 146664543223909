import React, {useEffect, useState} from 'react';
import {View, TextInput} from 'react-native';
import moment from 'moment';

import sy from '~/styles';
import {
  user as userApi,
  service_request as serviceRequestApi,
} from '~/api/private';

import {
  Text,
  Persona,
  Pressable,
  Menu,
  Dialog,
  Thumbnail,
  DealerPersona,
} from '~/components/controls';
import {PersonaSize} from '~/components/controls/Persona';

import MoreVertIcon from '~/images/md-icons/more_vert/materialicons/24px.svg';
import EditIcon from '~/images/md-icons/edit/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import DoneIcon from '~/images/md-icons/done/materialicons/24px.svg';
import DoneAllIcon from '~/images/md-icons/done_all/materialicons/24px.svg';

const LINE_HEIGHT = 24;

const EditDialog = ({visible, content, onDismiss, onEdit}) => {
  const [value, setValue] = useState('');
  const [lines, setLines] = useState(1);

  useEffect(() => {
    setValue(content ?? '');
  }, [content]);

  return (
    <Dialog
      visible={visible}
      title="Bericht bewerken"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'Gereed',
          onPress: () => {
            onEdit(value);
            onDismiss();
          },
        },
      ]}>
      <View style={[sy['py-4']]}>
        <View
          style={[
            sy['flex-row'],
            sy['flex-1'],
            sy['p-2'],
            {
              borderRadius: 10,
              backgroundColor: '#EBEBEB',
            },
          ]}>
          <TextInput
            style={[
              sy.regular,
              sy['flex-1'],
              {
                color: value.length > 0 ? '#4A4A49' : '#828282',
                height: lines * LINE_HEIGHT,
              },
            ]}
            multiline
            placeholder={'Typ om het bericht te wijzigen'}
            value={value}
            onChangeText={setValue}
            onContentSizeChange={({nativeEvent}) => {
              const {height} = nativeEvent.contentSize;
              setLines(Math.ceil(height / LINE_HEIGHT));
            }}
          />
        </View>
      </View>
    </Dialog>
  );
};

const Message = (props) => {
  const {
    style,
    content,
    attachments,
    timestamp: message_timestamp,
    message_id,
    request_id,
    user_id,
    dealer_id,
    dealer_name,
    fleet_owner_name,
    lessor,
    is_sent,
    onEdit,
    onDelete,
  } = props;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [is_read, setIsRead] = useState(props.is_read);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [showEditMessageDialog, setShowEditMessageDialog] = useState(null);
  const [showDeleteMessageDialog, setShowDeleteMessageDialog] = useState(null);

  useEffect(() => {
    const touch = async () => {
      await serviceRequestApi.message_read(request_id, message_id);
      setIsRead(true);
    };

    if (message_id) {
      touch();
    }
  }, [message_id, request_id]);

  useEffect(() => {
    const fetch = async () => {
      const {first_name, last_name} = (await userApi.find(user_id)) ?? {};

      if (first_name && last_name) {
        let initials = first_name.substring(0, 2);
        if (last_name.length > 0) {
          initials = `${first_name[0]}${last_name[0]}`;
        }

        setUser({initials, name: `${first_name} ${last_name}`.trim()});
      }

      setLoading(false);
    };

    if (user_id) {
      fetch();
    } else {
      setLoading(false);
    }
  }, [user_id]);

  let duration;
  if (message_timestamp) {
    const timestamp = moment(message_timestamp);
    if (timestamp.isSame(moment(), 'day')) {
      duration = timestamp.format('H:mm');
    } else {
      duration = timestamp.format('D MMMM YYYY');
    }
  }

  return (
    <>
      <View style={[sy['flex-row'], sy['overflow-hidden'], {gap: 22}, style]}>
        {dealer_name ? (
          <DealerPersona dealer_id={dealer_id} size={PersonaSize.size36} />
        ) : (
          <Persona
            is_dealer={false}
            style={[sy['self-center']]}
            imageInitials={(fleet_owner_name ?? lessor).substring(0, 2)}
            lessor={fleet_owner_name ?? lessor}
            size={PersonaSize.size36}
          />
        )}
        <View style={[sy['flex-1']]}>
          <View
            style={[
              sy['flex-row'],
              sy['items-center'],
              sy['justify-between'],
              sy['pb-1'],
            ]}>
            <div data-private>
              <Text style={[sy.mediumBold, {lineHeight: 16}]}>
                {user.name ??
                  (loading ? (
                    <>&nbsp;</>
                  ) : (
                    dealer_name ?? fleet_owner_name ?? lessor
                  ))}
              </Text>
            </div>
            <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
              <Text style={[sy.smallRegular, {lineHeight: 14}]}>
                {duration}
              </Text>
              {onEdit || onDelete ? (
                <Menu
                  visible={isMenuVisible}
                  anchor={
                    <MoreVertIcon height={18} width={18} fill="#4a4a49" />
                  }
                  style={{marginTop: 24}}
                  contentStyle={{backgroundColor: '#6D6D6D'}}
                  onShow={() => setIsMenuVisible(true)}>
                  <View style={[sy['px-2'], sy['flex-row'], sy['gap-4']]}>
                    {onEdit &&
                      !attachments?.length /* messages with attachments can't be edited */ && (
                        <Pressable
                          onPress={() => {
                            setShowEditMessageDialog(true);
                            setIsMenuVisible(false);
                          }}>
                          <EditIcon height={18} width={18} fill="#ffffff" />
                        </Pressable>
                      )}
                    {onDelete && (
                      <Pressable
                        onPress={() => {
                          setShowDeleteMessageDialog(true);
                          setIsMenuVisible(false);
                        }}>
                        <DeleteIcon height={18} width={18} fill="#ffffff" />
                      </Pressable>
                    )}
                  </View>
                </Menu>
              ) : is_read && is_sent ? (
                <DoneAllIcon width={14} height={14} fill="#231fda" />
              ) : is_sent ? (
                <DoneIcon width={14} height={14} fill="#231fda" />
              ) : null}
            </View>
          </View>
          <Text style={sy.smallRegular}>{content}</Text>
          {attachments?.length > 0 && (
            <>
              {attachments?.map((item, index) => {
                if (item.is_image) {
                  return (
                    <React.Fragment key={index}>
                      <Thumbnail data={item.data} />
                    </React.Fragment>
                  );
                }

                return null;
              })}
            </>
          )}
        </View>
      </View>
      <EditDialog
        visible={showEditMessageDialog}
        content={content}
        onDismiss={() => setShowEditMessageDialog(false)}
        onEdit={(value) => onEdit(message_id, value)}
      />
      <Dialog
        visible={showDeleteMessageDialog}
        title="Bericht verwijderen?"
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowDeleteMessageDialog(false),
          },
          {
            text: 'Verwijderen',
            onPress: () => {
              onDelete(message_id);
              setShowDeleteMessageDialog(false);
            },
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>Verwijderen kan niet ongedaan worden gemaakt.</Text>
        </View>
      </Dialog>
    </>
  );
};

export default Message;
