import React, {useState, useEffect, useLayoutEffect, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider, Badge} from 'react-native-paper';
import {useIsFocused} from '@react-navigation/native';
import {HeaderBackButton} from '@react-navigation/elements';
import moment from 'moment';
import _ from 'lodash';
import LogRocket from 'logrocket';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import withServiceRequestShortcuts from '~/components/hoc/with-service-request-shortcuts';
import withDimensions from '~/components/hoc/with-dimensions';
import {
  Text,
  PrivateText,
  Pressable,
  PrimaryButton,
  ServiceRequestStatusIcon,
  Dialog,
  FormTextInput,
  FormNumberInput,
  Checkbox,
  Grouping,
  Calendar,
  TimeSlots,
  PhoneNumberInput,
  EmailInput,
  ReplacementIcon,
  ReplacementText,
  RobTaskIcon,
  Link,
  RadioButton,
  Modal,
  InputChat,
  KpiWarning,
  ArticleText,
  ExternalLink,
  SlaArticleText,
} from '~/components/controls';
import {
  Row as GroupingRow,
  EditDialog as GroupingRowEdit,
} from '~/components/controls/Grouping';
import {LessorMessage} from '~/components/shared';
import {
  MaintenanceActionsheet,
  TaskActionsheet,
  SlaActionsheet,
  TiresActionsheet,
  DealerUserActionsheet,
} from '~/components/shared/Actionsheets';
import {
  DealerRequestDialog,
  InvoiceUploadDialog,
  RobNetCredentialsDialog,
} from '~/components/shared/Dialogs';
import Format from '~/lib/format';
import {
  service_request as serviceRequestApi,
  rob as robApi,
  car as carApi,
  user as userApi,
  fleet as fleetApi,
  dealer as dealerApi,
} from '~/api/private';
import {STATUS_DESCRIPTIONS, STATUS_CODES} from '~/types/statuses';
import {
  isRobTaskReady,
  isRobRequestReady,
  isLessorStatus,
  canInvoice,
  isRobTaskDenied,
  isRobTaskApproved,
  isRobTaskCancelled,
  isRobTaskChanged,
  isNotInvoiced,
  isArchived,
  isNotReadyForInvoicing,
  ROB_TASK_STATUS,
  ROB_TASK_STATUS_LABELS,
  ROB_NOT_INVOICED_STATUS_LABELS,
  ROB_COST_TEXTS,
  ROB_READY_CODES,
  ROB_ERROR_TRANSLATIONS,
  ROB_TIRE_CODES,
  TIRE_REPAIR,
} from '~/types/rob';
import {SERVICE_TYPE_COMPONENTS_ORDER} from '~/types/services';
import {
  REPLACEMENT_TYPE,
  ROB_REPLACEMENT_CODE,
  REPLACEMENT_TITLE,
  ROB_REPLACEMENT_STAGE,
  ROB_REPLACEMENT_STAGE_ORDER,
  ROB_REPLACEMENT_STAGE_TITLE,
} from '~/types/replacement';
import {REQUEST_TYPES, IsReplacementVehicle} from '~/types/request';
import {useDispatch, useSelector} from '~/lib/hooks';
import {
  setAppDoneLoading,
  setAppIsLoading,
  setUserSettings,
  showClientMessagesDialog,
} from '~/actions';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import ThumbUpIcon from '~/images/md-icons/thumb_up/materialicons/24px.svg';
import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import ListIcon from '~/images/md-icons/list/materialicons/24px.svg';
import ReportIcon from '~/images/md-icons/report/materialicons/24px.svg';
import BlockIcon from '~/images/md-icons/block/materialicons/24px.svg';
import AssignmentTurnedInIcon from '~/images/md-icons/assignment_turned_in/materialicons/24px.svg';
import ReceiptIcon from '~/images/md-icons/receipt/materialicons/24px.svg';
import ErrorIcon from '~/images/md-icons/error/materialicons/24px.svg';
import EventIcon from '~/images/md-icons/event/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import BusinessIcon from '~/images/md-icons/business/materialicons/24px.svg';
import AssignmentIcon from '~/images/md-icons/assignment/materialicons/24px.svg';
import ChatIcon from '~/images/md-icons/chat/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import RefreshIcon from '~/images/md-icons/refresh/materialicons/24px.svg';
import WarningIcon from '~/images/md-icons/warning/materialicons/18px.svg';
import WarningLargeIcon from '~/images/md-icons/warning/materialicons/24px.svg';
import ReceiptLongIcon from '~/images/md-icons/receipt_long/materialicons/24px.svg';
import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import EuroSymbolIcon from '~/images/md-icons/euro_symbol/materialicons/24px.svg';
import ShoppingBasketIcon from '~/images/md-icons/shopping_basket/materialicons/24px.svg';
import HistoryIcon from '~/images/md-icons/history/materialicons/24px.svg';
import PublishedWithChangesIcon from '~/images/md-icons/published_with_changes/materialicons/24px.svg';
import LocalOfferIcon from '~/images/md-icons/local_offer/materialicons/24px.svg';
import OpenInNewIcon from '~/images/md-icons/open_in_new/materialicons/24px.svg';
import FileOpenIcon from '~/images/md-icons/file_open/materialicons/24px.svg';
import PersonIcon from '~/images/md-icons/person/materialicons/24px.svg';
import MoreTimeIcon from '~/images/md-icons/more_time/materialicons/24px.svg';

const INVOICE_DISCOUNT_LABELS = {
  DEALER_FEE: 'Transactiekosten',
  DEALER_SMS_COST: 'SMS kosten',
  DEALER_TECRMI_COST: 'Gebruikskosten TecRMI',
  DEALER_SERVICE_PERCENTAGE: 'Administratiekosten',
  DEALER_EXPENSE_PERCENTAGE: 'Bijdrage coulance-/garantiefonds',
  SLA_APPROVED_INVOICE_DISCOUNT: 'Werkzaamheden',
  SLA_APPROVED_INVOICE_NEW_TIRES_DISCOUNT: 'Banden',
};

const INVOICE_DISCOUNT_ORDER = [
  'SLA_INVOICE_DISCOUNT',
  'SLA_INVOICE_NEW_TIRES_DISCOUNT',
  'DEALER_SERVICE_PERCENTAGE',
  'DEALER_EXPENSE_PERCENTAGE',
  'DEALER_FEE',
  'DEALER_TECRMI_COST',
  'DEALER_SMS_COST',
  'SLA_APPROVED_INVOICE_DISCOUNT',
  'SLA_APPROVED_INVOICE_NEW_TIRES_DISCOUNT',
];

const INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX = 64;
const INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX = 51;

const PdfViewerLink = ({invoice, request_id}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      let pdf;
      if (invoice?.bahnkick_can_invoice) {
        if (invoice?.fleet_request) {
          pdf = await fleetApi.invoice_pdf(request_id);
        } else {
          pdf = await robApi.invoice_pdf(request_id);
        }
      } else {
        pdf = await serviceRequestApi.invoice_pdf(request_id);
      }

      setData(pdf);
    };

    fetch();
  }, [invoice, request_id]);

  return (
    <Link
      style={sy.underline}
      onPress={() => {
        const obj = URL.createObjectURL(data);
        const iframe = `<div onclick="window.close();" style='position: absolute;background-color: #fff; padding: 4px;'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div><iframe width='100%' height='100%' src="${obj}"></iframe>`;
        const x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }}>
      Bekijk factuur (PDF)
    </Link>
  );
};

const LessorPhone = ({lessor, style}) => {
  let phone = lessor?.phone?.maintenance || lessor?.phone?.general;
  if (lessor?.sublessor?.phone) {
    phone =
      lessor.sublessor.phone.maintenance || lessor.sublessor.phone.general;
  }

  return <Text style={style}>{phone}</Text>;
};

const ServiceHistory = ({request_id, license, history_service_request_id}) => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);
  const [rob_components, setRobComponents] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const {result: history} = await serviceRequestApi.fetch_history(
        request_id,
        history_service_request_id,
      );

      setHistory(history);

      const rob_codes = history.map((item) => item.rob_code);
      const {result: rob_components} = await robApi.all_components(
        request_id,
        rob_codes,
      );
      setRobComponents(rob_components);

      setLoading(false);
    };

    fetch();
  }, [request_id, history_service_request_id, license]);

  return loading ? (
    <ActivityIndicator color="#231fda" />
  ) : (
    <>
      {history
        ?.filter((item) => isRobTaskApproved(item.status))
        .sort((a, b) => {
          if (!a.rob_code && b.rob_code) {
            return 1;
          }

          if (!b.rob_code && a.rob_code) {
            return -1;
          }

          if (a.rob_code && b.rob_code) {
            return a.rob_code.localeCompare(b.rob_code);
          }

          return a.description.localeCompare(b.description);
        })
        .map((item, index, arr) => (
          <React.Fragment key={index}>
            <View style={[sy['p-4'], sy['flex-row'], sy['gap-5']]}>
              <Text>{item.rob_code}</Text>
              <View style={[sy['flex-1']]}>
                <Text>{item.description}</Text>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  {rob_components[item.rob_code]?.operations.find(
                    (x) => x.operation.code === item.operation,
                  )?.operation?.description ?? item.operation}
                </Text>
              </View>
            </View>
            {index < arr.length - 1 && <Divider style={sy['bg-gray95']} />}
          </React.Fragment>
        ))}
    </>
  );
};

const ServiceHistoryLink = ({request_id, license, history}) => {
  const [showModal, setShowModal] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const onDismiss = () => {
    setShowModal(false);
  };

  if (!history?.length) {
    return <Text>Geen gegevens</Text>;
  }

  return (
    <>
      <Link onPress={() => setShowModal(true)}>
        {moment(history[0].date).format('D-M-YYYY')}&nbsp;(
        {history[0].rob_code ??
          `${Format.number(
            moment().diff(moment(history[0].date), 'months') / 12,
            1,
            true,
            {
              minimumFractionDigits: 0,
            },
          )} jaar`}
        )
      </Link>
      <Modal visible={showModal} onDismiss={onDismiss}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['items-center'],
            sy['gap-8'],
          ]}>
          <Pressable style={[sy['p-4']]} onPress={onDismiss}>
            <ChevronLeftIcon fill="#4a4a49" />
          </Pressable>
          <View
            style={[sy['flex-1'], sy['items-center'], sy['justify-center']]}>
            <Text style={sy.smallPlus}>Historie</Text>
          </View>
          <Pressable
            style={[sy['p-4']]}
            onPress={() => setShowInfoDialog(true)}>
            <HistoryIcon fill="#4a4a49" />
          </Pressable>
        </View>
        <ScrollView style={[sy['bg-gray95']]}>
          {history.map((item, index) => (
            <Grouping
              header={
                <>
                  {moment(item.date).format('D-M-YYYY')}&nbsp;&middot;&nbsp;
                  {item.rob_code ??
                    `${Format.number(item.kilometrage, 0, true)} km`}
                </>
              }
              key={index}
              initial={{expand: index === 0}}>
              {item.rob_code ? (
                <View style={sy['p-4']}>
                  <Text>Laatst bekende onderhoudsbeurt in ROB-Net</Text>
                </View>
              ) : (
                <ServiceHistory
                  request_id={request_id}
                  license={license}
                  history_service_request_id={item.service_request_id}
                />
              )}
            </Grouping>
          ))}
        </ScrollView>
      </Modal>
      <Dialog
        title={
          <View
            style={[sy['flex-row'], sy['items-center'], sy['justify-between']]}>
            <Text style={sy.largePlus}>Historie</Text>
          </View>
        }
        titleIcon={<HistoryIcon fill="#4a4a49" />}
        visible={showInfoDialog}
        onDismiss={() => setShowInfoDialog(false)}>
        <View style={[sy['py-4']]}>
          <Text>
            Hier wordt de datum en code van de laatst bekende onderhoudsbeurt in
            ROB-Net getoond en alle gefactureerde werkopdrachten voor dit
            kenteken die bij Bahnkick bekend zijn.
          </Text>
        </View>
      </Dialog>
    </>
  );
};

const ResetDialog = ({visible, state, onReset, onDismiss}) => {
  const [resetRequest, setResetRequest] = useState(false);

  useEffect(() => {
    setResetRequest(false);
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      title={
        <View style={[sy['flex-row'], sy['gap-4'], sy['items-center']]}>
          <ServiceRequestStatusIcon status={state?.from} />
          <Text style={[sy.large, {fontSize: 20, lineHeight: 30}]}>
            {STATUS_DESCRIPTIONS[state?.from]}
          </Text>
        </View>
      }
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'OK',
          textStyle: {color: '#972727'},
          disabled: !resetRequest,
          onPress: async () => {
            if (resetRequest) {
              onReset(state.to);
            }

            onDismiss();
          },
        },
      ]}
      onDismiss={onDismiss}>
      <Pressable
        style={[sy['py-4'], sy['gap-4'], sy['flex-row'], sy['items-center']]}
        onPress={() => setResetRequest(!resetRequest)}>
        <Checkbox checked={resetRequest} />
        <Text>
          Terug naar status&nbsp;
          {state?.to === STATUS_CODES.CheckIn
            ? 'Goedkeuring'
            : STATUS_DESCRIPTIONS[state?.to]}
        </Text>
      </Pressable>
    </Dialog>
  );
};

const CancelRequestDialog = ({visible, status, onCancel, onDismiss}) => {
  const [cancelRequest, setCancelRequest] = useState(false);

  return (
    <Dialog
      visible={visible}
      title={
        <View style={[sy['flex-row'], sy['gap-4'], sy['items-center']]}>
          <ServiceRequestStatusIcon status={status} />
          <Text style={[sy.large, {fontSize: 20, lineHeight: 30}]}>
            {STATUS_DESCRIPTIONS[status]}
          </Text>
        </View>
      }
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'OK',
          textStyle: {color: '#972727'},
          onPress: async () => {
            if (cancelRequest) {
              onCancel();
            }

            onDismiss();
          },
        },
      ]}
      onDismiss={onDismiss}>
      <Pressable
        style={[sy['py-4'], sy['gap-4'], sy['flex-row'], sy['items-center']]}
        onPress={() => setCancelRequest(!cancelRequest)}>
        <Checkbox checked={cancelRequest} />
        <Text>Aanvraag annuleren</Text>
      </Pressable>
    </Dialog>
  );
};

const CancelRobTaskDialog = ({visible, onCancel, onDismiss}) => {
  const dispatch = useDispatch();
  const user_settings = useSelector((state) => state.user_settings);
  const neverShowAgain = user_settings?.never_show_rob_task_cancel_dialog;

  return (
    <Dialog
      visible={visible}
      title="Item laten vervallen?"
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'Vervallen',
          textStyle: {color: '#972727'},
          onPress: async () => {
            if (neverShowAgain) {
              await userApi.update_settings({
                ...user_settings,
                never_show_rob_task_cancel_dialog: neverShowAgain,
              });
            }

            onCancel();
          },
        },
      ]}
      onDismiss={onDismiss}>
      <Pressable
        style={[sy['py-4'], sy['gap-4'], sy['flex-row'], sy['items-center']]}
        onPress={() =>
          dispatch(
            setUserSettings({
              never_show_rob_task_cancel_dialog: !neverShowAgain,
            }),
          )
        }>
        <Checkbox checked={neverShowAgain} />
        <Text>Niet meer tonen</Text>
      </Pressable>
    </Dialog>
  );
};

const MaintenanceExtrasDialog = ({visible, onDismiss, onConfirm}) => {
  const dispatch = useDispatch();
  const user_settings = useSelector((state) => state.user_settings);
  const neverShowAgain = user_settings?.never_show_maintenance_extras_dialog;

  return (
    <Dialog
      title="Extra onderhoud bekijken?"
      onDismiss={onDismiss}
      visible={visible}
      buttons={[
        {
          text: 'Nu niet',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          onPress: async () => {
            if (neverShowAgain) {
              await userApi.update_settings({
                ...user_settings,
                never_show_maintenance_extras_dialog: neverShowAgain,
              });
            }

            onConfirm();
          },
        },
      ]}>
      <View style={[sy['py-4']]}>
        <Text>
          Bekijk de extra services die voor het onderhoud beschikbaar zijn en
          voeg de gewenste items toe.
        </Text>
      </View>
      <Divider />
      <Pressable
        style={[sy['py-4'], sy['gap-4'], sy['flex-row'], sy['items-center']]}
        onPress={() =>
          dispatch(
            setUserSettings({
              never_show_maintenance_extras_dialog: !neverShowAgain,
            }),
          )
        }>
        <Checkbox checked={neverShowAgain} />
        <Text>Niet meer tonen</Text>
      </Pressable>
    </Dialog>
  );
};

const ReplacementVehicleRow = ({
  request_id,
  request_type,
  read_only,
  onPress,
}) => {
  const [replacement_vehicle, setReplacementVehicle] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      let replacement_vehicle;

      if (request_type === REQUEST_TYPES.FLEET_REQUEST) {
        ({result: replacement_vehicle} = await fleetApi.replacement_vehicle(
          request_id,
        ));
      } else {
        ({result: replacement_vehicle} = await robApi.replacement_vehicle(
          request_id,
        ));
      }
      setReplacementVehicle(replacement_vehicle);
    };

    fetch();
  }, [request_id, request_type]);

  let icon = <DirectionsCarIcon fill="#4A4A49" width={24} height={24} />;
  if (replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob) {
    icon = (
      <ServiceRequestStatusIcon
        status={replacement_vehicle.replacement.status}
      />
    );
  }

  let subtitle = '';
  if (
    replacement_vehicle?.replacement?.status === STATUS_CODES.Done ||
    replacement_vehicle?.replacement?.status === STATUS_CODES.DoneNoInvoice ||
    replacement_vehicle?.replacement?.status === STATUS_CODES.Invoiced ||
    replacement_vehicle?.replacement?.status === STATUS_CODES.Archived
  ) {
    subtitle = STATUS_DESCRIPTIONS[replacement_vehicle.replacement.status];
  } else if (
    (replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob)
      ?.task_status === ROB_TASK_STATUS.CancellationApproved
  ) {
    subtitle = ROB_TASK_STATUS_LABELS[ROB_TASK_STATUS.CancellationApproved];
  } else if (
    (replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob)?.stages
  ) {
    const rob_stage = _.first(
      Object.keys(
        (replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob).stages,
      ).sort(
        (a, b) =>
          ROB_REPLACEMENT_STAGE_ORDER.indexOf(b) -
          ROB_REPLACEMENT_STAGE_ORDER.indexOf(a),
      ),
    );

    if (
      isLessorStatus(
        replacement_vehicle?.fleet_request?.status ??
          replacement_vehicle?.rob?.rob_status,
      )
    ) {
      subtitle = `Voorstel ${ROB_REPLACEMENT_STAGE_TITLE[
        rob_stage
      ].toLowerCase()}`;
    } else {
      const stage_status = (
        replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob
      ).stages[rob_stage].status;
      subtitle = `${ROB_REPLACEMENT_STAGE_TITLE[rob_stage]} ${
        isRobTaskDenied(stage_status)
          ? 'niet akkoord'
          : isRobTaskApproved(stage_status)
          ? `${
              rob_stage === ROB_REPLACEMENT_STAGE.checkin ? '' : 'voorlopig '
            }akkoord`
          : ''
      }`.trim();
    }
  }

  return (
    <>
      <Pressable style={[sy['flex-row']]} onPress={onPress}>
        <View style={[sy['p-4'], sy['pr-8']]}>{icon}</View>
        <View
          style={[
            !read_only && sy['border-b'],
            sy['border-lightgray'],
            sy['flex-row'],
            sy['flex-1'],
            sy['justify-between'],
            sy['py-4'],
            sy['pr-4'],
          ]}>
          {replacement_vehicle?.fleet_request ?? replacement_vehicle?.rob ? (
            <View>
              {replacement_vehicle?.fleet_request ? (
                <Text>Huurauto</Text>
              ) : (
                <Text>
                  {ROB_REPLACEMENT_CODE}&nbsp;&middot;&nbsp;{REPLACEMENT_TITLE}
                </Text>
              )}
              <Text style={[sy.small, sy['text-lightgray'], sy.underline]}>
                {subtitle}
              </Text>
            </View>
          ) : (
            <>
              <View>
                <Text>Huurauto</Text>
                <Text style={[sy.small, sy['text-lightgray'], sy.underline]}>
                  Keuze maken
                </Text>
              </View>
              <ListIcon fill="#4A4A49" width={24} height={24} />
            </>
          )}
        </View>
      </Pressable>
      {read_only && <Divider />}
    </>
  );
};

const TaskSubtitle = ({
  lessor,
  tasks,
  is_maintenance_item,
  is_tires_item,
  is_sla_item,
  ready,
  enabled,
  cancelled,
  cancellation_approved,
  cancellation_denied,
  rob_components,
  is_fleet_car,
  is_retail_car,
}) => {
  let content = <>&nbsp;</>;
  if (ready) {
    if (is_tires_item) {
      const tire_actions = tasks
        .flatMap((task) => task.parts ?? [])
        .flatMap((part) => {
          switch (part.action) {
            case 'Current_TireRepair':
              return part.repairs.map((item) =>
                item === TIRE_REPAIR.Wielomwisselen ? 'Omwisselen' : item,
              );
            case 'Current_Replace':
              return ['Bandvervanging'];
            case 'Current_Store':
            case 'Current_Remove':
              return ['Wissel zonder velg'];
            case 'Current_StoreWheel':
              return ['Wissel met velg'];

            default:
              break;
          }

          return [];
        });

      content = (
        <>
          SLA&nbsp;&middot;&nbsp;{[...new Set(tire_actions ?? [])].join(', ')}
        </>
      );
    } else if (is_sla_item) {
      content = <>SLA&nbsp;&middot;&nbsp;{lessor}</>;

      const is_bk_task = tasks.some((item) =>
        ['SLA_TASK_DURATION', 'SLA_TASK_AMOUNT'].includes(item.type),
      );

      if (is_bk_task) {
        const main_task = tasks[0];
        content =
          main_task.type === 'SLA_TASK_DURATION' &&
          main_task.duration !== main_task.original_work_time ? (
            <>Invoer</>
          ) : (
            <>SLA</>
          );
      }
    } else {
      let type;
      let operations;

      if (tasks.every((item) => !item.reference)) {
        type = 'Invoer';

        operations = [
          ...new Set([
            ...tasks
              .map(
                (task) =>
                  rob_components[task.rob_code]?.operations.find(
                    (item) => item.operation.code === task.operation,
                  )?.operation.description,
              )
              .filter(Boolean),
          ]),
        ];
      } else {
        const main_task = tasks.find((item) => item.is_main_task);
        type = main_task?.source ?? 'TecRMI';

        if (is_maintenance_item || (!is_fleet_car && !is_retail_car)) {
          operations = tasks
            .sort((a, b) => {
              if (a.type && !b.type) {
                return -1;
              }

              if (!a.type && b.type) {
                return 1;
              }

              if (a.duration === b.duration) {
                return a.work_text.localeCompare(b.work_text);
              }

              return b.duration - a.duration;
            })
            .map((task) => task.work_text ?? 'Invoer');
        }
      }

      content = (
        <>
          {type}
          {operations?.length > 0 ? (
            <>&nbsp;&middot;&nbsp;{operations.join(', ')}</>
          ) : (
            <></>
          )}
        </>
      );
    }
  } else {
    content = <>Keuze maken</>;
  }

  return (
    <Text
      style={[
        sy.truncate,
        sy.small,
        sy['text-lightgray'],
        enabled && sy['underline'],
        (cancelled || cancellation_approved || cancellation_denied) &&
          sy['line-through'],
      ]}>
      {content}
    </Text>
  );
};

const Footer = ({
  request_id,
  is_fleet_car,
  is_retail_car,
  status,
  overview,
  invoice,
  amount,
  robErrors,
  onDoRequest,
  onRefresh,
  onInvoice,
  onShowRobRequestErrors,
}) => {
  const dispatch = useDispatch();

  const changeStatus = async (change) => {
    const {success} = await serviceRequestApi.change_status(request_id, change);
    if (success) {
      onRefresh();
    }
  };

  let open_tasks =
    !is_fleet_car &&
    overview.tasks
      ?.filter((task) => task.is_main_task)
      .some(
        (task) =>
          !(overview.dealer_request ?? overview.rob)?.components[task.id],
      );

  if (
    !open_tasks &&
    overview.replacement?.type === REPLACEMENT_TYPE.transport &&
    overview.replacement?.sla?.rob_code &&
    overview.rob
  ) {
    open_tasks = Object.values(overview.rob.components).every(
      (item) => item.rob.code !== overview.replacement.sla.rob_code,
    );
  }

  let submitButton = <></>;
  if (
    isArchived(overview?.rob?.status) ||
    overview?.status === STATUS_CODES.Cancelled
  ) {
    // Left intentionally empty
  } else {
    if (canInvoice(status) && !open_tasks) {
      const phone_number_missing =
        overview?.replacement?.type === REPLACEMENT_TYPE.message &&
        !overview?.phone_number;

      submitButton = (
        <PrimaryButton
          onPress={() => {
            if (overview?.phone_number || overview?.email) {
              dispatch(
                showClientMessagesDialog(
                  request_id,
                  overview?.phone_number,
                  overview?.email,
                ),
              );
            }

            changeStatus(STATUS_CODES.Done);
          }}
          disabled={
            isNotReadyForInvoicing(overview?.rob?.invoicing?.status) ||
            phone_number_missing
          }
          icon={() => (
            <ServiceRequestStatusIcon
              status={STATUS_CODES.Done}
              fill="#FFFFFF"
              iconProps={{
                width: 18,
                height: 18,
              }}
            />
          )}>
          GEREED MELDEN
        </PrimaryButton>
      );
    } else {
      if (status === STATUS_CODES.Reserved) {
        submitButton = (
          <PrimaryButton
            onPress={() => changeStatus(STATUS_CODES.CheckIn)}
            icon={() => (
              <ServiceRequestStatusIcon
                status={STATUS_CODES.CheckIn}
                fill="#FFFFFF"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}>
            INCHECKEN
          </PrimaryButton>
        );
      } else if (is_retail_car && !overview?.phone_number) {
        let ready = isRobRequestReady(
          overview.components,
          null,
          is_fleet_car,
          is_retail_car,
        );
        submitButton = (
          <PrimaryButton
            onPress={() => changeStatus(STATUS_CODES.Done)}
            disabled={!ready}
            icon={() => (
              <ServiceRequestStatusIcon
                status={STATUS_CODES.Done}
                fill="#FFFFFF"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}>
            GEREED MELDEN
          </PrimaryButton>
        );
      } else if (
        status === STATUS_CODES.CheckIn ||
        status === STATUS_CODES.Modified ||
        status === STATUS_CODES.Denied ||
        open_tasks
      ) {
        let ready = isRobRequestReady(
          overview.components,
          overview.dealer_request ?? overview.fleet_request ?? overview.rob,
          is_fleet_car,
          is_retail_car,
        );
        if (
          overview.components?.length === 0 &&
          overview.replacement?.type === REPLACEMENT_TYPE.transport &&
          overview.replacement?.sla?.rob_code &&
          amount > 0
        ) {
          // Loket functie
          ready = true;
        }

        if (
          (is_retail_car || is_fleet_car) &&
          overview.components?.length === 0 &&
          overview.status === STATUS_CODES.Modified &&
          !ready
        ) {
          ready = true;
        }

        submitButton = (
          <PrimaryButton
            disabled={!ready}
            onPress={onDoRequest}
            icon={() => (
              <ServiceRequestStatusIcon
                status={STATUS_CODES.ApprovalRequested}
                fill="#FFFFFF"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}>
            VOORSTELLEN
          </PrimaryButton>
        );
      } else if (status === STATUS_CODES.ApprovalRequested) {
        submitButton = (
          <PrimaryButton
            disabled={true}
            icon={() => (
              <ServiceRequestStatusIcon
                status={STATUS_CODES.ApprovalRequested}
                fill="rgba(0, 0, 0, 0.32)"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}>
            VOORSTELLEN
          </PrimaryButton>
        );
      } else if (
        [STATUS_CODES.Done, STATUS_CODES.DoneNoInvoice].includes(status)
      ) {
        submitButton = (
          <PrimaryButton
            disabled={
              (!overview?.invoice_number && !invoice?.bahnkick_can_invoice) ||
              (!overview.fleet_request &&
                (overview.dealer_request ?? overview.rob)?.invoicing?.status !==
                  'ReadyForInvoicing')
            }
            onPress={onInvoice}
            icon={() => (
              <ServiceRequestStatusIcon
                status={STATUS_CODES.Invoiced}
                fill="#FFFFFF"
                iconProps={{
                  width: 18,
                  height: 18,
                }}
              />
            )}>
            FACTUREREN
          </PrimaryButton>
        );
      }
    }
  }

  const rob_request_errors = robErrors?.filter((item) => !item.task_id);

  return (
    status !== STATUS_CODES.Invoiced && (
      <View style={[sy.footerView, sy['justify-between'], sy['items-center']]}>
        {!canInvoice(status) ? (
          <Text style={sy.mediumPlus}>{Format.price(amount)}</Text>
        ) : (
          <View></View>
        )}
        <View style={[sy['flex-row'], sy['items-center']]}>
          {rob_request_errors?.length > 0 && (
            <Pressable style={[sy['pr-4']]} onPress={onShowRobRequestErrors}>
              <ErrorIcon fill="#972727" />
            </Pressable>
          )}
          {submitButton}
        </View>
      </View>
    )
  );
};

const RobReasonsDialog = ({
  component_id,
  rob_code,
  tasks: request_tasks,
  rob,
  rob_components,
  onDismiss,
}) => {
  let rob_codes;
  if (rob_code) {
    rob_codes = [rob_code];
  } else {
    rob_codes = [
      ...new Set(
        request_tasks
          .filter((item) => item.component_id === component_id)
          .map((item) => item.rob_code),
      ),
    ];
  }

  return (
    <Dialog
      visible={true}
      title={
        <View style={[{width: '100%'}, sy['flex-row'], sy['justify-between']]}>
          {rob_codes.map((rob_code) => (
            <Text key={rob_code} style={[sy.largePlus, sy.truncate]}>
              {rob_code}
              &nbsp;&middot;&nbsp;
              {rob_components[rob_code].description}
            </Text>
          ))}
        </View>
      }
      buttons={[
        {
          text: 'OK',
          textStyle: {color: '#972727'},
          onPress: onDismiss,
        },
      ]}
      onDismiss={onDismiss}>
      <View style={{paddingVertical: 16}}>
        {Object.values(rob?.components ?? {})
          .filter((item) => rob_codes.includes(item.rob.code))
          .map(({status, denialReasons}) => (
            <React.Fragment key={status.code}>
              <View style={[sy['flex-row'], sy['py-4']]}>
                <RobTaskIcon statuses={[status.code]} height="24" width="24" />
                <Text style={[sy['pl-4']]}>
                  {ROB_TASK_STATUS_LABELS[status.code]}
                </Text>
              </View>
              {denialReasons?.map((reason) => (
                <View key={reason.code} style={[sy['flex-row'], sy['py-4']]}>
                  <View>
                    <ReportIcon height={24} width={24} />
                  </View>
                  <Text style={[sy['pl-4']]}>
                    {reason.lessorRemark ?? reason.code}
                  </Text>
                </View>
              ))}
            </React.Fragment>
          ))}
      </View>
    </Dialog>
  );
};

const RequestDetails = ({
  onDismiss,
  request_id,
  is_fleet_car,
  is_retail_car,
  lessor,
  data,
  tasks: request_tasks,
  replacement_rob_code,
  total_amount,
}) => {
  const [replacement, setReplacement] = useState(null);
  const [showUnitPrice, setShowUnitPrice] = useState(false);

  useEffect(() => {
    if (replacement_rob_code) {
      const fetch = async () => {
        const rob_component = Object.values(data?.components ?? []).find(
          (item) => item.rob.code === replacement_rob_code,
        );

        if (rob_component && isRobTaskApproved(rob_component.status.code)) {
          const {result: sla_result} = await serviceRequestApi.sla(request_id);

          setReplacement({
            rob_component: rob_component,
            sla: sla_result?.codes?.find(
              (item) => item.rob_code === replacement_rob_code,
            ),
          });
        }
      };

      fetch();
    }
  }, [replacement_rob_code, request_id, data]);

  // TODO: Move to own file or create better solution.
  const is_fluid = (part) => {
    if (part.attributes?.option_id) {
      return false;
    }

    switch (part.gen_art_nr) {
      case 71: // Remvloeistof
      case 1893: // Ruitenreiniging
      case 3224: // Motorolie
      case 3225: // Versnellingsbakolie
      case 3356: // Koelvloeistof
      case 5040: // Ureum
      case 8561: // Versnellingsbakolie
        return true;

      default:
        return false;
    }
  };

  return (
    <Modal visible={true} onDismiss={onDismiss}>
      <View style={[{height: 64}, sy['flex-row'], sy['items-center']]}>
        <Pressable style={[sy['p-4']]} onPress={onDismiss}>
          <CloseIcon fill="#4a4a49" />
        </Pressable>

        <View style={[sy['flex-1'], sy['items-center']]}>
          <Text style={[sy.smallPlus]}>Details opdracht</Text>
        </View>
        <Pressable
          style={[sy['p-4']]}
          onPress={() => setShowUnitPrice((prevState) => !prevState)}>
          <EuroSymbolIcon
            fill="#4a4a49"
            style={{opacity: showUnitPrice ? 1 : 0.38}}
          />
        </Pressable>
      </View>
      <ScrollView>
        {Object.values(data?.components ?? [])
          .sort((a, b) => a.rob.code?.localeCompare(b.rob.code))
          .map((rob_component) => {
            const main_task = request_tasks.find(
              (item) => item.id === rob_component.id,
            );
            if (!main_task || !isRobTaskApproved(rob_component.status.code)) {
              return null;
            }

            const tasks = [main_task].concat(
              request_tasks.filter(
                (item) =>
                  item.component_id === main_task.component_id &&
                  !item.is_main_task,
              ),
            );

            const is_main_task_warranty_leniency =
              main_task.warranty === 'Warranty' ||
              _.isNumber(main_task.leniency_amount);

            const parts = tasks
              .filter((task) => task.parts)
              .flatMap((task) => task.parts)
              .filter((part) => part.quantity)
              .flatMap((part) => {
                const {service, ...rest} = part.service_cost ?? {};
                let costs = [];
                if (rest) {
                  costs = Object.entries(rest).map(([key, value]) => ({
                    description: ROB_COST_TEXTS[key] ?? key,
                    is_sla: true,
                    quantity: 1,
                    amount: value,
                  }));
                }

                return [
                  {
                    ...part,
                    amount:
                      part.quantity * part.price + part.markup - part.discount,
                  },
                  ...costs,
                ];
              });

            const service_costs = parts.reduce(
              (acc, part) => acc + (part.service_cost?.service ?? 0),
              0,
            );

            return (
              <Grouping
                key={rob_component.id}
                header={
                  <Text style={sy.medium}>
                    {is_fleet_car || is_retail_car
                      ? main_task?.description ?? rob_component.component_name
                      : rob_component.component_name ?? (
                          <>
                            {rob_component.rob.code}&nbsp;&middot;&nbsp;
                            {rob_component.rob.description}
                          </>
                        )}
                  </Text>
                }
                initial={{
                  expand: true,
                }}>
                {tasks.map((task) => {
                  let is_sla;
                  let is_tires_task;
                  let amount;
                  let is_warranty = false;
                  let is_leniency = false;

                  is_tires_task = ROB_TIRE_CODES.includes(task.rob_code);

                  if (task.warranty === 'Warranty') {
                    amount = 0;
                    is_warranty = true;
                  } else if (_.isNumber(task.leniency_amount)) {
                    amount = task.leniency_amount;
                    is_leniency = true;
                  } else {
                    if (
                      _.isNumber(task.hourly_rate) &&
                      _.isNumber(task.duration)
                    ) {
                      is_sla = false;
                      amount = task.hourly_rate * task.duration;
                    } else {
                      is_sla = true;
                      amount = task.amount;
                    }

                    if (task.is_main_task) {
                      amount += service_costs;
                    }

                    amount += task.markup;
                    amount -= task.discount;
                  }

                  const amount_diff = is_tires_task
                    ? 0
                    : amount -
                      (is_sla ? task.amount : task.hourly_rate * task.duration);

                  return (
                    <React.Fragment key={task.id}>
                      <View style={[sy['p-4']]}>
                        <View style={[sy['flex-row'], sy['justify-between']]}>
                          <Text style={sy.truncate}>
                            {task.work_text ?? task.description}
                          </Text>
                          <Text style={sy.smallRegular}>
                            {Format.price(Math.round(amount * 100) / 100)}
                          </Text>
                        </View>
                        <View style={[sy['flex-row'], sy['justify-between']]}>
                          <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                            {is_warranty ? (
                              <>Garantie</>
                            ) : is_leniency ? (
                              <>Coulance</>
                            ) : is_sla ? (
                              <>SLA</>
                            ) : (
                              <>
                                <>
                                  {Format.number(task.duration, 2)}
                                  &nbsp;u&nbsp;&middot;&nbsp;
                                </>
                                {task.reference ? <>TecRMI</> : <>Invoer</>}
                              </>
                            )}
                          </Text>
                          {showUnitPrice ? (
                            <Text
                              style={[sy.smallRegular, sy['text-lightgray']]}>
                              {Format.price(
                                Math.round(
                                  (amount /
                                    (is_sla ||
                                    is_warranty ||
                                    is_leniency ||
                                    task.duration === 0
                                      ? 1
                                      : task.duration)) *
                                    100,
                                ) / 100,
                              )}
                              &nbsp;
                              {is_sla || is_warranty || is_leniency ? (
                                <>p/s</>
                              ) : (
                                <>p/u</>
                              )}
                            </Text>
                          ) : Math.round(amount_diff * 100) / 100 !== 0 ? (
                            <Text
                              style={[
                                sy.smallRegular,
                                sy['text-lightgray'],
                                sy['line-through'],
                              ]}>
                              {Format.price(amount - amount_diff)}
                            </Text>
                          ) : null}
                        </View>
                      </View>
                      <Divider style={sy['bg-gray95']} />
                    </React.Fragment>
                  );
                })}
                {parts
                  .filter((part) => part.amount > 0)
                  .map((part, index, array) => {
                    const amount_diff = part.price
                      ? part.amount - part.quantity * part.price
                      : 0;

                    return (
                      <React.Fragment key={part.id ?? index}>
                        <View style={[sy['p-4']]}>
                          <View style={[sy['flex-row'], sy['justify-between']]}>
                            <Text style={sy.truncate}>{part.description}</Text>
                            <Text style={sy.smallRegular}>
                              {Format.price(
                                is_main_task_warranty_leniency
                                  ? 0
                                  : Math.round(part.amount * 100) / 100,
                              )}
                            </Text>
                          </View>
                          <View style={[sy['flex-row'], sy['justify-between']]}>
                            <Text
                              style={[sy.smallRegular, sy['text-lightgray']]}>
                              {part.quantity}&nbsp;&middot;&nbsp;
                              {part.is_sla ? (
                                <SlaArticleText
                                  lessor={lessor}
                                  attributes={part.attributes}
                                  specifications={part.specifications}
                                  request_id={request_id}
                                  itemMpId={part.attributes?.itemMpId}
                                />
                              ) : (
                                <ArticleText
                                  source={part.source}
                                  number={part.number}
                                />
                              )}
                            </Text>
                            {showUnitPrice ? (
                              <Text
                                style={[sy.smallRegular, sy['text-lightgray']]}>
                                {Format.price(
                                  is_main_task_warranty_leniency
                                    ? 0
                                    : Math.round(
                                        (part.price
                                          ? (part.quantity * part.price +
                                              (part.markup ?? 0) -
                                              (part.discount ?? 0)) /
                                            part.quantity
                                          : part.amount) * 100,
                                      ) / 100,
                                )}
                                &nbsp;
                                {is_fluid(part) ? <>p/l</> : <>p/s</>}
                              </Text>
                            ) : is_main_task_warranty_leniency ? (
                              <Text
                                style={[
                                  sy.smallRegular,
                                  sy['text-lightgray'],
                                  sy['line-through'],
                                ]}>
                                {Format.price(
                                  Math.round(part.amount * 100) / 100,
                                )}
                              </Text>
                            ) : Math.round(amount_diff * 100) / 100 !== 0 ? (
                              <Text
                                style={[
                                  sy.smallRegular,
                                  sy['text-lightgray'],
                                  sy['line-through'],
                                ]}>
                                {Format.price(part.amount - amount_diff)}
                              </Text>
                            ) : null}
                          </View>
                        </View>
                        {index < array.length - 1 && (
                          <Divider style={sy['bg-gray95']} />
                        )}
                      </React.Fragment>
                    );
                  })}
              </Grouping>
            );
          })}
        {replacement && (
          <>
            <Grouping
              header={
                <Text style={sy.medium}>
                  {is_fleet_car || is_retail_car ? (
                    replacement.sla.item
                  ) : (
                    <>
                      {replacement.rob_component.rob.code}&nbsp;&middot;&nbsp;
                      {replacement.rob_component.rob.description}
                    </>
                  )}
                </Text>
              }
              initial={{expand: true}}>
              <View style={[sy['flex-row'], sy['p-4'], sy['justify-between']]}>
                <View>
                  <Text>{replacement.sla.item}</Text>
                  <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                    SLA
                  </Text>
                </View>
                <Text style={sy.smallRegular}>
                  {Format.price(replacement.rob_component.price)}
                </Text>
              </View>
            </Grouping>
          </>
        )}
      </ScrollView>
      <Divider />
      <View
        style={[
          sy['flex-row'],
          sy['items-center'],
          sy['justify-between'],
          sy['p-4'],
        ]}>
        <Text style={sy.mediumPlus}>{Format.price(total_amount)}</Text>
        <PrimaryButton onPress={onDismiss}>Sluiten</PrimaryButton>
      </View>
    </Modal>
  );
};

const ClientFields = ({overview, phone_number_missing, onUpdateRequest}) => {
  const [editClient, setEditClient] = useState(null);
  const [editPhoneNumber, setEditPhoneNumber] = useState(null);
  const [editEmail, setEditEmail] = useState(null);

  return (
    <>
      <GroupingRow
        title="Voorletter(s)"
        onEditClicked={() => setEditClient(overview?.client_initials)}>
        <PrivateText>{overview?.client_initials || <>Opgeven</>}</PrivateText>
        <GroupingRowEdit
          title="Voorletter(s)"
          dismissable
          cancelButton
          onOK={async () => {
            await onUpdateRequest({client_initials: editClient});
          }}>
          <View style={[sy['p-4']]}>
            <div data-private>
              <FormTextInput
                label="Voorletter(s)"
                value={overview?.client_initials}
                onEndEditing={setEditClient}
              />
            </div>
          </View>
        </GroupingRowEdit>
      </GroupingRow>
      <GroupingRow
        title="Voornaam"
        onEditClicked={() => setEditClient(overview?.client_first_name)}>
        <PrivateText>{overview?.client_first_name || <>Opgeven</>}</PrivateText>
        <GroupingRowEdit
          title="Voornaam"
          dismissable
          cancelButton
          onOK={async () => {
            await onUpdateRequest({client_first_name: editClient});
          }}>
          <View style={[sy['p-4']]}>
            <div data-private>
              <FormTextInput
                label="Voornaam"
                value={overview?.client_first_name}
                onEndEditing={setEditClient}
              />
            </div>
          </View>
        </GroupingRowEdit>
      </GroupingRow>
      <GroupingRow
        title="Achternaam"
        onEditClicked={() => setEditClient(overview?.client_last_name)}>
        <PrivateText>{overview?.client_last_name || <>Opgeven</>}</PrivateText>
        <GroupingRowEdit
          title="Achternaam"
          dismissable
          cancelButton
          onOK={async () => {
            await onUpdateRequest({client_last_name: editClient});
          }}>
          <View style={[sy['p-4']]}>
            <div data-private>
              <FormTextInput
                label="Achternaam"
                value={overview?.client_last_name}
                onEndEditing={setEditClient}
              />
            </div>
          </View>
        </GroupingRowEdit>
      </GroupingRow>
      <GroupingRow
        title={
          <View style={[sy['flex-row'], sy['items-center']]}>
            <Text>Mobiele telefoon</Text>
            {phone_number_missing && (
              <>
                &nbsp;
                <WarningIcon fill="#FF6C00" />
              </>
            )}
          </View>
        }
        linkStyle={{
          color: phone_number_missing ? '#FF6C00' : '#4a4a49',
        }}
        onEditClicked={() => setEditPhoneNumber(overview?.phone_number)}>
        <PrivateText
          style={{
            color: phone_number_missing ? '#FF6C00' : '#4a4a49',
          }}>
          {overview?.phone_number || <>Opgeven</>}
        </PrivateText>
        <GroupingRowEdit
          title="Mobiele telefoon"
          dismissable
          cancelButton
          onOK={async () => {
            await onUpdateRequest({phone_number: editPhoneNumber});
          }}>
          <View style={[sy['p-4']]}>
            <div data-private>
              <PhoneNumberInput
                label="Nummer"
                value={overview?.phone_number}
                onChange={setEditPhoneNumber}
              />
            </div>
          </View>
        </GroupingRowEdit>
      </GroupingRow>
      <GroupingRow
        title="E-mail"
        onEditClicked={() => setEditEmail(overview?.email)}>
        <PrivateText>{overview?.email || <>Opgeven</>}</PrivateText>
        <GroupingRowEdit
          title="E-mail"
          dismissable
          cancelButton
          onOK={async () => {
            await onUpdateRequest({email: editEmail});
          }}>
          <View style={[sy['p-4']]}>
            <div data-private>
              <EmailInput
                label="E-mail"
                value={overview?.email}
                onChange={setEditEmail}
              />
            </div>
          </View>
        </GroupingRowEdit>
      </GroupingRow>
    </>
  );
};

const Index = ({dimensions, navigation, route}) => {
  const request_id = route.params?.request_id;
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState(null);
  const [editable, setEditable] = useState(true);
  const [read_only, setReadOnly] = useState(false);
  const [car, setCar] = useState(null);
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const [partial_vin, setPartialVin] = useState(null);
  const [current_mileage, setCurrentMileage] = useState(null);
  const [robComponents, setRobComponents] = useState({});
  const [invoice, setInvoice] = useState(null);
  const [vin_part, setVINPart] = useState(null);
  const [vin_number, setVINNumber] = useState(null);
  const [edit_vin, setEditVIN] = useState({});
  const [edit_invoiceNumber, setEditInvoiceNumber] = useState(null);
  const [robContract, setRobContract] = useState(null);
  const [serviceHistory, setServiceHistory] = useState(null);
  const [slaKpis, setSlaKpis] = useState({});
  const [is_fleet_car, setIsFleetCar] = useState(false);
  const [is_retail_car, setIsRetailCar] = useState(false);
  const [robNetCredentials, setRobNetCredentials] = useState(null);

  const [showMaintenanceEdit, setShowMaintenanceEdit] = useState(null);
  const [showTaskEdit, setShowTaskEdit] = useState(null);
  const [showSlaEdit, setShowSlaEdit] = useState(null);
  const [showTiresEdit, setShowTiresEdit] = useState(null);
  const [robErrors, setRobErrors] = useState([]);
  const [showRobReasonsDialog, setShowRobReasonsDialog] = useState(null);
  const [showNoInvoiceDialog, setShowNoInvoiceDialog] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [showNoInvoiceInfoDialog, setShowNoInvoiceInfoDialog] = useState(false);
  const [showRobRequestErrorsDialog, setShowRobRequestErrorsDialog] =
    useState(false);
  const [showRobTaskErrorsDialog, setShowRobTaskErrorsDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCancelRequestDialog, setShowCancelRequestDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(null);
  const [showCancelRobTaskDialog, setShowCancelRobTaskDialog] = useState(null);
  const [showMaintenanceExtrasDialog, setShowMaintenanceExtrasDialog] =
    useState(null);
  const [showMoreTimeInfoDialog, setShowMoreTimeInfoDialog] = useState(false);
  const [showOverlapInfoDialog, setShowOverlapInfoDialog] = useState(false);
  const [showDealerRequestDialog, setShowDealerRequestDialog] = useState(false);
  const [showAssignUserDialog, setShowAssignUserDialog] = useState(false);
  const [showInvoicePdfUploadDialog, setShowInvoicePdfUploadDialog] =
    useState(false);
  const [showRobNetCredentialsDialog, setShowRobNetCredentialsDialog] =
    useState(null);

  const [readLastMessage, setReadLastMessage] = useState(true);
  const [showSaveMessageDialog, setShowSaveMessageDialog] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  const [editDateTime, setEditDateTime] = useState(null);
  const [editKilometrage, setEditKilometrage] = useState(null);

  const isFocused = useIsFocused();

  const dispatch = useDispatch();
  const forceRefresh = useSelector((state) => state.serviceRequestUpdate);
  const lastFetch = useSelector((state) => state.serviceRequestLastFetch);
  const navHistory = useSelector((state) => state.nav_history);

  const user_settings = useSelector((state) => state.user_settings);

  useLayoutEffect(() => {
    const header_right_enabled =
      // TODO: if it's already cancelled, do we want to uncancel it?
      overview?.status !== STATUS_CODES.Cancelled &&
      (!(
        overview?.rob ??
        overview?.fleet_request ??
        overview?.dealer_request
      ) ||
        overview?.status === STATUS_CODES.Done ||
        (is_fleet_car && overview?.status === STATUS_CODES.Invoiced));

    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.replace('Tabs', {
                screen: 'Calendar',
              });
            }
          }}
        />
      ),
      headerStatusBarHeight: 0,
      headerTitleAlign: 'center',
      headerTitle: () =>
        loading ? null : (
          <View>
            <Text style={sy.largePlus}>
              {Format.license(overview?.license)}
            </Text>
          </View>
        ),
      headerRight: () =>
        loading ? null : (
          <Pressable
            disabled={
              // Disable the link when the request has been submitted to rob or has been cancelled. If the service request is in the Done state, it is active.
              !header_right_enabled
            }
            onPress={() => {
              if (
                overview.status === STATUS_CODES.Done ||
                (is_fleet_car && overview.status === STATUS_CODES.Invoiced)
              ) {
                let reset;
                if (overview.status === STATUS_CODES.Done) {
                  reset = {from: STATUS_CODES.Done, to: STATUS_CODES.CheckIn};
                } else {
                  reset = {from: STATUS_CODES.Invoiced, to: STATUS_CODES.Done};
                }

                setShowResetDialog(reset);
              } else {
                setShowCancelRequestDialog(true);
              }
            }}>
            <ServiceRequestStatusIcon
              style={{marginRight: 16}}
              status={overview.status}
            />
          </Pressable>
        ),
    });
  }, [navigation, overview, is_fleet_car, loading]);

  useEffect(() => {
    if (overview) {
      LogRocket.track(
        'Service Request Details',
        _.pickBy({
          request_id,
          license: overview?.license,
          status: overview?.status,
          rob_status: overview?.rob?.status,
        }),
      );
    }
  }, [request_id, overview]);

  useEffect(() => {
    if (loading) {
      dispatch(setAppIsLoading());
    } else {
      dispatch(setAppDoneLoading());
    }
  }, [loading, dispatch]);

  const refresh = useCallback(async () => {
    const {result} = await serviceRequestApi.get(request_id, dispatch);
    if (!result.length || IsReplacementVehicle(result[0].request_type)) {
      navigation.replace('Tabs', {
        screen: 'Calendar',
      });
      return false;
    }

    setOverview(result[0]);
    setRobErrors(result[0].rob_errors);
    setVINPart(result[0].vin_part);
    setVINNumber(result[0].vin_number);
    setCurrentMileage(result[0].mileage);
    setReadLastMessage(
      !result[0].last_message?.user_id
        ? result[0].last_message?.is_read ?? true
        : true,
    );

    let is_fleet_car = false;
    let is_retail_car = false;
    let contract;
    if (result[0].request_type === REQUEST_TYPES.SERVICE_REQUEST) {
      is_retail_car = true;
      setIsRetailCar(true);
    } else if (result[0].request_type === REQUEST_TYPES.FLEET_REQUEST) {
      is_fleet_car = true;
      setIsFleetCar(true);

      const {result: fleet_contract} = await fleetApi.contract(
        result[0].fleet_contract_id,
      );

      contract = fleet_contract.contract;
    } else {
      ({result: contract} = await robApi.contract(
        result[0].license,
        request_id,
      ));

      const rob_codes = [
        ...new Set(
          [
            ...(result[0].tasks?.map((item) => item.rob_code) ?? []),
            result[0].replacement?.sla?.rob_code,
          ].filter(Boolean),
        ),
      ];
      if (rob_codes.length) {
        const {result: robComponents} = await robApi.components(
          result[0].license,
          request_id,
          rob_codes,
        );
        setRobComponents(robComponents);
      }
    }

    setEditable(
      !isLessorStatus(
        is_fleet_car || is_retail_car
          ? result[0].status
          : result[0].rob?.status,
      ) &&
        result[0].status !== STATUS_CODES.Cancelled &&
        result[0].status !== STATUS_CODES.Done &&
        result[0].status !== STATUS_CODES.DoneNoInvoice &&
        result[0].status !== STATUS_CODES.Invoiced &&
        result[0].status !== STATUS_CODES.Archived,
    );
    setReadOnly(
      result[0].status === STATUS_CODES.Cancelled ||
        result[0].status === STATUS_CODES.Done ||
        result[0].status === STATUS_CODES.DoneNoInvoice ||
        result[0].status === STATUS_CODES.Invoiced ||
        result[0].status === STATUS_CODES.Archived,
    );

    setRobContract(contract);

    if (
      (result[0].status === STATUS_CODES.Done ||
        result[0].status === STATUS_CODES.DoneNoInvoice ||
        result[0].status === STATUS_CODES.Invoiced ||
        result[0].status === STATUS_CODES.Archived) &&
      (is_fleet_car ||
        is_retail_car ||
        result[0].rob_invoicing_status === 'ReadyForInvoicing' ||
        result[0].rob_invoicing_status === 'Invoiced')
    ) {
      const {result: invoice} = await serviceRequestApi.invoice(request_id);

      setInvoice(invoice);
    }

    const car = await carApi.fetch(result[0].license);
    setCar(car);

    const {result: sla} = await serviceRequestApi.sla(request_id);
    setSlaKpis(sla.kpis);

    return true;
  }, [navigation, request_id, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      const success = await refresh();

      const {result: history} = await serviceRequestApi.history(request_id);
      setServiceHistory(history);

      if (success) {
        setLoading(false);
      }
    };

    if (isFocused) {
      setLoading(true);
      fetch();
    }
  }, [refresh, request_id, isFocused]);

  useEffect(() => {
    // Keep partial vin in sync with vin_part.
    setPartialVin(vin_part);
  }, [vin_part]);

  const hasSeenAddTasks = () => {
    return navHistory.find(
      (item) =>
        item.name === 'AddTasks' && item.params.request_id === request_id,
    );
  };

  const onDoRequest = async () => {
    if (
      (is_retail_car
        ? !overview.dealer_request
        : is_fleet_car
        ? !overview.fleet_request
        : !overview.rob) &&
      overview.components.find((item) => item.name === 'Onderhoud') &&
      overview.tasks.find(
        (item) =>
          item.type === 'MAINTENANCE' && item.is_main_task && !!item.reference,
      ) &&
      !user_settings?.never_show_maintenance_extras_dialog &&
      !hasSeenAddTasks()
    ) {
      try {
        await new Promise((resolve, reject) => {
          setShowMaintenanceExtrasDialog({
            resolve,
            reject,
          });
        });
      } catch {
        return;
      }
    }

    if (pendingMessage?.length > 0) {
      setShowSaveMessageDialog(true);
      return true;
    }

    dispatch(setAppIsLoading());

    try {
      if (is_retail_car) {
        setShowDealerRequestDialog(true);
      } else if (is_fleet_car && overview?.status !== STATUS_CODES.CheckIn) {
        const {success} = await fleetApi.request(request_id);
        if (success) {
          await refresh();
        }
      } else if (overview.rob?.status) {
        const {success, errors} = await robApi.update_request(request_id, {
          rob_net_credentials: robNetCredentials,
        });

        if (success) {
          await refresh();
        } else {
          if (errors && errors[0] === 'ROB_NET_CREDENTIALS_INVALID') {
            setShowRobNetCredentialsDialog({
              error: errors[1],
            });
            return false;
          }

          const {result} = await serviceRequestApi.get(request_id, dispatch);
          setRobErrors(result[0].rob_errors);
        }
      } else {
        setShowRequestDialog(true);
        return true;
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onRequest = async () => {
    if (is_fleet_car) {
      const {success} = await fleetApi.request(request_id);
      if (success) {
        setShowRequestDialog(false);
        await refresh();
      }
    } else {
      await onRequestROB();
    }
  };

  const onRequestROB = async () => {
    if (!partial_vin || !current_mileage) {
      return;
    }

    dispatch(setAppIsLoading());

    try {
      const {replacement, ...rest} = overview;
      await serviceRequestApi.patch(request_id, {
        ...rest,
        mileage: current_mileage,
      });

      const {success, errors} = await robApi.request(
        request_id,
        partial_vin,
        current_mileage,
        robNetCredentials,
      );
      if (success) {
        await refresh();
        setShowRequestDialog(false);
      } else {
        if (errors && errors[0] === 'ROB_NET_CREDENTIALS_INVALID') {
          setShowRequestDialog(false);
          setShowRobNetCredentialsDialog({
            error: errors[1],
          });
          return;
        }

        const {result} = await serviceRequestApi.get(request_id, dispatch);
        setRobErrors(result[0].rob_errors);
        setShowRequestDialog(false);
        setShowRobRequestErrorsDialog(
          result[0].rob_errors?.filter((item) => !item.task_id)?.length > 0,
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  useEffect(() => {
    const last_fetch_date = moment(lastFetch[request_id] ?? new Date(0));
    const update_date = moment(
      forceRefresh[request_id]?.timestamp ?? new Date(0),
    );
    if (update_date.isAfter(last_fetch_date, 'seconds')) {
      console.log(
        'Refreshing service_request',
        request_id,
        last_fetch_date,
        update_date,
      );

      refresh();
    }
  }, [request_id, forceRefresh, lastFetch, refresh]);

  const InvoiceServiceRequest = async (pdf) => {
    dispatch(setAppIsLoading());

    try {
      if (overview?.dealer_request) {
        const {success} = await dealerApi.invoice(request_id);
        if (success) {
          await refresh();
        }
      } else if (overview?.fleet_request) {
        const {success, errors} = await fleetApi.invoice(request_id, pdf);
        if (success) {
          await refresh();
          return null;
        }

        return errors;
      } else {
        const {success, errors} = await robApi.invoice(
          request_id,
          overview.invoice_number,
        );

        if (success) {
          await serviceRequestApi.change_status(
            request_id,
            STATUS_CODES.Invoiced,
          );

          await refresh();
        } else {
          setRobErrors(errors);
          setShowRobRequestErrorsDialog(true);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onInvoice = async () => {
    if (invoice?.pdf_upload && !invoice?.bahnkick_can_invoice) {
      setShowInvoicePdfUploadDialog(true);
    } else {
      await InvoiceServiceRequest();
    }
  };

  const onChangeInvoiceStatus = async () => {
    dispatch(setAppIsLoading());
    try {
      if (overview?.invoice_number) {
        // Clear the entered invoice number
        await onUpdateRequest({invoice_number: null});
      }

      const {success} = await robApi.change_invoice_status(
        request_id,
        invoiceStatus,
      );

      if (success) {
        setShowNoInvoiceDialog(false);
        setShowNoInvoiceInfoDialog(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      await refresh();
      dispatch(setAppDoneLoading());
    }
  };

  const onActivateComponent = async (component_id, main_task_id) => {
    dispatch(setAppIsLoading());
    try {
      const {success, errors} = await serviceRequestApi.activate_component(
        request_id,
        component_id,
        main_task_id,
      );

      if (errors?.length > 0) {
        setRobErrors(errors);
      }

      if (success) {
        await refresh();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onDeleteComponent = async (component_id, main_task_id) => {
    dispatch(setAppIsLoading());
    try {
      await serviceRequestApi.delete_component(
        request_id,
        component_id,
        main_task_id,
      );
    } catch (e) {
      console.error(e);
    } finally {
      await refresh();
      dispatch(setAppDoneLoading());
    }
  };

  const onUpdateRequest = async (changes) => {
    const {replacement, ...rest} = overview;
    const result = await serviceRequestApi.patch(request_id, {
      ...rest,
      ...changes,
    });

    if (result?.success) {
      refresh();
    }

    return result?.success;
  };

  const onUpdateCar = async (attributes) => {
    const result = await carApi.change_attributes(car.license, attributes);

    if (result?.success) {
      refresh();
    }
  };

  const onMaintenanceEdit = async (changes) => {
    dispatch(setAppIsLoading());

    try {
      if (!changes.task_id) {
        const main_task = overview.tasks?.find(
          (item) =>
            item.rob_code === changes.rob_code &&
            item.is_main_task &&
            item.type === 'MAINTENANCE',
        );

        changes.task_id = main_task?.id;
      }

      const {id: component_id} = showMaintenanceEdit.component;
      const result = await serviceRequestApi.update_maintenance_task(
        request_id,
        {
          ...changes,
          component_id: changes.component_id ?? component_id,
        },
      );

      if (result.success) {
        setShowMaintenanceEdit(null);
      }
    } catch (e) {
      console.error(e);
    } finally {
      await refresh();
      dispatch(setAppDoneLoading());
    }
  };

  const onTaskEdit = async (changes) => {
    dispatch(setAppIsLoading());

    try {
      let {
        task_id,
        rob_code,
        duration,
        original_duration,
        operation,
        reason,
        location,
        parts,
        warranty,
        leniency_amount,
        is_damage,
        discount,
        markup,
        tasks: changed_tasks,
      } = changes;

      let main_task = overview.tasks.find(
        (item) => item.id === task_id && item.is_main_task,
      );

      if (!main_task) {
        main_task = overview.tasks.find(
          (item) => item.rob_code === rob_code && item.is_main_task,
        );
      }

      const tasks = changed_tasks.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const changed_task = changed_tasks.find((t) => t.id === task_id);
      tasks[task_id] = {
        ...tasks[task_id],
        ...changed_task,
        id: task_id,
        rob_code,
        duration,
        original_duration,
      };

      if (main_task?.id === task_id) {
        tasks[task_id] = {
          ...tasks[task_id],
          operation,
          reason,
          location,
          parts,
          warranty,
          leniency_amount,
          is_damage,
          discount,
          markup,
        };

        if (!is_fleet_car && !is_retail_car) {
          const children = Object.values(tasks).filter(
            (item) => item.rob_code === rob_code && item.id !== task_id,
          );

          for (const child of children) {
            tasks[child.id] = null;
          }

          for (const changed_child_task of changed_tasks.filter(
            (t) => t.id !== task_id,
          )) {
            const child =
              children.find((c) => c.id === changed_child_task.id) ?? {};

            const child_id = child.id ?? changed_child_task.id;
            tasks[child_id] = {
              ...child,
              ...changed_child_task,
              id: child_id,
            };
          }
        }
      } else if (!tasks[task_id].reference) {
        // The edited task is not the main task for this rob code (probably changed the rob code) and is also not a TecRMI task, so silently delete this task... (TecRMI has preference)
        tasks[task_id] = null;
      }

      const result = await serviceRequestApi.update_tasks(request_id, {
        tasks: Object.values(tasks).filter(Boolean),
      });

      if (result.success) {
        refresh();
        setShowTaskEdit(null);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onSlaEdit = async (changes) => {
    dispatch(setAppIsLoading());

    try {
      const {parts, warranty, leniency_amount, is_damage, discount, markup} =
        changes;
      const {id: task_id} = showSlaEdit.task;
      const existing_task = overview?.tasks.find((item) => item.id === task_id);

      const result = await serviceRequestApi.update_tasks(request_id, {
        tasks: [
          {
            ...existing_task,
            warranty,
            leniency_amount,
            is_damage,
            discount,
            markup,
            parts,
          },
        ],
      });

      if (result.success) {
        setShowSlaEdit(null);
        refresh();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onTiresEdit = async (changes) => {
    dispatch(setAppIsLoading());

    try {
      const result = await serviceRequestApi.update_tires(request_id, {
        ...changes,
      });

      if (result.success) {
        setShowTiresEdit(null);
        refresh();
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  };

  const onSendChatMessage = async () => {
    const {success} = await serviceRequestApi.send_message(
      request_id,
      pendingMessage,
    );

    if (success) {
      setPendingMessage(null);
      refresh();
    }
  };

  const onSendChatImage = async (image) => {
    const {success} = await serviceRequestApi.send_image(request_id, image);

    if (success) {
      refresh();
    }
  };

  const components =
    overview?.components
      .map((item) => item.id)
      .sort((a, b) => {
        const component_a = overview?.components.find((item) => item.id === a);
        const component_b = overview?.components.find((item) => item.id === b);
        const task_a = overview?.tasks.find(
          (task) => task.component_id === a && task.is_main_task,
        );
        const task_b = overview?.tasks.find(
          (task) => task.component_id === b && task.is_main_task,
        );

        if (
          !task_a &&
          SERVICE_TYPE_COMPONENTS_ORDER.indexOf(component_a.name) === -1
        ) {
          return 1;
        }

        if (
          !task_b &&
          SERVICE_TYPE_COMPONENTS_ORDER.indexOf(component_b.name) === -1
        ) {
          return -1;
        }

        if (!task_a && !task_b) {
          return (
            SERVICE_TYPE_COMPONENTS_ORDER.indexOf(component_a.name) -
            SERVICE_TYPE_COMPONENTS_ORDER.indexOf(component_b.name)
          );
        }

        if (!task_a) {
          return -1;
        }

        if (!task_b) {
          return 1;
        }

        if (task_a.rob_code && !task_b.rob_code) {
          return -1;
        }

        if (task_b.rob_code && !task_a.rob_code) {
          return 1;
        }

        if (task_a.rob_code && task_b.rob_code) {
          return task_a.rob_code.localeCompare(task_b.rob_code);
        }

        return (task_a.description ?? task_a.work_text ?? '').localeCompare(
          task_b.description ?? task_b.work_text ?? '',
        );
      }) ?? [];

  let total_amount = 0;
  if (overview?.status !== STATUS_CODES.Cancelled) {
    total_amount = overview?.tasks
      ?.filter((task) => {
        if (is_retail_car) {
          return true;
        }

        if (!is_fleet_car && !task.rob_code) {
          return false;
        }

        if (
          isRobTaskCancelled(
            (
              overview?.dealer_request ??
              overview?.fleet_request ??
              overview?.rob
            )?.components[task.id]?.status.code,
          )
        ) {
          return false;
        }

        if (!task.is_main_task) {
          const main_task = overview.tasks.find(
            (item) => item.is_main_task && item.rob_code === task.rob_code,
          );

          if (
            isRobTaskCancelled(
              (
                overview?.dealer_request ??
                overview?.fleet_request ??
                overview?.rob
              )?.components[main_task.id]?.status.code,
            ) ||
            main_task.warranty
          ) {
            return false;
          }
        }

        return true;
      })
      .reduce((acc, task) => acc + task.task_amount, 0);

    total_amount += overview?.replacement?.sla?.amount ?? 0;
  }

  const contract_ends =
    robContract &&
    robContract.leaseContract?.endDate &&
    moment(robContract.leaseContract.endDate).isBefore(
      moment().add(3, 'months'),
    );

  const phone_number_missing =
    overview?.replacement?.type === REPLACEMENT_TYPE.message &&
    !overview?.phone_number;

  return (
    <View style={sy.mainView}>
      {!loading && (
        <>
          <ScrollView style={[sy['bg-gray95'], {marginBottom: -1}]}>
            <Grouping
              icon={<EventIcon fill="#4A4A49" />}
              header={
                <>
                  <Text style={[sy.mediumPlus, sy.capitalize]}>
                    {moment(overview?.datetime, 'YYYY-MM-DD').format('dddd')}
                  </Text>
                  &nbsp;
                  {moment(overview?.datetime, 'YYYY-MM-DD').format('D MMMM')}
                  &nbsp;&middot;&nbsp;
                  {moment.utc(overview?.datetime).format('H:mm')}
                  &nbsp;uur
                </>
              }
              options={{topMost: true}}>
              <GroupingRow title="Vestiging">
                <Text>{overview?.dealer_city}</Text>
              </GroupingRow>
              <GroupingRow title="Datum">
                <Text>
                  {moment(overview?.datetime, 'YYYY-MM-DD').format(
                    'dddd D MMMM YYYY',
                  )}
                </Text>
                {(editable || overview?.status === STATUS_CODES.Done) && (
                  <GroupingRowEdit
                    title="Datum wijzigen"
                    dismissable
                    cancelButton
                    disableOKButton={!editDateTime}
                    onClose={() => setEditDateTime(null)}
                    onOK={() => {
                      editDateTime.hours(
                        moment(overview.datetime).utc().hours(),
                      );
                      editDateTime.minutes(moment(overview.datetime).minutes());
                      onUpdateRequest({datetime: editDateTime});
                    }}>
                    <Calendar
                      months={[
                        /* Three months in the past and six in the future */
                        -3, -2, -1, 0, 1, 2, 3, 4, 5, 6,
                      ]}
                      initialDate={overview?.datetime}
                      date={editDateTime ?? overview?.datetime}
                      onPress={setEditDateTime}
                    />
                  </GroupingRowEdit>
                )}
              </GroupingRow>
              <GroupingRow title="Tijd">
                <Text>{moment.utc(overview?.datetime).format('H:mm')}</Text>
                {(editable || overview?.status === STATUS_CODES.Done) && (
                  <GroupingRowEdit
                    title="Tijd wijzigen"
                    dismissable
                    cancelButton
                    disableOKButton={!editDateTime}
                    onClose={() => setEditDateTime(null)}
                    onOK={() => {
                      onUpdateRequest({datetime: editDateTime});
                    }}>
                    <ScrollView style={[sy['pl-4'], {maxHeight: 400}]}>
                      <TimeSlots
                        begin_checkin={moment().startOf('day').hours(6)}
                        end_checkin={moment().startOf('day').hours(19)}
                        datetime={editDateTime ?? overview?.datetime}
                        onPress={setEditDateTime}
                      />
                    </ScrollView>
                  </GroupingRowEdit>
                )}
              </GroupingRow>
            </Grouping>

            <Grouping
              icon={<DirectionsCarIcon fill="#4A4A49" />}
              header={overview?.car_name}>
              <GroupingRow
                title={
                  <View style={[sy['flex-row'], sy['items-center']]}>
                    <Text>Chassisnr. (VIN)</Text>
                  </View>
                }
                onEditClicked={async () => {
                  const {vin_number} = await carApi.vinLookup(
                    overview.license,
                    vin_part,
                  );

                  setEditVIN({
                    vin_part,
                    vin_number,
                    valid: vin_number?.endsWith(vin_part),
                  });
                }}>
                <Text>{vin_number?.length ? vin_number : <>Opgeven</>}</Text>
                <GroupingRowEdit
                  title="Meldcode & VIN"
                  dismissable
                  cancelButton
                  onValidate={() => {
                    const valid =
                      edit_vin.vin_part?.length === 4 &&
                      edit_vin.vin_number?.endsWith(edit_vin.vin_part);

                    setEditVIN((prevState) => ({
                      ...prevState,
                      valid,
                    }));
                    return valid;
                  }}
                  onOK={async () => {
                    if (edit_vin.valid) {
                      await onUpdateRequest(edit_vin);
                      refresh();
                    }
                  }}>
                  <View style={[sy['p-4']]}>
                    <FormTextInput
                      label="Meldcode"
                      value={edit_vin.vin_part}
                      hasError={edit_vin?.valid === false}
                      errorMessage="Meldcode is onjuist"
                      onChangeText={(value) => {
                        setEditVIN((prevState) => ({
                          ...prevState,
                          vin_part: value,
                          valid: true,
                        }));

                        if (value?.length === 4) {
                          carApi
                            .vinLookup(overview.license, value)
                            .then(({vin_number}) => {
                              setEditVIN((prevState) => ({
                                ...prevState,
                                vin_number: vin_number ?? prevState.vin_number,
                                valid: !!vin_number,
                              }));
                            });
                        }
                      }}
                      inputProps={{
                        maxLength: 4,
                        keyboardType: 'numeric',
                      }}
                    />
                  </View>
                  <View style={[sy['p-4'], sy['pt-0']]}>
                    <FormTextInput
                      disabled
                      label="Chassisnummer (VIN)"
                      value={edit_vin.vin_number}
                    />
                  </View>
                </GroupingRowEdit>
              </GroupingRow>
              <GroupingRow title="Brandstof">
                <Text>{overview?.fuel}</Text>
              </GroupingRow>
              {car?.apk_expiry && (
                <GroupingRow title="APK vervaldatum">
                  <Text>{moment(car?.apk_expiry).format('D-M-YYYY')}</Text>
                </GroupingRow>
              )}
              <GroupingRow
                title="Kilometerstand"
                onEditClicked={() => setEditKilometrage(overview?.mileage)}>
                <Text>
                  {Format.number(overview?.mileage, 0, true) || <>Opgeven</>}
                </Text>
                <GroupingRowEdit
                  title="Kilometerstand wijzigen"
                  dismissable
                  cancelButton
                  onOK={() => {
                    onUpdateRequest({mileage: editKilometrage});
                  }}>
                  <View style={[sy['p-4']]}>
                    <FormNumberInput
                      label="Kilometerstand"
                      value={overview?.mileage}
                      digits={0}
                      onChangeNumber={setEditKilometrage}
                    />
                  </View>
                </GroupingRowEdit>
              </GroupingRow>
              {car?.entry_date && (
                <GroupingRow title="Eerste toelating">
                  <Text>
                    {moment(car?.entry_date).format('D-M-YYYY')}&nbsp;(
                    {Format.number(
                      moment().diff(moment(car?.entry_date), 'months') / 12,
                      1,
                      false,
                      {minimumFractionDigits: 0},
                    )}
                    &nbsp;jaar)
                  </Text>
                </GroupingRow>
              )}
              <GroupingRow title="Verzwaard gebruik">
                <Text>{car?.attributes?.heavy_use ? <>Ja</> : <>Nee</>}</Text>
                <GroupingRowEdit
                  title={Format.license(car.license)}
                  initialValue={car}
                  dismissable
                  cancelButton
                  onCancel={({initialValue}) => {
                    setCar(initialValue);
                    onUpdateCar(initialValue.attributes);
                  }}>
                  <Pressable
                    style={[
                      sy['p-4'],
                      sy['gap-4'],
                      sy['flex-row'],
                      sy['items-center'],
                    ]}
                    onPress={() => {
                      setCar((prevState) => {
                        const attributes = {
                          ...prevState.attributes,
                          heavy_use: !prevState.attributes?.heavy_use,
                        };

                        onUpdateCar(attributes);

                        return {
                          ...prevState,
                          attributes,
                        };
                      });
                    }}>
                    <Checkbox checked={car?.attributes?.heavy_use} />
                    <Text>Verzwaard gebruik</Text>
                  </Pressable>
                </GroupingRowEdit>
              </GroupingRow>
            </Grouping>
            {!is_retail_car || overview?.kvk_name ? (
              <Grouping
                icon={
                  <BusinessIcon
                    fill={
                      contract_ends || robContract?.isActive === false
                        ? '#972727'
                        : phone_number_missing
                        ? '#FF6C00'
                        : '#4A4A49'
                    }
                  />
                }
                header={overview?.kvk_name ?? overview?.lessor}>
                {robContract?.lessor?.sublessor && (
                  <GroupingRow
                    title="Subleasemaatschappij"
                    style={[sy['gap-4']]}
                    contentStyle={[sy['flex-1']]}>
                    <Text style={[sy['text-right']]}>
                      {robContract.lessor.sublessor.name}
                    </Text>
                  </GroupingRow>
                )}
                {robContract?.lessor && (
                  <GroupingRow
                    title="Telefoonnummer"
                    style={[sy['gap-4']]}
                    contentStyle={[sy['flex-1']]}>
                    <LessorPhone
                      lessor={robContract?.lessor}
                      style={[sy['text-right']]}
                    />
                  </GroupingRow>
                )}
                {robContract?.leaseContract?.endDate && (
                  <GroupingRow
                    title={
                      <View style={[sy['flex-row'], sy['items-center']]}>
                        <Text>Einde contract</Text>
                        {contract_ends && (
                          <>
                            &nbsp;
                            <WarningIcon fill="#972727" />
                          </>
                        )}
                      </View>
                    }>
                    <Text>
                      {moment(robContract?.leaseContract.endDate).format(
                        'D-M-YYYY',
                      )}
                      &nbsp;(
                      {Format.number(
                        robContract?.leaseContract.maximumMileage,
                        0,
                        true,
                      )}{' '}
                      km)
                    </Text>
                  </GroupingRow>
                )}
                {is_retail_car ? (
                  <></>
                ) : (
                  <>
                    <GroupingRow
                      title={
                        <View style={[sy['flex-row'], sy['items-center']]}>
                          <Text>Laatste werkopdracht</Text>
                        </View>
                      }>
                      <ServiceHistoryLink
                        request_id={request_id}
                        license={overview?.license}
                        history={serviceHistory}
                      />
                    </GroupingRow>
                    <GroupingRow title="Reparatie & onderhoud">
                      <Text>
                        {robContract?.permissions.repairAndMaintenance ? (
                          <>Toegestaan</>
                        ) : (
                          <>Niet toegestaan</>
                        )}
                      </Text>
                    </GroupingRow>
                    <GroupingRow title="Schade">
                      {robContract?.supplierContract?.repairAndMaintenance
                        .componentsWithDamageAllowed === false ? (
                        <View style={[sy['flex-row'], sy['gap-4']]}>
                          <ExternalLink
                            href={`https://supplier.rob-net.nl/nl-NL/${robContract.supplier?.id}/ServiceRequest/Create?serviceRequestType=Damage`}>
                            ROB-Net portaal
                          </ExternalLink>
                          <OpenInNewIcon fill="#4a4a49" />
                        </View>
                      ) : (
                        <Text>Toegestaan</Text>
                      )}
                    </GroupingRow>
                    <GroupingRow title="Bandvervanging">
                      <Text>
                        {robContract?.permissions.tires.replace ? (
                          <>Toegestaan</>
                        ) : (
                          <>Niet toegestaan</>
                        )}
                      </Text>
                    </GroupingRow>
                    <GroupingRow title="Seizoenswissel">
                      <Text>
                        {robContract?.permissions.tires.swap ? (
                          <>Toegestaan</>
                        ) : (
                          <>Niet toegestaan</>
                        )}
                      </Text>
                    </GroupingRow>
                    <GroupingRow title="Vervangend vervoer">
                      <Text>
                        {robContract?.permissions.replacement_vehicle
                          ?.allowed ? (
                          <>Toegestaan</>
                        ) : (
                          <>Niet toegestaan</>
                        )}
                      </Text>
                    </GroupingRow>
                  </>
                )}
                {is_retail_car ? (
                  overview?.kvk_name ? (
                    <>
                      <GroupingRow title="Bedrijfsnaam">
                        <Text>{overview?.kvk_name}</Text>
                      </GroupingRow>
                      <GroupingRow title="KVK nummer">
                        <Text>{overview?.kvk_number}</Text>
                      </GroupingRow>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    <ClientFields
                      overview={overview}
                      phone_number_missing={phone_number_missing}
                      onUpdateRequest={onUpdateRequest}
                    />
                    {!is_fleet_car && robContract?.leaseContract && (
                      <GroupingRow>
                        <View style={[sy['flex-row'], sy['justify-between']]}>
                          {robContract.isActive ? (
                            <Pressable
                              style={[sy['flex-row']]}
                              onPress={async () => {
                                const {result: rob_contract} =
                                  await robApi.update_contract(
                                    overview.license,
                                    request_id,
                                  );

                                setRobContract(rob_contract);
                              }}>
                              <View style={[sy['pr-8']]}>
                                <RefreshIcon fill="#231fda" />
                              </View>
                              <View>
                                <Link>Gegevens vernieuwen</Link>
                                <Text
                                  style={[
                                    sy.smallRegular,
                                    sy['text-lightgray'],
                                  ]}>
                                  Laatst bijgewerkt&nbsp;
                                  {moment(robContract?.timestamp).format(
                                    'D MMMM YYYY H:mm',
                                  )}
                                </Text>
                              </View>
                            </Pressable>
                          ) : (
                            <View style={sy['flex-row']}>
                              <View style={[sy['pr-8']]}>
                                <WarningLargeIcon fill="#972727" />
                              </View>
                              <View>
                                <Text>Contract inactief</Text>
                                <Text
                                  style={[
                                    sy.smallRegular,
                                    sy['text-lightgray'],
                                  ]}>
                                  Laatst bijgewerkt&nbsp;
                                  {moment(robContract?.timestamp).format(
                                    'D MMMM YYYY H:mm',
                                  )}
                                </Text>
                              </View>
                            </View>
                          )}
                        </View>
                      </GroupingRow>
                    )}
                  </>
                )}
              </Grouping>
            ) : (
              <></>
            )}
            {is_retail_car && (
              <Grouping
                icon={<PersonIcon fill="#4A4A49" />}
                header={
                  (overview.client_first_name || overview.client_initials) &&
                  overview.client_last_name
                    ? `${
                        overview.client_first_name || overview.client_initials
                      } ${overview.client_last_name}`
                    : 'Berijder'
                }>
                <ClientFields
                  overview={overview}
                  phone_number_missing={phone_number_missing}
                  onUpdateRequest={onUpdateRequest}
                />
              </Grouping>
            )}
            <View style={sy['bg-white']}>
              <Divider />
              <View style={[sy['flex-row'], sy['justify-between'], sy['p-4']]}>
                <View style={[sy['flex-row'], sy['flex-1']]}>
                  <View style={[sy['pr-8']]}>
                    <AssignmentIcon fill="#4A4A49" />
                  </View>
                  <View>
                    <Text style={sy.mediumPlus}>
                      Opdracht&nbsp;&middot;&nbsp;
                      {isNotInvoiced(overview.rob?.invoicing?.status)
                        ? STATUS_DESCRIPTIONS[STATUS_CODES.NoInvoice]
                        : overview.status === STATUS_CODES.Done &&
                          isNotReadyForInvoicing(
                            overview.rob?.invoicing?.status,
                          )
                        ? STATUS_DESCRIPTIONS[STATUS_CODES.NotReadyForInvoicing]
                        : STATUS_DESCRIPTIONS[overview.status]}
                    </Text>
                    <Link
                      textStyle={[sy.small, sy['text-lightgray']]}
                      onPress={() => setShowAssignUserDialog(true)}>
                      {overview?.assigned_user ?? 'Toewijzen'}
                    </Link>
                  </View>
                </View>
                <Text style={sy.mediumPlus}>{Format.price(total_amount)}</Text>
              </View>
              <Divider />
              {components?.flatMap((component_id) => {
                const component = overview?.components.find(
                  (item) => item.id === component_id,
                );

                const has_rob_errors = robErrors
                  .map((item) => item.component_id)
                  .includes(component?.id);

                let main_tasks = overview?.tasks.filter(
                  (item) =>
                    item.component_id === component_id && item.is_main_task,
                );

                if (!main_tasks.length) {
                  main_tasks = [null];
                }

                const result = [];
                for (const main_task of main_tasks.sort((a, b) => {
                  if (a?.rob_code === b?.rob_code) {
                    return 0;
                  }
                  return a?.rob_code < b?.rob_code ? -1 : 1;
                })) {
                  const tasks = overview?.tasks.filter(
                    (item) =>
                      item.component_id === component?.id &&
                      item.rob_code === main_task?.rob_code,
                  );

                  const rob_code = main_task?.rob_code;

                  const amount = tasks
                    .filter((task) => task.rob_code === rob_code)
                    .reduce((acc, task) => acc + task.task_amount, 0);

                  const task_ready =
                    isRobTaskReady(
                      main_task,
                      amount,
                      component,
                      (
                        overview?.dealer_request ??
                        overview?.fleet_request ??
                        overview?.rob
                      )?.components[main_task?.id],
                      is_fleet_car,
                      is_retail_car,
                    ) === ROB_READY_CODES.Ready;

                  const is_sla_item = tasks.length
                    ? tasks.some((item) => _.isNumber(item.amount))
                    : false;

                  const approved = isRobTaskApproved(
                    (
                      overview?.dealer_request ??
                      overview?.fleet_request ??
                      overview?.rob
                    )?.components[main_task?.id]?.status.code,
                  );

                  const cancelled =
                    (
                      overview?.dealer_request ??
                      overview?.fleet_request ??
                      overview?.rob
                    )?.components[main_task?.id]?.status.code ===
                    ROB_TASK_STATUS.Cancelled;

                  const cancellation_approved =
                    (
                      overview?.dealer_request ??
                      overview?.fleet_request ??
                      overview?.rob
                    )?.components[main_task?.id]?.status.code ===
                    ROB_TASK_STATUS.CancellationApproved;

                  const cancellation_denied =
                    (
                      overview?.dealer_request ??
                      overview?.fleet_request ??
                      overview?.rob
                    )?.components[main_task?.id]?.status.code ===
                    ROB_TASK_STATUS.CancellationDenied;

                  const has_discount = main_task?.task_discount > 0;
                  const has_markup = main_task?.task_markup > 0;
                  const has_original_duration =
                    main_task?.original_duration > 0;

                  const has_work_time_overlap = tasks.some((task) =>
                    _.isNumber(task.calculated_work_time),
                  );

                  const kpi = slaKpis?.[main_task?.rob_code];

                  const onTaskPress = () => {
                    if (component.name === 'Onderhoud') {
                      setShowMaintenanceEdit({
                        task: {
                          ...(main_task ?? {}),
                          reference: main_task?.reference
                            ? main_task?.source === 'Fabrikant'
                              ? main_task?.reference
                              : parseInt(main_task?.reference)
                            : null,
                          parts:
                            main_task?.parts?.reduce((acc, item) => {
                              acc[item.attributes.itemMpId] = item;
                              return acc;
                            }, {}) ?? {},
                        },
                        additional: tasks.filter(
                          (item) => item.type !== 'MAINTENANCE',
                        ),
                        component,
                      });
                    } else if (component.name === 'Banden') {
                      setShowTiresEdit({
                        tasks: main_tasks,
                      });
                    } else if (is_sla_item) {
                      setShowSlaEdit({
                        task: tasks[0],
                        component,
                      });
                    } else {
                      setShowTaskEdit({
                        main_task,
                        parts: [
                          ...(main_task?.parts ?? []),
                          ...(component.parts ?? []),
                        ],
                        tasks,
                        component,
                      });
                    }
                  };

                  result.push(
                    <React.Fragment key={main_task?.id ?? component_id}>
                      {read_only ? (
                        approved ? (
                          <>
                            <View
                              style={[
                                sy['flex-row'],
                                sy['p-4'],
                                sy['justify-between'],
                                sy['gap-4'],
                              ]}>
                              <View
                                style={[
                                  sy['flex-1'],
                                  sy['flex-row'],
                                  sy['gap-4'],
                                ]}>
                                {is_fleet_car || is_retail_car ? (
                                  <></>
                                ) : (
                                  <Text style={{width: 40}}>{rob_code}</Text>
                                )}
                                <Text>
                                  {robComponents[rob_code]?.description ??
                                    main_task?.description ??
                                    component.name}
                                </Text>
                              </View>
                              <Text style={sy.smallRegular}>
                                {Format.price(amount)}
                              </Text>
                            </View>
                            <Divider style={sy['bg-gray95']} />
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <>
                          <View
                            style={[
                              sy['flex-row'],
                              sy['flex-1'],
                              sy['p-4'],
                              sy['gap-4'],
                              !editable && sy['view-disabled'],
                            ]}>
                            <Checkbox
                              style={[
                                sy['justify-start'],
                                sy['pr-4'],
                                {width: 'auto'},
                              ]}
                              disabled={!editable}
                              checked={
                                !cancelled &&
                                !cancellation_denied &&
                                !cancellation_approved
                              }
                              onPress={() => {
                                if (
                                  cancelled ||
                                  cancellation_denied ||
                                  cancellation_approved
                                ) {
                                  onActivateComponent(
                                    component.id,
                                    main_task?.id,
                                  );
                                } else {
                                  if (
                                    !user_settings.never_show_rob_task_cancel_dialog &&
                                    overview?.rob?.components[main_task?.id]
                                  ) {
                                    setShowCancelRobTaskDialog(main_task);
                                  } else {
                                    onDeleteComponent(
                                      component.id,
                                      main_task?.id,
                                    );
                                  }
                                }

                                return true; // prevent checkbox from being toggled
                              }}
                            />
                            <View style={[sy['flex-1']]}>
                              <Pressable
                                disabled={
                                  !component ||
                                  !editable ||
                                  cancelled ||
                                  cancellation_approved ||
                                  cancellation_denied
                                }
                                onPress={onTaskPress}>
                                <View
                                  style={[
                                    sy['flex-row'],
                                    sy['justify-between'],
                                  ]}>
                                  <View style={[sy['flex-1']]}>
                                    <View style={[sy['flex-row']]}>
                                      {rob_code ? (
                                        <Text
                                          style={[
                                            (cancelled ||
                                              cancellation_approved ||
                                              cancellation_denied) &&
                                              sy['line-through'],
                                            sy.truncate,
                                          ]}>
                                          {is_fleet_car || is_retail_car ? (
                                            main_task?.description ??
                                            component.name
                                          ) : (
                                            <>
                                              {rob_code}&nbsp;&middot;&nbsp;
                                              {robComponents[rob_code]
                                                ?.description ?? component.name}
                                            </>
                                          )}
                                        </Text>
                                      ) : (
                                        <Text
                                          style={[
                                            (cancelled ||
                                              cancellation_approved ||
                                              cancellation_denied) &&
                                              sy['line-through'],
                                            sy.truncate,
                                          ]}>
                                          {main_task?.reference
                                            ? main_task?.source === 'Fabrikant'
                                              ? component.name
                                              : main_task.work_text
                                            : component.name}
                                        </Text>
                                      )}
                                    </View>
                                    {!!component && (
                                      <TaskSubtitle
                                        lessor={overview?.lessor}
                                        tasks={tasks}
                                        is_maintenance_item={
                                          component.name === 'Onderhoud'
                                        }
                                        is_tires_item={
                                          component.name === 'Banden'
                                        }
                                        is_sla_item={
                                          is_sla_item ||
                                          main_task?.type ===
                                            'SLA_TASK_DURATION'
                                        }
                                        ready={task_ready}
                                        enabled={editable && !cancelled}
                                        cancelled={cancelled}
                                        cancellation_approved={
                                          cancellation_approved
                                        }
                                        cancellation_denied={
                                          cancellation_denied
                                        }
                                        rob_components={robComponents}
                                        is_fleet_car={is_fleet_car}
                                        is_retail_car={is_retail_car}
                                      />
                                    )}
                                  </View>
                                </View>
                              </Pressable>
                            </View>
                            {component && (
                              <View style={[sy['items-end']]}>
                                {task_ready ? (
                                  <Text style={sy.smallRegular}>
                                    {Format.price(
                                      cancelled ||
                                        cancellation_approved ||
                                        cancellation_denied
                                        ? 0
                                        : amount,
                                    )}
                                  </Text>
                                ) : (
                                  <Pressable
                                    disabled={!editable && !cancelled}
                                    onPress={onTaskPress}>
                                    <ListIcon />
                                  </Pressable>
                                )}
                                <View
                                  style={[
                                    sy['flex-row'],
                                    sy['items-center'],
                                    sy['gap-1'],
                                  ]}>
                                  {has_rob_errors && (
                                    <Pressable
                                      onPress={() =>
                                        setShowRobTaskErrorsDialog({
                                          title: (
                                            <Text style={[sy.largePlus]}>
                                              {rob_code}&nbsp;&middot;&nbsp;
                                              {
                                                robComponents[rob_code]
                                                  ?.description
                                              }
                                            </Text>
                                          ),
                                          component_id: component.id,
                                        })
                                      }>
                                      <ErrorIcon
                                        width={18}
                                        height={18}
                                        fill="#972727"
                                      />
                                    </Pressable>
                                  )}
                                  {((overview.dealer_request ??
                                    overview.fleet_request) &&
                                    (
                                      overview.dealer_request ??
                                      overview.fleet_request
                                    )?.components?.[main_task?.id]?.status
                                      .code !==
                                      ROB_TASK_STATUS.ApprovalRequested) ||
                                  (overview?.rob &&
                                    overview?.rob?.components[main_task?.id]
                                      ?.status.code &&
                                    !isRobTaskChanged(
                                      overview?.rob?.components[main_task?.id]
                                        ?.status.code,
                                    )) ? (
                                    is_fleet_car || is_retail_car ? (
                                      <RobTaskIcon
                                        statuses={[
                                          (
                                            overview.dealer_request ??
                                            overview.fleet_request
                                          ).components[main_task?.id]?.status
                                            .code,
                                        ]}
                                        width={18}
                                        height={18}
                                      />
                                    ) : (
                                      <Pressable
                                        onPress={() =>
                                          setShowRobReasonsDialog({
                                            rob_code: main_task?.rob_code,
                                          })
                                        }>
                                        <RobTaskIcon
                                          statuses={[
                                            overview?.rob?.components[
                                              main_task?.id
                                            ]?.status.code,
                                          ]}
                                          width={18}
                                          height={18}
                                        />
                                      </Pressable>
                                    )
                                  ) : (
                                    <>
                                      {!is_sla_item &&
                                        task_ready &&
                                        (has_original_duration ? (
                                          <Pressable
                                            onPress={() =>
                                              setShowMoreTimeInfoDialog(true)
                                            }>
                                            <MoreTimeIcon
                                              fill="#FF6C00"
                                              width={18}
                                              height={18}
                                            />
                                          </Pressable>
                                        ) : has_work_time_overlap ? (
                                          <Pressable
                                            onPress={() =>
                                              setShowOverlapInfoDialog(true)
                                            }>
                                            <PublishedWithChangesIcon
                                              fill="#299976"
                                              width={18}
                                              height={18}
                                            />
                                          </Pressable>
                                        ) : null)}
                                      {has_discount && (
                                        <DiscountIcon
                                          fill="#4A4A49"
                                          width={18}
                                          height={18}
                                        />
                                      )}
                                      {has_markup && (
                                        <LocalOfferIcon
                                          fill="#4A4A49"
                                          width={18}
                                          height={18}
                                        />
                                      )}
                                      {kpi && (
                                        <KpiWarning
                                          style={[
                                            sy['flex-1'],
                                            sy['items-end'],
                                            sy['justify-end'],
                                          ]}
                                          kpi={kpi}
                                        />
                                      )}
                                    </>
                                  )}
                                </View>
                              </View>
                            )}
                          </View>
                          <View style={[sy['pl-18']]}>
                            <Divider style={sy['bg-gray95']} />
                          </View>
                        </>
                      )}
                    </React.Fragment>,
                  );
                }

                return result;
              })}

              {(is_retail_car ||
                overview?.replacement?.type !== REPLACEMENT_TYPE.car) &&
                (read_only ? (
                  overview?.replacement?.sla?.rob_code &&
                  isRobTaskApproved(
                    Object.values(
                      (
                        overview?.dealer_request ??
                        overview?.fleet_request ??
                        overview?.rob
                      )?.components ?? {},
                    ).find(
                      (item) =>
                        item.rob.code === overview?.replacement.sla.rob_code,
                    )?.status.code,
                  ) && (
                    <>
                      <View
                        style={[
                          sy['flex-row'],
                          sy['p-4'],
                          sy['justify-between'],
                        ]}>
                        <View style={[sy['flex-row']]}>
                          {!is_fleet_car && !is_retail_car && (
                            <Text style={[{width: 40}, sy['mr-4']]}>
                              {overview?.replacement.sla.rob_code}
                            </Text>
                          )}
                          <Text>
                            {is_fleet_car || is_retail_car
                              ? Object.values(
                                  overview.dealer_request?.components ??
                                    overview.fleet_request?.components,
                                ).find(
                                  (item) =>
                                    item.rob.code ===
                                    overview?.replacement?.sla?.rob_code,
                                )?.description
                              : robComponents[overview.replacement.sla.rob_code]
                                  ?.description}
                          </Text>
                        </View>
                        <Text style={sy.smallRegular}>
                          {Format.price(overview?.replacement?.sla?.amount)}
                        </Text>
                      </View>
                      <Divider style={sy['bg-gray95']} />
                    </>
                  )
                ) : (
                  <>
                    <View
                      style={[
                        sy['flex-row'],
                        !editable && sy['view-disabled'],
                      ]}>
                      <View style={[sy['p-4'], sy['pr-8']]}>
                        <ReplacementIcon
                          type={overview?.replacement?.type}
                          fill="#4A4A49"
                        />
                      </View>
                      <View
                        style={[
                          sy['flex-row'],
                          sy['flex-1'],
                          sy['justify-between'],
                          sy['py-4'],
                          sy['pr-4'],
                        ]}>
                        <Pressable
                          disabled={!editable}
                          onPress={() =>
                            navigation.navigate('Replacement', {
                              request_id,
                            })
                          }>
                          {!is_fleet_car &&
                          !is_retail_car &&
                          overview?.replacement?.sla?.rob_code ? (
                            <Text>
                              {overview.replacement.sla.rob_code}
                              &nbsp;&middot;&nbsp;
                              {
                                robComponents[overview.replacement.sla.rob_code]
                                  ?.description
                              }
                            </Text>
                          ) : (
                            <Text>Vervangend vervoer</Text>
                          )}

                          {overview?.replacement?.type ? (
                            <ReplacementText
                              type={overview?.replacement?.type}
                              style={[
                                sy.small,
                                sy['text-lightgray'],
                                editable && sy.underline,
                              ]}
                            />
                          ) : (
                            <Text
                              style={[
                                sy.small,
                                sy['text-lightgray'],
                                editable && sy.underline,
                              ]}>
                              Keuze maken
                            </Text>
                          )}
                        </Pressable>
                        <View style={[sy['items-end']]}>
                          {overview?.replacement?.type ? (
                            <>
                              {_.isNumber(
                                overview?.replacement?.sla?.amount,
                              ) && (
                                <Text style={sy.smallRegular}>
                                  {Format.price(
                                    overview?.replacement?.sla?.amount,
                                  )}
                                </Text>
                              )}
                              {(overview?.dealer_request ||
                                overview?.fleet_request ||
                                overview?.rob) &&
                                overview?.replacement?.sla?.rob_code && (
                                  <Pressable
                                    disabled={is_fleet_car || is_retail_car}
                                    onPress={() =>
                                      setShowRobReasonsDialog({
                                        rob_code:
                                          overview?.replacement.sla.rob_code,
                                      })
                                    }>
                                    {isRobTaskApproved(
                                      Object.values(
                                        (
                                          overview?.dealer_request ??
                                          overview?.fleet_request ??
                                          overview?.rob
                                        ).components,
                                      ).find(
                                        (item) =>
                                          item.rob.code ===
                                          overview?.replacement.sla.rob_code,
                                      )?.status.code,
                                    ) ? (
                                      <ThumbUpIcon
                                        width={18}
                                        height={18}
                                        fill="#299976"
                                      />
                                    ) : (
                                      <RobTaskIcon
                                        statuses={[
                                          Object.values(
                                            (
                                              overview?.dealer_request ??
                                              overview?.fleet_request ??
                                              overview?.rob
                                            ).components,
                                          ).find(
                                            (item) =>
                                              item.rob.code ===
                                              overview?.replacement.sla
                                                .rob_code,
                                          )?.status.code,
                                        ]}
                                        width={18}
                                        height={18}
                                        fill="#972727"
                                      />
                                    )}
                                  </Pressable>
                                )}
                            </>
                          ) : (
                            <ListIcon />
                          )}
                        </View>
                      </View>
                    </View>
                    <View style={[sy['pl-18']]}>
                      <Divider style={sy['bg-gray95']} />
                    </View>
                  </>
                ))}

              {read_only && (
                <>
                  <Pressable
                    style={[sy['flex-row'], sy['p-4']]}
                    onPress={() => setShowDetails(true)}>
                    <View style={[sy['pr-8']]}>
                      <ReceiptLongIcon />
                    </View>
                    <Text style={[sy.underline]}>Details</Text>
                  </Pressable>
                  <Divider />
                </>
              )}

              {!is_retail_car &&
                overview?.replacement?.type === REPLACEMENT_TYPE.car && (
                  <ReplacementVehicleRow
                    request_id={request_id}
                    request_type={overview?.request_type}
                    read_only={read_only}
                    onPress={() =>
                      navigation.navigate('Replacement', {request_id})
                    }
                  />
                )}

              {!read_only && (
                <View
                  style={[
                    sy['p-4'],
                    sy['flex-row'],
                    sy['justify-between'],
                    !editable && sy['view-disabled'],
                  ]}>
                  <Pressable
                    style={[sy['flex-row']]}
                    disabled={!editable}
                    onPress={() =>
                      navigation.navigate('AddTasks', {
                        request_id,
                        license: overview?.license,
                      })
                    }>
                    <View style={[sy['pr-8']]}>
                      <AddIcon fill="#231fda" />
                    </View>
                    <Text>Item toevoegen</Text>
                  </Pressable>
                  {is_fleet_car || is_retail_car ? (
                    <></>
                  ) : (
                    <Pressable
                      disabled={!editable}
                      onPress={() =>
                        navigation.navigate('Fource', {
                          request_id,
                          license: overview?.license,
                          dealer_id: overview?.dealer_id,
                        })
                      }>
                      <ShoppingBasketIcon fill="#231fda" />
                    </Pressable>
                  )}
                </View>
              )}
            </View>

            {([STATUS_CODES.Done, STATUS_CODES.DoneNoInvoice].includes(
              overview.status,
            ) ||
              (
                overview.dealer_request ??
                overview.fleet_request ??
                overview.rob
              )?.invoicing?.status === STATUS_CODES.Invoiced) && (
              <>
                <View
                  style={[
                    sy['flex-row'],
                    sy['items-center'],
                    sy['justify-between'],
                    sy['p-4'],
                    sy['bg-white'],
                  ]}>
                  <View style={[sy['flex-row'], sy['flex-1']]}>
                    <View style={[sy['pr-8']]}>
                      <ReceiptIcon
                        fill={
                          !isNotInvoiced(
                            (
                              overview.dealer_request ??
                              overview.fleet_request ??
                              overview.rob
                            )?.invoicing?.status,
                          ) &&
                          !overview.invoice_number &&
                          !invoice?.bahnkick_can_invoice
                            ? '#FF6C00'
                            : '#4A4A49'
                        }
                      />
                    </View>
                    <Text style={sy.mediumPlus}>
                      {isNotInvoiced(
                        (
                          overview.dealer_request ??
                          overview.fleet_request ??
                          overview.rob
                        )?.invoicing?.status,
                      ) ? (
                        <>Geen factuur</>
                      ) : (
                        <>
                          Factuur
                          {overview.invoice_number ? (
                            <>&nbsp;&middot;&nbsp;{overview.invoice_number}</>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Text>
                  </View>
                  <>
                    {isNotInvoiced(
                      (
                        overview.dealer_request ??
                        overview.fleet_request ??
                        overview.rob
                      )?.invoicing?.status,
                    ) ? (
                      <></>
                    ) : (
                      <Text style={sy.mediumPlus}>
                        {Format.price(invoice?.amount ?? total_amount)}
                      </Text>
                    )}
                  </>
                </View>
                <Divider />
                <View style={[sy['bg-white']]}>
                  {isNotInvoiced(
                    (
                      overview.dealer_request ??
                      overview.fleet_request ??
                      overview.rob
                    )?.invoicing?.status,
                  ) ? (
                    <GroupingRow title="Reden">
                      <Text>
                        {
                          ROB_NOT_INVOICED_STATUS_LABELS[
                            (
                              overview.dealer_request ??
                              overview.fleet_request ??
                              overview.rob
                            )?.invoicing?.status
                          ]
                        }
                      </Text>
                    </GroupingRow>
                  ) : (
                    !isArchived(
                      is_fleet_car || is_retail_car
                        ? overview.status
                        : overview.rob?.status,
                    ) &&
                    (
                      overview.dealer_request ??
                      overview.fleet_request ??
                      overview.rob
                    )?.status !== 'Invoiced' && (
                      <GroupingRow
                        linkStyle={[
                          {
                            color:
                              !overview.invoice_number &&
                              !invoice?.bahnkick_can_invoice
                                ? '#FF6C00'
                                : null,
                          },
                        ]}
                        title={
                          <View>
                            <View style={[sy['flex-row'], sy['items-center']]}>
                              <Text>Nummer</Text>
                            </View>
                            {!overview.invoice_number &&
                              !invoice?.bahnkick_can_invoice &&
                              invoice?.pdf_upload && (
                                <View>
                                  <Text
                                    style={[
                                      sy.smallRegular,
                                      sy['text-lightgray'],
                                    ]}>
                                    Let op: facturatie aan{' '}
                                    <>
                                      {overview.fleet_administrator ??
                                        overview.lessor}
                                    </>
                                  </Text>
                                </View>
                              )}
                          </View>
                        }
                        onEditClicked={
                          invoice?.bahnkick_can_invoice
                            ? null
                            : () =>
                                setEditInvoiceNumber({
                                  value: overview?.invoice_number,
                                  valid: true,
                                })
                        }>
                        <>
                          {invoice?.bahnkick_can_invoice &&
                          !overview.invoice_number ? (
                            <Text style={sy.smallRegular}>
                              (Wordt gegenereerd)
                            </Text>
                          ) : (
                            overview.invoice_number ?? <>Opgeven</>
                          )}
                        </>
                        {invoice?.bahnkick_can_invoice ? null : (
                          <GroupingRowEdit
                            title="Factuur"
                            dismissable
                            cancelButton
                            onValidate={async () => {
                              if (edit_invoiceNumber.value?.length > 0) {
                                const result = await onUpdateRequest({
                                  invoice_number:
                                    edit_invoiceNumber.value?.trim(),
                                });

                                if (result) {
                                  return true;
                                }

                                setEditInvoiceNumber((prevState) => ({
                                  ...prevState,
                                  valid: false,
                                }));

                                return false;
                              }
                            }}>
                            <View style={[sy['p-4']]}>
                              <FormTextInput
                                label="Nummer"
                                value={edit_invoiceNumber?.value}
                                hasError={edit_invoiceNumber?.valid === false}
                                errorMessage="Factuurnummer opslaan is mislukt. Controleer het factuurnummer en probeer het opnieuw."
                                onEndEditing={(value) => {
                                  setEditInvoiceNumber((prevState) => ({
                                    ...prevState,
                                    value,
                                    valid: true,
                                  }));
                                }}
                              />
                            </View>
                          </GroupingRowEdit>
                        )}
                      </GroupingRow>
                    )
                  )}
                  {!isNotInvoiced(
                    (
                      overview.dealer_request ??
                      overview.fleet_request ??
                      overview.rob
                    )?.invoicing?.status,
                  ) &&
                    invoice?.components && (
                      <>
                        <GroupingRow
                          title={
                            <View>
                              <Text>Opdracht</Text>
                              <Text
                                style={[sy.smallRegular, sy['text-lightgray']]}>
                                Exclusief BTW
                              </Text>
                            </View>
                          }>
                          <Text style={sy.smallRegular}>
                            {Format.price(invoice.amount_approved)}
                          </Text>
                        </GroupingRow>
                        {Format.round(
                          invoice.amount_approved -
                            (invoice?.amount_approved_discount ?? 0) -
                            (invoice?.amount ?? total_amount),
                        ) > 0 && (
                          <GroupingRow
                            title={
                              <View>
                                <Text>Inhouding</Text>
                              </View>
                            }>
                            <Text style={sy.smallRegular}>
                              {Format.price(
                                invoice.amount_approved -
                                  (invoice?.amount_approved_discount ?? 0) -
                                  (invoice?.amount ?? total_amount),
                              )}
                            </Text>
                            <GroupingRowEdit
                              title="Inhouding"
                              titleIcon={<DiscountIcon fill="#4a4a49" />}
                              titleStyle={[sy['ml-4'], sy['mr-4']]}>
                              <ScrollView
                                contentContainerStyle={{
                                  maxHeight:
                                    dimensions.window.height -
                                    INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX -
                                    INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX,
                                }}>
                                {Object.keys(invoice.components)
                                  .filter(
                                    (type) =>
                                      invoice.components[type].amount > 0,
                                  )
                                  .sort(
                                    (a, b) =>
                                      INVOICE_DISCOUNT_ORDER.indexOf(a) -
                                      INVOICE_DISCOUNT_ORDER.indexOf(b),
                                  )
                                  .map((type) => {
                                    if (
                                      [
                                        'DEALER_TIRE_SERVICE_PERCENTAGE',
                                        'DEALER_TIRE_EXPENSE_PERCENTAGE',
                                      ].includes(type)
                                    ) {
                                      return null;
                                    }

                                    const item = invoice.components[type];
                                    let amount = item.amount;
                                    if (
                                      item.type === 'DEALER_SERVICE_PERCENTAGE'
                                    ) {
                                      amount +=
                                        invoice.components[
                                          'DEALER_TIRE_SERVICE_PERCENTAGE'
                                        ]?.amount ?? 0;
                                    } else if (
                                      item.type === 'DEALER_EXPENSE_PERCENTAGE'
                                    ) {
                                      amount +=
                                        invoice.components[
                                          'DEALER_TIRE_EXPENSE_PERCENTAGE'
                                        ]?.amount ?? 0;
                                    }

                                    return (
                                      <GroupingRow
                                        key={type}
                                        title={
                                          <View>
                                            <Text>
                                              {INVOICE_DISCOUNT_LABELS[
                                                item.type
                                              ] ?? <>Korting</>}
                                            </Text>
                                            <Text
                                              style={[
                                                sy.smallRegular,
                                                sy['text-lightgray'],
                                              ]}>
                                              <>
                                                {[
                                                  'DEALER_TECRMI_COST',
                                                  'DEALER_SMS_COST',
                                                ].includes(item.type) ? (
                                                  Format.price(item.discount)
                                                ) : (
                                                  <>
                                                    {Format.number(
                                                      item.discount * 100,
                                                      1,
                                                      false,
                                                      {
                                                        minimumFractionDigits: 0,
                                                      },
                                                    )}
                                                    %
                                                  </>
                                                )}
                                              </>
                                              {item.lessor && (
                                                <>
                                                  &nbsp;&middot;&nbsp;
                                                  {item.lessor}
                                                </>
                                              )}
                                              {item.subject && (
                                                <>
                                                  &nbsp;&middot;&nbsp;
                                                  {item.subject}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        }>
                                        <Text style={sy.smallRegular}>
                                          {Format.price(amount)}
                                        </Text>
                                      </GroupingRow>
                                    );
                                  })}
                              </ScrollView>
                            </GroupingRowEdit>
                          </GroupingRow>
                        )}
                        {invoice?.amount_approved_discount > 0 && (
                          <GroupingRow
                            title={
                              <View>
                                <Text>Korting</Text>
                              </View>
                            }>
                            <Text style={sy.smallRegular}>
                              {Format.price(invoice?.amount_approved_discount)}
                            </Text>
                            <GroupingRowEdit
                              title="Korting"
                              titleIcon={<DiscountIcon fill="#4a4a49" />}
                              titleStyle={[sy['ml-4'], sy['mr-4']]}>
                              <ScrollView
                                contentContainerStyle={{
                                  maxHeight:
                                    dimensions.window.height -
                                    INVOICE_COMPONENTS_HEADER_HEIGHT_IN_PX -
                                    INVOICE_COMPONENTS_FOOTER_HEIGHT_IN_PX,
                                }}>
                                {Object.keys(invoice.components)
                                  .filter(
                                    (type) =>
                                      [
                                        'SLA_APPROVED_INVOICE_DISCOUNT',
                                        'SLA_APPROVED_INVOICE_NEW_TIRES_DISCOUNT',
                                      ].includes(type) &&
                                      invoice.components[type].amount > 0,
                                  )
                                  .sort(
                                    (a, b) =>
                                      INVOICE_DISCOUNT_ORDER.indexOf(a) -
                                      INVOICE_DISCOUNT_ORDER.indexOf(b),
                                  )
                                  .map((type) => {
                                    const item = invoice.components[type];
                                    return (
                                      <GroupingRow
                                        key={type}
                                        title={
                                          <View>
                                            <Text>
                                              {INVOICE_DISCOUNT_LABELS[
                                                item.type
                                              ] ?? <>Korting</>}
                                            </Text>
                                            <Text
                                              style={[
                                                sy.smallRegular,
                                                sy['text-lightgray'],
                                              ]}>
                                              <>
                                                {Format.number(
                                                  item.discount * 100,
                                                  1,
                                                  false,
                                                  {
                                                    minimumFractionDigits: 0,
                                                  },
                                                )}
                                                %
                                              </>
                                              {item.lessor && (
                                                <>
                                                  &nbsp;&middot;&nbsp;
                                                  {item.lessor}
                                                </>
                                              )}
                                              {item.subject && (
                                                <>
                                                  &nbsp;&middot;&nbsp;
                                                  {item.subject}
                                                </>
                                              )}
                                            </Text>
                                          </View>
                                        }>
                                        <Text style={sy.smallRegular}>
                                          {Format.price(item.amount)}
                                        </Text>
                                      </GroupingRow>
                                    );
                                  })}
                              </ScrollView>
                            </GroupingRowEdit>
                          </GroupingRow>
                        )}
                      </>
                    )}
                  <GroupingRow
                    title={
                      <View>
                        <Text>Totaal</Text>
                        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                          Exclusief BTW
                        </Text>
                      </View>
                    }>
                    <Text style={sy.smallRegular}>
                      {Format.price(invoice?.amount ?? total_amount)}
                    </Text>
                  </GroupingRow>
                  {(invoice?.bahnkick_can_invoice || invoice?.pdf_available) &&
                    overview.invoice_number && (
                      <GroupingRow
                        title={
                          <PdfViewerLink
                            invoice={invoice}
                            request_id={request_id}
                          />
                        }>
                        <FileOpenIcon />
                      </GroupingRow>
                    )}
                  {isArchived(overview.rob?.status) ? (
                    <></>
                  ) : (
                    <>
                      {overview.rob?.invoicing?.status ===
                        'ReadyForInvoicing' && (
                        <View style={[sy['p-4'], sy['flex-row']]}>
                          <BlockIcon />
                          <Link
                            style={[sy['pl-8']]}
                            onPress={() => setShowNoInvoiceDialog(true)}>
                            Niet factureren
                          </Link>
                        </View>
                      )}
                      {isNotInvoiced(overview.rob?.invoicing?.status) && (
                        <View style={[sy['p-4'], sy['flex-row']]}>
                          <ReceiptIcon />
                          <Link
                            style={[sy['pl-8']]}
                            onPress={async () => onDoRequest()}>
                            Wel factureren
                          </Link>
                        </View>
                      )}
                    </>
                  )}
                </View>
              </>
            )}
          </ScrollView>
          <Grouping
            icon={
              <View>
                <ChatIcon fill="#4A4A49" />
                <Badge
                  theme={{colors: {notification: '#ff6c00'}}}
                  visible={!readLastMessage}
                  style={{position: 'absolute', top: 0, right: -2}}
                  size={10}
                />
              </View>
            }
            header="Berichten"
            onExpand={() => setReadLastMessage(true)}
            options={{switchChevrons: true}}>
            <LessorMessage
              key={overview?.last_message?.message_id}
              request_id={request_id}
              dealer_id={overview?.dealer_id}
              {...(overview?.last_message || {
                lessor: overview?.kvk_name ?? overview?.lessor,
                content: '(nog geen berichten)',
              })}
            />
            {editable && (
              <>
                <Divider style={sy['bg-gray95']} />
                <InputChat
                  message={pendingMessage}
                  onChange={setPendingMessage}
                  onSend={onSendChatMessage}
                  onImage={onSendChatImage}
                />
              </>
            )}
          </Grouping>

          <Footer
            request_id={request_id}
            is_fleet_car={is_fleet_car}
            is_retail_car={is_retail_car}
            status={overview.status}
            overview={overview}
            invoice={invoice}
            amount={total_amount}
            robErrors={robErrors}
            onDoRequest={onDoRequest}
            onRefresh={refresh}
            onInvoice={onInvoice}
            onShowRobRequestErrors={() => setShowRobRequestErrorsDialog(true)}
          />

          {showMaintenanceEdit && (
            <MaintenanceActionsheet
              onDismiss={() => setShowMaintenanceEdit(null)}
              request_id={request_id}
              license={overview.license}
              is_fleet_car={is_fleet_car}
              is_retail_car={is_retail_car}
              dealer_id={overview.dealer_id}
              lessor={overview.lessor}
              vin_number={vin_number}
              mileage={overview.mileage}
              car={car}
              task={showMaintenanceEdit.task}
              additional={showMaintenanceEdit.additional}
              component={showMaintenanceEdit.component}
              rob={overview.rob?.components[showMaintenanceEdit.task.id]}
              onChange={onMaintenanceEdit}
              onShowDenialReasons={() =>
                setShowRobReasonsDialog({
                  component_id: showMaintenanceEdit.component.id,
                })
              }
            />
          )}

          {showTaskEdit && (
            <TaskActionsheet
              onDismiss={() => setShowTaskEdit(null)}
              license={overview.license}
              is_fleet_car={is_fleet_car}
              is_retail_car={is_retail_car}
              dealer_id={overview.dealer_id}
              lessor={overview.lessor}
              vin_number={vin_number}
              mileage={overview.mileage}
              tecrmi_enabled={overview.tecrmi_enabled}
              request_id={request_id}
              main_task={showTaskEdit.main_task}
              tasks={showTaskEdit.tasks}
              parts={showTaskEdit.parts}
              component={showTaskEdit.component}
              rob={overview.rob?.components[showTaskEdit.main_task.id]}
              onChange={onTaskEdit}
              onShowDenialReasons={() =>
                setShowRobReasonsDialog({
                  component_id: showTaskEdit.component.id,
                })
              }
            />
          )}

          {showSlaEdit && (
            <SlaActionsheet
              onDismiss={() => setShowSlaEdit(null)}
              license={overview.license}
              is_fleet_car={is_fleet_car}
              is_retail_car={is_retail_car}
              request_id={request_id}
              task_id={showSlaEdit.task.id}
              component={showSlaEdit.component}
              rob={overview.rob?.components[showSlaEdit.task.id]}
              onChange={onSlaEdit}
              onShowDenialReasons={() =>
                setShowRobReasonsDialog({
                  component_id: showSlaEdit.component.id,
                })
              }
            />
          )}

          {showTiresEdit && (
            <TiresActionsheet
              onDismiss={() => setShowTiresEdit(null)}
              license={overview.license}
              is_fleet_car={is_fleet_car}
              is_retail_car={is_retail_car}
              request_id={request_id}
              onChange={onTiresEdit}
            />
          )}

          {showRequestDialog && (
            <Dialog
              visible={true}
              title={
                is_fleet_car || is_retail_car ? 'Aanvraag' : 'Aanvraag ROB'
              }
              onDismiss={() => setShowRequestDialog(false)}
              buttons={[
                {text: 'Annuleren', onPress: () => setShowRequestDialog(false)},
                {
                  text: 'Verzenden',
                  textStyle: {color: '#972727'},
                  onPress: onRequest,
                },
              ]}>
              <View style={[{paddingTop: 24}, sy['gap-4']]}>
                <FormTextInput
                  label="Meldcode"
                  value={partial_vin}
                  onChangeText={(value) => setPartialVin(value)}
                  inputProps={{
                    maxLength: 4,
                    keyboardType: 'numeric',
                  }}
                />
                <FormNumberInput
                  label="Huidige kilometerstand"
                  value={current_mileage}
                  digits={0}
                  onChangeNumber={(value) => setCurrentMileage(value)}
                  onSubmit={onRequest}
                />
              </View>
            </Dialog>
          )}
          {showRobReasonsDialog && (
            <RobReasonsDialog
              visible={true}
              component_id={showRobReasonsDialog.component_id}
              rob_code={showRobReasonsDialog.rob_code}
              tasks={overview.tasks}
              rob={overview.rob}
              rob_components={robComponents}
              onDismiss={() => setShowRobReasonsDialog(null)}
            />
          )}
          {showNoInvoiceDialog && (
            <Dialog
              visible={true}
              title="Reden geen factuur"
              buttons={[
                {
                  text: 'Annuleren',
                  onPress: () => setShowNoInvoiceDialog(false),
                },
                {
                  text: 'Opslaan',
                  textStyle: {color: '#972727'},
                  onPress: onChangeInvoiceStatus,
                },
              ]}
              options={{noPaddingContent: true}}>
              <View>
                <Pressable
                  style={[sy['flex-row']]}
                  onPress={() =>
                    setInvoiceStatus(
                      'WillNotBeInvoicedForWarrantyOrLeniencyReasons',
                    )
                  }>
                  <View style={[sy['p-4'], sy['pr-8']]}>
                    <RadioButton
                      checked={
                        invoiceStatus ===
                        'WillNotBeInvoicedForWarrantyOrLeniencyReasons'
                      }
                    />
                  </View>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['flex-1'],
                      sy['justify-between'],
                      sy['py-4'],
                      sy['pr-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                    ]}>
                    <Text>Garantie of coulance</Text>
                  </View>
                </Pressable>
                <Pressable
                  style={[sy['flex-row']]}
                  onPress={() => setInvoiceStatus('Invoiced')}>
                  <View style={[sy['p-4'], sy['pr-8']]}>
                    <RadioButton checked={invoiceStatus === 'Invoiced'} />
                  </View>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['flex-1'],
                      sy['justify-between'],
                      sy['py-4'],
                      sy['pr-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                    ]}>
                    <Text>Al gefactureerd</Text>
                  </View>
                </Pressable>
                <Pressable
                  style={[sy['flex-row']]}
                  onPress={() => setInvoiceStatus('WillNotBeInvoiced')}>
                  <View style={[sy['p-4'], sy['pr-8']]}>
                    <RadioButton
                      checked={invoiceStatus === 'WillNotBeInvoiced'}
                    />
                  </View>
                  <View
                    style={[
                      sy['flex-row'],
                      sy['flex-1'],
                      sy['justify-between'],
                      sy['py-4'],
                      sy['pr-4'],
                      sy['border-b'],
                      sy['border-lightgray'],
                    ]}>
                    <Text>Niet gefactureerd</Text>
                  </View>
                </Pressable>
              </View>
            </Dialog>
          )}
          {showNoInvoiceInfoDialog && (
            <Dialog
              visible={true}
              title="Gereed zonder factuur"
              buttons={[
                {
                  text: 'OK',
                  textStyle: {color: '#972727'},
                  onPress: () => setShowNoInvoiceInfoDialog(false),
                },
              ]}>
              <View style={[sy['flex-row'], sy['py-4']]}>
                <View style={[{flexShrink: 0}, sy['pr-4']]}>
                  <AssignmentTurnedInIcon
                    height="24px"
                    width="24px"
                    fill="#828282"
                  />
                </View>
                <Text>
                  Opdracht niet gefactureerd. Toch wel factureren? Dit is
                  mogelijk tot aan archivering.
                </Text>
              </View>
            </Dialog>
          )}
          {showRobRequestErrorsDialog && (
            <Dialog
              visible={true}
              title="Fout werkopdracht"
              buttons={[
                {
                  text: 'OK',
                  onPress: () => setShowRobRequestErrorsDialog(false),
                },
              ]}>
              <ScrollView contentContainerStyle={{maxHeight: 400}}>
                {robErrors
                  .filter((item) => !item.task_id)
                  .map((item, index) => (
                    <View style={[sy['flex-row'], sy['py-4']]} key={index}>
                      <View style={[{flexShrink: 0}, sy['pr-4']]}>
                        <ErrorIcon height="24px" width="24px" fill="#972727" />
                      </View>
                      <View style={[sy['flex-1']]}>
                        <Text>
                          {ROB_ERROR_TRANSLATIONS[item.code] ?? item.error}
                        </Text>
                        <Text style={[sy.small, sy['text-lightgray']]}>
                          {item.code}
                        </Text>
                      </View>
                    </View>
                  ))}
              </ScrollView>
            </Dialog>
          )}
          {showRobTaskErrorsDialog && (
            <Dialog
              visible={true}
              title={showRobTaskErrorsDialog.title}
              buttons={[
                {
                  text: 'OK',
                  onPress: () => setShowRobTaskErrorsDialog(false),
                },
              ]}>
              <ScrollView contentContainerStyle={{maxHeight: 400}}>
                {robErrors
                  .filter(
                    (item) =>
                      item.component_id ===
                      showRobTaskErrorsDialog.component_id,
                  )
                  .map((item, index) => (
                    <View style={[sy['flex-row'], sy['py-4']]} key={index}>
                      <View style={[{flexShrink: 0}, sy['pr-4']]}>
                        <ErrorIcon height="24px" width="24px" fill="#972727" />
                      </View>
                      <View style={[sy['flex-1']]}>
                        <Text>
                          {ROB_ERROR_TRANSLATIONS[item.code] ?? item.error}
                        </Text>
                        <Text style={[sy.small, sy['text-lightgray']]}>
                          {item.code}
                        </Text>
                      </View>
                    </View>
                  ))}
              </ScrollView>
            </Dialog>
          )}
          {showDetails && (
            <RequestDetails
              onDismiss={() => setShowDetails(false)}
              request_id={request_id}
              is_fleet_car={is_fleet_car}
              is_retail_car={is_retail_car}
              lessor={overview.lessor}
              license={overview.license}
              data={
                overview.dealer_request ??
                overview.fleet_request ??
                overview.rob
              }
              tasks={overview.tasks}
              replacement_rob_code={overview.replacement?.sla?.rob_code}
              total_amount={total_amount}
            />
          )}

          <ResetDialog
            visible={!!showResetDialog}
            state={showResetDialog}
            onDismiss={() => setShowResetDialog(null)}
            onReset={async (status) => {
              await serviceRequestApi.change_status(request_id, status);

              await refresh();
            }}
          />

          <CancelRequestDialog
            visible={showCancelRequestDialog}
            status={overview.status}
            onDismiss={() => setShowCancelRequestDialog(false)}
            onCancel={async () => {
              await serviceRequestApi.change_status(
                request_id,
                STATUS_CODES.Cancelled,
              );

              await refresh();
            }}
          />

          <CancelRobTaskDialog
            visible={!!showCancelRobTaskDialog}
            onDismiss={async () => {
              await refresh();
              setShowCancelRobTaskDialog(null);
            }}
            onCancel={async () => {
              onDeleteComponent(
                showCancelRobTaskDialog.component_id,
                showCancelRobTaskDialog.id,
              );

              await refresh();
              setShowCancelRobTaskDialog(null);
            }}
          />

          <Dialog
            title="Bericht opslaan?"
            visible={showSaveMessageDialog}
            buttons={[
              {
                text: 'Annuleren',
                onPress: () => setShowSaveMessageDialog(false),
              },
              {
                text: 'Opslaan',
                onPress: async () => {
                  await onSendChatMessage();
                  setShowSaveMessageDialog(false);
                },
              },
            ]}>
            <View style={[sy['py-4']]}>
              <Text>Het getypte bericht is nog niet opgeslagen.</Text>
            </View>
          </Dialog>

          <MaintenanceExtrasDialog
            visible={!!showMaintenanceExtrasDialog}
            onDismiss={() => {
              showMaintenanceExtrasDialog.resolve();
              setShowMaintenanceExtrasDialog(null);
            }}
            onConfirm={() => {
              showMaintenanceExtrasDialog.reject();
              setShowMaintenanceExtrasDialog(null);
              navigation.navigate('AddTasks', {
                request_id,
              });
            }}
          />

          <Dialog
            visible={showMoreTimeInfoDialog}
            title="Arbeidstijd bijgewerkt"
            titleIcon={<MoreTimeIcon fill="#FF6C00" />}
            onDismiss={() => setShowMoreTimeInfoDialog(false)}>
            <View style={[sy['py-4']]}>
              <Text>
                De arbeidstijd is handmatig aangepast naar een andere waarde.
              </Text>
            </View>
          </Dialog>

          <Dialog
            visible={showOverlapInfoDialog}
            title="Overlapcorrectie"
            titleIcon={<PublishedWithChangesIcon fill="#299976" />}
            onDismiss={() => setShowOverlapInfoDialog(false)}>
            <View style={[sy['py-4']]}>
              <Text>
                Arbeidstijd is bijgewerkt door overlap met andere werkzaamheden
                in werkopdracht.
              </Text>
            </View>
          </Dialog>

          {showDealerRequestDialog && (
            <DealerRequestDialog
              visible={true}
              request_id={request_id}
              email={overview?.email}
              phone_number={overview?.phone_number}
              onDismiss={() => setShowDealerRequestDialog(false)}
              onRefresh={refresh}
            />
          )}

          {showAssignUserDialog && (
            <DealerUserActionsheet
              visible={true}
              dealer_id={overview?.dealer_id}
              assigned_user_id={overview?.assigned_user_id}
              onDismiss={() => setShowAssignUserDialog(false)}
              onOK={async (assigned_user_id) => {
                await onUpdateRequest({assigned_user_id});
                setShowAssignUserDialog(false);
                refresh();
              }}
            />
          )}

          {showInvoicePdfUploadDialog && (
            <InvoiceUploadDialog
              visible={true}
              lessor={overview.fleet_administrator ?? overview.lessor}
              onDismiss={() => setShowInvoicePdfUploadDialog(false)}
              onOK={async (data) => {
                const errors = await InvoiceServiceRequest(data);

                if (errors) {
                  throw errors;
                } else {
                  setShowInvoicePdfUploadDialog(false);
                }
              }}
            />
          )}

          {showRobNetCredentialsDialog && (
            <RobNetCredentialsDialog
              visible={true}
              error={showRobNetCredentialsDialog.error}
              onDismiss={() => {
                setRobNetCredentials(null);
                setShowRobNetCredentialsDialog(null);
              }}
              onOK={(username, password) => {
                setRobNetCredentials({username, password});
                setShowRobNetCredentialsDialog(null);
              }}
            />
          )}
        </>
      )}
    </View>
  );
};

export default withProtectedScreen(
  withServiceRequestShortcuts(
    withDimensions(Index),
    (props) => props.route.params?.request_id,
  ),
);
